@import './../../../../scss/colors.scss';
@import './../../../../scss/typography.scss';
@import './../../../../scss/mixins/progress.scss';

.layout-staticpage {
    min-height: 100%;
    background-color: white;

    .toolbar {

        & > button {
            user-select: none;
            background-color: var(--highlight-color);
            color: white;
            &:hover {
                background: var(--highlight-color) linear-gradient(90deg, rgba(0, 0, 0, 0.05) 0px, rgba(0, 0, 0, 0.05));
        		}

        		&.loading {
                    @include progress;
                    cursor: normal;
        		}
        }
    } // .toolbar

    .content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 50px;

        .center {
            text-align: center !important;
        }

        & > h2 {
            @include fontSansBold;
            font-size: 40px;
            line-height: 47px;
            color: var(--highlight-color);
            margin: 15px 0 10px 0;
            text-align: left;
        }

        & > h3 {
            @include fontSansBold;
            font-size: 18px;
            line-height: 30px;
            color: var(--highlight-color);
            text-align: left;
            margin: 0;
        }

        ol {
            @include fontSansBold;
            font-size: 18px;
            line-height: 30px;
            color: $text-black;
            text-align: left;
            display: block;
            padding: 0 0 0 30px;
            margin-top: 10px;
            li {
                @include fontSansRegular;
                font-size: 18px;
                line-height: 30px;
                color: $text-black;
                text-align: left;
                margin-bottom: 15px;
                strong {
                    @include fontSansBold;
                }
            }
        }
        & > ul {
            @include fontSansBold;
            font-size: 18px;
            line-height: 30px;
            color: $text-black;
            text-align: left;
            display: block;
            padding: 0;
            margin-top: 10px;

            li {

                @include fontSansRegular;
                font-size: 18px;
                line-height: 30px;
                color: $text-black;
                text-align: left;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;

                .icon {
                    height: 25px;
                    width: 25px;
                    border-radius: 50%;
                    background-color: var(--highlight-color);
                    color: white;
                    margin-right: 24px;
                }

                strong {
                    @include fontSansBold;
                }
            }
        }

        & > p {
            @include fontSansRegular;
            text-align: left;
            font-size: 16px;
            line-height: 26px;

            &.multiline {
                line-height: 18px;
                margin-bottom: 15px;
            }

            &.info {
                color: $light-grey;
                a {
                    @include fontSansBold;
                    cursor: pointer;
                    color: var(--highlight-color);
                    text-decoration: none;
                    &:hover {
                        color: var(--highlight-color);
                        text-decoration: underline;
                    }
                }
            }

            &.help {
                color: var(--highlight-color);
                font-size: 14px;
                text-align: right;

                a {
                    cursor: pointer;
                    color: var(--highlight-color);
                    text-decoration: none;
                    &:hover {
                        color: var(--highlight-color);
                        text-decoration: underline;
                    }
                }
            }
        }

        .option-list {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: flex-start;
            width: calc(100% + 2px);
            transform: translateX(-1px);
            .option-item {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                width: 30%;
                height: 38px;
                line-height: 38px;
                border-radius: 6px;
                text-align: center;
                margin: 1.5px;
                background-color: $lighter-grey;
                color: var(--highlight-color);
                font-size: 14px;
                transition: background-color 200ms ease, color 300ms ease;
                .marker {
                    display: none;
                }
                .label {
                    text-align: center;
                    color: var(--highlight-color);
                    font-size: 14px;
                    margin: 0;
                    padding: 0;
                }
                &.set {
                    background-color: var(--highlight-color);
                    color: white;
                    .label {
                        color: white;
                        margin: 0;
                        padding: 0;
                    }
                }
            }
        }

        & > button, & > p > button {
            user-select: none;
            background-color: var(--highlight-color);
            color: white;
            font-size: 16px;
            line-height: 22px;
            height: 53px;
            border-radius: 27px;
            min-width: 160px;

            &:hover {
                background: var(--highlight-color) linear-gradient(90deg, rgba(0, 0, 0, 0.05) 0px, rgba(0, 0, 0, 0.05));
    		}

    		&.loading {
                @include progress;
                cursor: normal;
    		}
        }

    }

}
