@import './../../../../scss/colors.scss';
@import './../../../../scss/typography.scss';
@import './../../../../scss/responsive.scss';

.bookmarks.header {

	display: block;
	width: 100%;
    padding: 0;

	a, .title {

        display: inline;
		line-height: 20px;
		margin: 5px 10px;
		font-size: 16px;
		padding: 4px 12px 6px 12px;
		@include fontSansBold;
		white-space: nowrap;
		text-decoration: none;
		color: $text-grey;

		a:visited, a:active, a:hover {
			text-decoration: none;
			color: $text-grey;
		}

		&:first-child {
			margin-left: 4px;
		}

		&.active {
			color: var(--highlight-color);
			border-radius: 4px;
			background-color: $lighter-grey;
		}
	}
}

@media only screen and (min-width: $mobile-width) {

    .bookmarks.header {

        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        a, .title {
    		line-height: 22px;
    		margin: 5px 10px;
    		font-size: 18px;
    		padding: 5px 12px 7px 12px;

            &:first-child {
    			margin-left: 0;
    		}
    	}

        .container {
            padding-left: 0;
        }

        // .menu {
        //     flex: 0 1 1270px;
        //     max-width: 100%;
        //     padding-left: 20px;
        //     box-sizing: border-box;
        // }

    }
}

// @media only screen and (min-width: 1220px) {
//
//     .bookmarks.header {
//
//         .menu {
//             padding-left: 0;
//         }
//     }
// }
