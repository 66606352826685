@import "./../../../../scss/colors.scss";
@import "./../../../../scss/typography.scss";
@import "./../../../../scss/mixins/linkpseudos.scss";
@import "./../../../../scss/responsive.scss";

$list-card-font-size-small: 13px;
$list-card-line-height-small: 20px;
$list-card-font-size-tiny: 11px;
$list-card-line-height-tiny: 20px;
$list-card-font-size-big: 15px;
$list-card-line-height-big: 20px;

$medium-list-card-font-size-small: 15px;
$medium-list-card-line-height-small: 24px;
$medium-list-card-font-size-tiny: 13px;
$medium-list-card-line-height-tiny: 24px;
$medium-list-card-font-size-big: 18px;
$medium-list-card-line-height-big: 24px;

$info-line-height: 19px;
$info-font-size: 13px;

$info-line-height-medium: 21px;
$info-font-size-medium: 15px;

// this nolonger works - because now wrapped in list-card-wrapper so cannot use adjacent selector
// but it may not be needed anyway because of other design changes.
// .post-list-card.has-responses + .post-list-card {
//     .post-breadcrumbs .toggle {
//         padding-top: 10px;
//     }
// }

.post-list-card-wrapper {
  // all wrappers
  & > .post-list-card > article {
    padding-top: 0px;
  }
}
.post-list-card-wrapper .post-list-card-wrapper {
  // 2nd level
  & > .post-list-card > article {
    padding-top: 10px;
  }
}
.post-list-card-wrapper + .post-list-card-wrapper {
  // exclude non-first children
  & > .post-list-card > article {
    padding-top: 0px;
  }
}
.post-list-card-wrapper .post-list-card-wrapper .post-list-card-wrapper {
  // exclude 3rd level
  & > .post-list-card > article {
    padding-top: 0px;
  }
}

.post-list-card {
  font-size: $list-card-font-size-small;
  line-height: $list-card-line-height-small;
  overflow: visible;
  display: flex;
  flex-direction: column;
  background: transparent;

  // JMR: replaced by separate set of styles
  // margin-bottom: 4px;

  &.deleted {
    opacity: 0.5;
  }
  &.injected {
    // border: 2px dashed #bada55;
  }

  & > * {
    flex: 1 1 auto;
  }

  a {
    text-decoration: none;
    @include linkpseudos {
      text-decoration: none;
    }
    &:hover {
      text-decoration: underline;
    }
  }

  & > .responses-container {
    background-color: $card-white;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  & > article {
    display: flex;
    background-color: transparent;
    line-height: $list-card-line-height-small;
    font-size: $list-card-font-size-small;
    padding-bottom: 0;
    background-color: $card-white;

    display: grid;
    grid-template-columns: [card-start] 12px [avatar-start] 34px [avatar-end] 12px [content-start] auto [content-end] 12px [card-end];
    grid-template-rows: [card-start] 26px [avatar-start] 34px [avatar-end] auto [content-end] 20px [card-end];
    grid-template-areas:
      ". . . . ."
      ". avatar . content ."
      ". .      . content ."
      ". responses-button . . .";

    // JMR: replaced by separate set of styles
    // &:after {
    //   content: " ";
    //   // display: block;
    //   display: none;
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   right: 0;
    //   height: 3px;
    //   background-color: $canvas-grey;
    // }

    .avatar-link {
      height: 38px;
      width: 38px; // includes below border
      position: relative;
      // grid-column-start: avatar-start;
      // grid-column-end: avatar-end;
      // grid-row-start: avatar-start;
      // grid-row-end: avatar-end;
      grid-area: avatar;
      justify-self: center;
      align-self: center;
      // z-index: 1;

      .image {
        max-height: 34px;
        max-width: 34px;
        border: 2px solid white;
      }
      .image + .image {
        max-height: 24px;
        max-width: 24px;
        border: 3px solid white;
        position: absolute;
        top: -3px;
        right: -8px;
      }
    } // .avatar-link

    .card-content {
      grid-area: content;

      // this fixes the issue of the thread lines running over text
      // but causes the content to be on top of the previous posts
      // pop-up reputation
      // z-index: 1; 

      .byline {
        display: flex;
        align-items: center;
        margin: 0;
        line-height: $info-line-height;
        font-size: $info-font-size;
        .people {
          flex: 0 1 auto;
        }
        .author {
          @include fontSansBlack;
          line-height: $info-line-height;
          font-size: $info-font-size;
          cursor: pointer;
          color: var(--highlight-color);
          display: flex;
          flex-direction: row;
          align-items: center;

          .ancestor-count {
            display: inline;
          }

          time {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: var(--highlight-color);
            @include fontSansBold;
            line-height: $info-line-height;
            font-size: $info-font-size;
            flex: 0 0 auto;
            white-space: nowrap;
            &:before {
              content: "•";
              font-size: 6px;
              line-height: 6px;
              margin: 0 3px;
            }
          }
        }
      }

      .reputation-post-overview,
      .reputation-generic-overview {
        margin-left: auto;
        margin-right: 4px;
      }
      .reputation-stars {
        flex: 0 0 auto;
        margin-left: 0;
        height: 13px;
        line-height: 13px;
        margin-right: 0;
        canvas {
          height: 13px;
          width: 46px;
        }
      }

      .parent-author {
        display: none; // shown when in responses
        color: var(--highlight-color);
        text-align: left;
        align-items: center;
        justify-content: flex-start;
        @include fontSansItalic;
        line-height: $info-line-height;
        font-size: $info-font-size;
      }

      .reposted-by {
        display: block;
        color: var(--highlight-color);
        @include fontSansItalic;
        line-height: $info-line-height;
        font-size: $info-font-size;
        cursor: pointer;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;

        & > .icon {
          height: 22px;
          width: 22px;
          margin-left: 0px;
          vertical-align: bottom;
        }

        .reposted-by-in {
          display: inline;
          .name {
            display: inline;
          }
        }
        .reposted-by-via {
          display: inline;
          .name {
            display: inline;
          }
        }
        .reposted-by-via + .reposted-by-in {
          &:before {
            content: " ";
          }
        }
        .repost-comment {
          font-style: italic;
          display: block;
          &:before {
            content: "‘";
          }
          &:after {
            content: "’";
          }
        }
      }

      .ancestor-title {
        color: var(--highlight-color);
        @include fontSansItalic;
        margin: 0;
        line-height: $info-line-height;
        font-size: $info-font-size;
      }

      .post-content,
      h1,
      .comment,
      .link-content,
      .controls,
      .parent-author,
      .byline {
        margin-right: 0;
      }
      .post-content {
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        @include fontSansRegular;
      }
      h1 {
        font-size: $list-card-font-size-big;
        line-height: calc($list-card-line-height-big + 3px);
        margin-top: 0;
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        @include fontSansBlack;
      }
      .comment {
        font-size: $list-card-font-size-big;
        line-height: calc($list-card-line-height-big + 1px);
        margin-top: 0;
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        @include fontSansRegular;
      }
      .link-content {
        font-size: $list-card-font-size-small;
        line-height: calc($list-card-line-height-small + 2px);
        @include fontSansRegular;
        color: var(--highlight-color);

        .link-content-title {
          @include fontSansBlack;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          line-clamp: 1;
        }
        .link-content-domain {
          font-size: $list-card-font-size-tiny;
          line-height: calc($list-card-line-height-tiny + 1px);
          @include fontSansRegular;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          line-clamp: 1;
        }
      }

      .post-stats {
        cursor: pointer;
        color: $text-black;
        @include fontSansItalic;
        font-size: $list-card-font-size-tiny;
        line-height: calc($list-card-line-height-tiny + 1px);
        text-decoration: underline;
        &.comment {
          text-decoration: none;
        }
      } // .post-stats
    }

    .responses-button-container {
      grid-area: responses-button;
      justify-self: center;
      align-self: center;
      z-index: 2;
    } // .responses-button-container
  } // article

  &.expanded.has-responses {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    & > article {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .responses-button-container {
      & > .responses-button {
        box-shadow: 0 0 0 3px white;
        background-color: $canvas-grey;
        span {
          color: var(--highlight-color);
        }
      }
      .icon {
        display: block;
      }
    }
  }
} // .post-list-card

.post-list-card,
.post-content-card {
  .responses {
    .post-list-card.expanded.has-responses {
      // the post that has been expanded (not it's children)
      & > article {
        overflow: visible;
        &:after {
          left: 58px;
        }

        &:after {
          content: " ";
          display: block;
          position: absolute;
          bottom: -12px;
          left: 1px;
          right: auto;
          width: 56px;
          height: 16px;
          background: url("/images/chevron.png") no-repeat transparent;
          background-position: bottom center;
          background-size: 182px 16px;
          z-index: 4;
        }

        .responses-button-container {
          display: none;
        }
      }
    } // .post-list-card.expanded.has-responses
  } // .responses
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  .post-list-card,
  .post-content-card {
    .responses {
      .post-list-card.expanded.has-responses {
        & > article {
          &:after {
            background: url("/images/chevron-retina.png") no-repeat transparent;
            background-position: bottom center;
            background-size: 182px 16px;
          }
        }
      } // .post-list-card.expanded.has-responses
    } // .responses
  } // .post-list-card
}

// deal with the child lines
// .post-list-card.expanded.has-responses,
// .post-list-card-wrapper > .post-content-card {
//   .responses {
//     position: relative;
//
//     & > .wall {
//       border-bottom-left-radius: 12px;
//       border-bottom-right-radius: 12px;
//     }
//
//     .responses-button-container {
//       display: none;
//     }
//
//     .post-list-card,
//     .post-content-card {
//       & > article {
//         position: relative;
//         & > .card-content,
//         & > .card-content-margin {
//           z-index: 4;
//         }
//         &:before {
//           content: " ";
//           background: linear-gradient(
//               to right,
//               rgba(255, 255, 255, 0) 3px,
//               rgba(255, 255, 255, 0) 3px,
//               $canvas-grey 4px,
//               $canvas-grey 7px,
//               rgba(255, 255, 255, 0) 7px,
//               rgba(255, 255, 255, 0) 8px,
//               rgba(255, 255, 255, 0)
//             ),
//             rgba(255, 255, 255, 0);
//           background-size: 8px;
//           background-repeat: repeat;
//           position: absolute;
//           top: 5px;
//           left: 0;
//           bottom: 2px;
//           width: 8px;
//           z-index: 1;
//         }
//       }

// deal with the child lines
.post-list-card.expanded.has-responses,
.post-content-card.expanded.has-responses {
  .responses {
    position: relative;

    & > .wall {
      // border-bottom-left-radius: 12px;
      // border-bottom-right-radius: 12px;
      // overflow: hidden;

      & > .post-list {
        .post-list-card.response:last-child {
          & > article > .card-content:after {
            display: none;
          }
        }
        .post-list-card.expanded.has-responses.response:last-child {
          & > article > .card-content:after {
            display: block;
          }
        }
      }
    }

    .post-list-card {
      & > article {
        position: relative;
        & > .card-content {
          // z-index: 4; // removed to allow the reputation overview to cover following card content
          position: relative;
        }
        &:before {
          content: " ";
          background: linear-gradient(
              to right,
              rgba(255, 255, 255, 0) 3px,
              rgba(255, 255, 255, 0) 3px,
              $canvas-grey 4px,
              $canvas-grey 7px,
              rgba(255, 255, 255, 0) 7px,
              rgba(255, 255, 255, 0) 8px,
              rgba(255, 255, 255, 0)
            ),
            rgba(255, 255, 255, 0);
          background-size: 8px;
          background-repeat: repeat;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: 8px;
          // z-index: 1;
        }
      }

      &.expanded > article {
        position: relative;
        &:before {
          bottom: 3px;
        }
      }

      .responses .response {
        & > article:before {
          width: 16px;
        }
        .responses .response {
          article:before {
            width: 24px;
          }
          .responses .response {
            article:before {
              width: 32px;
            }
            .responses .response {
              article:before {
                width: 40px;
              }
              .responses .response {
                article:before {
                  width: 48px;
                }
                .responses .response {
                  article:before {
                    width: 56px;
                  }
                  .responses .response {
                    article:before {
                      width: 64px;
                    }
                    .responses .response {
                      article:before {
                        width: 72px;
                      }
                      .responses .response {
                        article:before {
                          width: 80px;
                        }
                        .responses .response {
                          article:before {
                            width: 88px;
                          }
                          .responses .response {
                            article:before {
                              width: 96px;
                            }
                            .responses .response {
                              article:before {
                                width: 104px;
                              }
                              .responses .response {
                                article:before {
                                  width: 112px;
                                }
                                .responses .response {
                                  article:before {
                                    width: 120px;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// dedicated styles to deal with the lines across in different circumstances
/* sets the border radius and margin for top level posts */
.post-list-card {
  margin: 0 0 3px 0;
  padding: 0;
  position: relative;
}

/* unsets the border radius and margin for responses */
.post-list-card .post-list-card,
.post-content-card .post-list-card {
  margin: 0;
  border-radius: 0;
}

.post-list-card .post-list-card:after,
.post-content-card .post-list-card:after {
  // draws standard line across for child posts
  content: " ";
  position: absolute;
  top: 0;
  bottom: auto;
  right: 3px;
  height: 3px;
  background: $canvas-grey;
  left: 58px;
}

// .post-list-card .expanded + .post-list-card:after {
// doesn't work because of the post-list-card-wrapper
// make line full width after an expanded card
//   left: 3px;
// }

.post-list-card.expanded:after {
  // this line shows at the top of the post that has been expanded
}

.post-list-card.expanded
  > .responses-container
  > .responses
  > .wall
  > .post-list
  > .post-list-card-wrapper:first-child
  > .post-list-card:after,
.post-content-card.expanded
  > .responses-container
  > .responses
  > .wall
  > .post-list
  > .post-list-card-wrapper:first-child
  > .post-list-card:after {
  // this line shows at start of new level - ie. after parent is expanded
  left: 3px;
}

// the below doesn't work because we are drawing this line underneath the
// last child and so we get the line drawn after the whole list ends when
// the last item is expanded to show its responses.
// .post-list-card.expanded
//   > .responses-container
//   > .responses
//   > .wall
//   > .post-list
//   > .post-list-card-wrapper:last-child
//   .post-list-card:before,
// .post-content-card.expanded
//   > .responses-container
//   > .responses
//   > .wall
//   > .post-list
//   > .post-list-card-wrapper:last-child
//   .post-list-card:before {
//   content: " ";
//   position: absolute;
//   top: auto;
//   bottom: -5px;
//   right: 60%;
//   height: 3px;
//   left: 3px;
//   z-index: 3;
//   // background: $canvas-grey;
//   // border-bottom: 2px solid white;
//   background: red;
//   border-bottom: yellow;
// }

// instead of the above, we can draw the extra line at the top of any item
// that is preceded by an expanded item (same logic as contentcard but required)
// change to allow the wrapper to know if it's content had been expanded or not
// so we can compare siblings
.post-list-card-wrapper .responses-expanded + .post-list-card-wrapper {
  // make line full width after an expanded card
  position: relative;
  &:before {
    content: " ";
    position: absolute;
    top: 0;
    right: 60%;
    height: 3px;
    left: 3px;
    z-index: 3;
    background: $canvas-grey;
    border-bottom: 2px solid white;
  }
}

.expanded.has-responses {
  .responses {
    .post-list-card-wrapper:first-child {
      & > .post-list-card > article:before {
        top: 5px;
      }
    }
    .post-list-card-wrapper:last-child {
      & > .post-list-card > article:before {
        bottom: 3px;
      }
    }
  }
}

@media only screen and (min-width: $mobile-width) {
  .post-content-card {
    .responses {
      .post-list-card.expanded.has-responses {
        & > article:after {
          left: 31px;
        }
      }
    }

    .post-list-card:after {
      left: 96px;
    }
  }

  .post-list-card {
    font-size: $medium-list-card-font-size-small;
    line-height: $medium-list-card-line-height-small;

    .responses .post-list-card.expanded.has-responses > article:after {
      left: 31px;
    }

    .post-list-card:after {
      left: 96px;
    }

    article {
      border-radius: 12px;

      // display: grid;
      grid-template-columns:
        [card-start] 40px [avatar-start] 40px [avatar-end] 16px [content-start] minmax(
          auto,
          664px
        )
        [content-end] 40px [card-end];
      grid-template-rows: [card-start] 26px [avatar-start] 40px [avatar-end] auto [content-end] 26px [card-end];
      // grid-template-areas:
      // ". . . . ."
      // ". avatar . content ."
      // ". .      . content ."
      // ". responses-button . . .";

      .avatar-link {
        height: 44px;
        width: 44px; // includes below border
        .image {
          max-height: 40px;
          max-width: 40px;
          border: 2px solid white;
        }
      } // .avatar-link

      .card-content {
        line-height: $medium-list-card-line-height-small;
        font-size: $medium-list-card-font-size-small;

        .ancestor-title,
        .post-content,
        h1,
        .comment,
        .controls,
        .parent-author,
        .user-summary,
        .link-content {
          margin-right: 99px;
        }

        .post-content .post-content {
          margin-right: 0;
        }

        .byline {
          line-height: $info-line-height-medium;
          font-size: $info-font-size-medium;

          .author {
            line-height: $info-line-height-medium;
            font-size: $info-font-size-medium;
            time {
              line-height: $info-line-height-medium;
              font-size: $info-font-size-medium;
              &:before {
                font-size: 6px;
              }
            }
          }
          .activity-sum {
          }
        }

        .reputation-stars {
          height: 15px;
          line-height: 15px;
          canvas {
            height: 15px;
            width: 54px;
          }
        }

        .parent-author {
          line-height: $info-line-height-medium;
          font-size: $info-font-size-medium;
        }

        .reposted-by {
          line-height: $info-line-height-medium;
          font-size: $info-font-size-medium;
        }

        .ancestor-title {
          line-height: $info-line-height-medium;
          font-size: $info-font-size-medium;
        }

        h1 {
          font-size: $medium-list-card-font-size-big;
          line-height: calc($medium-list-card-line-height-big + 4px);
        }

        .comment {
          font-size: $medium-list-card-font-size-big;
          line-height: calc($medium-list-card-line-height-big);
        }

        .link-content {
          font-size: $medium-list-card-font-size-small;
          line-height: calc($medium-list-card-line-height-small + 2px);

          .link-content-domain {
            font-size: $medium-list-card-font-size-tiny;
            line-height: calc($medium-list-card-line-height-tiny + 1px);
          }
        }

        .post-stats {
          font-size: $medium-list-card-font-size-tiny;
          line-height: calc($medium-list-card-line-height-tiny + 4px);
        } // .post-stats
      }
    }

    // deal with the child lines
    // &.expanded.has-responses,
    // .post-list-card-wrapper > .post-content-card {
    //   .responses {
    //     .post-list-card,
    //     .post-content-card {
    //       & > article {
    //         &:before {
    //           border-bottom-left-radius: 12px;
    //         }
    //       }
    //     }
    //   }
    // }
  }

  // dedicated styles to deal with the lines across in different circumstances
  .post-list-card .post-content-card:after,
  .post-list-card .post-list-card:after {
    // draws standard line across for child posts
    content: " ";
    right: 3px;
    left: 96px;
  }

  .post-list-card.expanded
    > .responses-container
    > .responses
    > .wall
    > .post-list
    > .post-list-card-wrapper:first-child
    > .post-content-card:after,
  .post-list-card.expanded
    > .responses-container
    > .responses
    > .wall
    > .post-list
    > .post-list-card-wrapper:first-child
    > .post-list-card:after {
    left: 3px;
  }
}
