.feed-views {
    svg {
        .bars {
            rect {
                transition: y 1000ms ease 0s, height 1000ms ease 0s;

                &:last-of-type {
                    transition: y 1000ms ease 350ms, height 1000ms ease 350ms;

                }
            }
        }
    }


}
