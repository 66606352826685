@import "./../../../../scss/colors.scss";
@import "./../../../../scss/typography.scss";
@import "./../../../../scss/mixins/linkpseudos.scss";
@import "./../../../../scss/responsive.scss";

// $headerImageMaxHeight: 215px;
$headerImageMaxHeight: none;
// $headerImageMaxHeightWide: 320px;
$headerImageMaxHeightWide: 66vh;

$content-card-font-size-small: 13px;
$content-card-line-height-small: 21px;
$content-card-font-size-tiny: 11px;
$content-card-line-height-tiny: 21px;
$content-card-font-size-big: 17px;
$content-card-line-height-big: 24px;

$medium-content-card-font-size-small: 15px;
$medium-content-card-line-height-small: 24px;
$medium-content-card-font-size-tiny: 13px;
$medium-content-card-line-height-tiny: 24px;
$medium-content-card-font-size-big: 22px;
$medium-content-card-line-height-big: 33px;

$info-line-height: 19px;
$info-font-size: 13px;

$info-line-height-medium: 21px;
$info-font-size-medium: 15px;

.post-content-card.has-responses + .post-content-card {
  .post-breadcrumbs .toggle {
    padding-top: 26px;
  }
}

.post-content-card {
  font-size: $content-card-font-size-small;
  line-height: $content-card-line-height-small;
  overflow: visible;
  display: flex;
  flex-direction: column;
  margin-bottom: 3px;

  &.deleted {
    opacity: 0.5;
  }
  &.injected {
    // border: 2px dashed #bada55;
    // background-color: #ccc;
    // article,
    // .card-content-margin,
    // .card-content {
    //     background-color: #ccc;
    //     .activity-icon-button .icon {
    //         background-color: #ccc;
    //     }
    // }
  }

  & > * {
    flex: 1 1 auto;
  }

  a {
    text-decoration: none;
    @include linkpseudos {
      text-decoration: none;
    }
    &:hover {
      text-decoration: underline;
    }
  }

  .responses &.response article .ancestor-title {
    display: none;
  }

  &.show-content {
    article {
      .header-image {
        .image {
          cursor: pointer;
          max-height: inherit;
        }
        .embed {
          iframe {
            max-height: inherit;
          }
        }
      } //.header-image
    }
  }

  & > article {
    background-color: $card-white;

    .card-content {
      position: relative;
      display: grid;
      grid-template-columns: [card-start] 12px [avatar-start] 34px [avatar-end] 12px [content-start] auto [content-end] 12px [card-end];
      grid-template-rows: [card-start] 26px [avatar-start] auto [avatar-end] auto [content-end] 0px [next-button] 10px [card-end];
      grid-template-areas:
        ". . . . ."
        ". avatar . byline-content ."
        ". content content content ."
        ". next-button . . ."
        ". responses-button . reply-button .";
      line-height: $content-card-line-height-big;
      // font-size: $content-card-font-size-big;
      font-size: 15px;

      .avatar-link {
        height: 38px;
        width: 38px; // includes below border
        position: relative;
        grid-area: avatar;
        justify-self: center;
        align-self: start;
        z-index: 1;

        .image {
          max-height: 34px;
          max-width: 34px;
          border: 2px solid white;
        }
        .image + .image {
          max-height: 16px;
          max-width: 16px;
          border: 3px solid white;
          position: absolute;
          top: -3px;
          right: -8px;
        }
      } // .avatar-link

      .byline-content {
        align-self: center;
        grid-area: byline-content;
      }

      .content {
        margin-top: 10px;
        grid-area: content;
      }

      .byline {
        display: flex;
        align-items: center;
        margin: 0;
        font-size: $info-font-size;
        line-height: $info-line-height;

        .author {
          @include fontSansBlack;
          line-height: $info-line-height;
          font-size: $info-font-size;
          cursor: pointer;
          color: var(--highlight-color);
          display: flex;
          flex-direction: row;
          align-items: center;

          .ancestor-count {
            display: inline;
          }

          time {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: var(--highlight-color);
            @include fontSansBold;
            line-height: $info-line-height;
            font-size: $info-font-size;
            flex: 0 0 auto;
            white-space: nowrap;
            &:before {
              content: "•";
              font-size: 6px;
              line-height: 6px;
              margin: 0 3px;
            }
          }
        }

        .reputation-post-overview,
        .reputation-generic-overview {
          margin-left: auto;
          margin-right: 4px;
        }

        .reputation-stars {
          flex: 0 0 auto;
          margin-left: 0;
          height: 13px;
          line-height: 13px;
          margin-right: 0;
          canvas {
            height: 13px;
            width: 46px;
          }
        }
      } // .byline

      .parent-author {
        display: none; // shown when in responses
        color: var(--highlight-color);
        text-align: left;
        align-items: center;
        justify-content: flex-start;
        @include fontSansBoldItalic;
        font-size: $info-font-size;
        line-height: $info-line-height;
      }

      .reposted-by {
        display: block;
        // color: $text-grey;
        color: var(--highlight-color);
        @include fontSansItalic;
        font-size: $info-font-size;
        line-height: $info-line-height;
        cursor: pointer;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;

        .reposted-by-in {
          display: inline;
          .name {
            display: inline;
          }
        }
        .reposted-by-via {
          display: inline;
          .name {
            display: inline;
          }
        }
        .reposted-by-via + .reposted-by-in {
          &:before {
            content: " ";
          }
        }
        .name {
          display: inline;
          // @include fontSansBoldItalic;
        }
        .repost-comment {
          font-style: italic;
          display: block;
          &:before {
            content: "‘";
          }
          &:after {
            content: "’";
          }
        }
      }

      .tags {
        color: var(--highlight-color);
        @include fontSansBlackItalic;
        font-size: $info-font-size;
        line-height: $info-line-height;

        a {
          @include fontSansBlackItalic;
          @include linkpseudos {
            color: var(--highlight-color);
            text-decoration: none;
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }
      .ancestor-title {
        color: var(--highlight-color);
        @include fontSansItalic;
        margin: 0;
        font-size: $info-font-size;
        line-height: $info-line-height;
      }

      header-image,
      .post-content,
      h1,
      .comment,
      .controls,
      .parent-author,
      .tags,
      .reactions,
      .reposted-by,
      .user-summary,
      .byline {
        margin-right: 0;
      }
      h1 {
        @include fontSansBlack;
        font-size: 20px;
        line-height: 28px;
        margin-top: 0;
        margin-bottom: 0;
      }

      h1 + .post-content {
        margin-top: 3px;
      }

      .comment {
        @include fontSerifRegular;
        font-size: 16px;
        line-height: 26px;
      }

      .user-summary {
        @include fontSansRegular;
        font-size: 15px;
        line-height: 24px;
        margin: 5px 0 0 0;
      }

      .header-image {
        max-width: calc(100% + 24px);
        overflow: hidden;
        margin: 15px -12px 10px -12px;
        .image {
          cursor: pointer;
          max-width: 100%;
          overflow: hidden;
          border-radius: 0;
          // border-radius: 6px;
          max-height: $headerImageMaxHeight;
        }
        .embed {
          max-width: 100%;
          overflow: hidden;
          iframe {
            max-width: 100%;
            min-width: 100%;
            border-radius: 0;
            // border-radius: 6px;
            max-height: $headerImageMaxHeight;
          }
        }
      } //.header-image

      .embed-thumb {
        max-width: 100%;
        overflow: hidden;
        border-radius: 0;

        .image {
          max-width: 100%;
          overflow: hidden;
          border-radius: 0;
          // max-height: $headerImageMaxHeight;
          max-height: none;
        }

        h4 {
          @include fontSansBlack;
          font-size: 17px;
          line-height: 26px;
          margin: 0 12px;
        }
        p {
          @include fontSansRegular;
          font-size: $content-card-font-size-small;
          line-height: $content-card-line-height-small;
          margin: 0 12px;
          &.link {
            @include fontSansBlack;
          }
        }
        a {
          @include linkpseudos {
            text-decoration: none;
          }

          &:hover {
            text-decoration: underline;
          }
        }
      } // .embed-thumb

      .post-content {
        @include fontSerifRegular;
        font-size: 16px;
        line-height: 26px;

        iframe {
          max-width: 100%;
          min-width: 100%;
          overflow: hidden;
          border-radius: 0;
          max-height: $headerImageMaxHeight;
          // max-height: none;
          // max-height: 50vh;
          // height: auto;
        }
      } // .post-content

      .actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: $text-black;
        margin-top: 10px;

        .activity-sum {
          font-size: 15px;
          line-height: 36px;
          padding-right: 15px;
          position: relative;
          margin-right: 7px;
          cursor: pointer;

          &:after {
            content: " ";
            position: absolute;
            top: 5px;
            right: 0;
            background-color: $lighter-grey;
            width: 3px;
            height: 25px;
          }
        }
        .activity-button {
          .button {
            color: $text-black;
          }
          &.set .button {
            color: var(--highlight-color);
          }
        }

        & > * {
          flex: 0 0 auto;
          margin-left: 0;
          height: 32px;
          max-height: 32px;
          // overflow: hidden;
          .button {
            // margin: 0;
            border: none;
          }
          // border: 1px dotted red;

          .activity-icon-upvote,
          .activity-icon-downvote {
            height: 32px;
            width: 32px;
          }
          .activity-icon-downvote {
            margin-left: 2px;
          }
          .icon {
            height: 32px;
            width: 32px;
          }
        }

        .custom_images {
          .icon {
            margin-left: 0;
          }
        }
        .icon {
          height: 32px;
          width: 32px;
          margin-left: 2px;

          &.post-menu-icon {
            margin-left: 0;
          }
        }

        .generic-dropup {
          margin-left: 3px;

          .handle {
            display: flex;
            align-items: center;
            .icon {
              margin-left: 0;
            }
          }
        }

        // activity at this level so the styles also carry-through to the footer
        .activity {
          font-size: $content-card-font-size-small;
          line-height: 36px;
          @include fontSansRegular;

          .activity-sum {
            display: inline;
            position: relative;
            padding: 3px 15px 3px 0;

            &.activity-sum-0 {
              display: none;
            }

            &:after {
              content: " ";
              position: absolute;
              top: 0;
              right: 0;
              width: 3px;
              bottom: 0;
              background: $canvas-grey;
            }
          }
        } // .activity
      } // .actions

      .editor-richtext {
        @include fontSerifRegular;
        font-size: 16px;
        line-height: 26px;

        .content {
          @include fontSerifRegular;
          font-size: 16px;
          line-height: 26px;
          margin-top: 0;

          .block:after {
            content: "";
            display: none;
          }

          .embed-block,
          .upload-block {
            margin-left: -12px;
            margin-right: -12px;
            max-width: calc(100% + 24px);
            width: calc(100% + 24px);
            .caption {
              margin-left: 12px;
              margin-right: 12px;
            }
          }

          .text-block {
            margin: 0;
            @include fontSerifRegular;
            font-size: 16px;
            line-height: 26px;
            padding: 4px 0;
            min-height: auto;
          }

          h2.text-block {
            @include fontSerifBold;
            line-height: 26px;
            font-size: 20px;
          }

          p:first-child {
            padding-top: 0;
          }
          iframe {
            max-width: 100%;
            min-width: 100%;
            overflow: hidden;
            border-radius: 0;
            // border-radius: 6px 6px 0 0;
            max-height: $headerImageMaxHeight;
          }

          .twitter-block {
            .tweet {
              overflow: hidden;
              .twitter-tweet {
                width: 100% !important;
                min-width: 100px !important;
              }
            }
          }
        }
      } // .editor-richtext

      .post-stats {
        color: $text-black;
        @include fontSansItalic;
        font-size: $content-card-font-size-tiny;
        line-height: $content-card-line-height-tiny;
        text-decoration: underline;
      } // .post-stats

      .controls {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        color: $text-black;
        margin-top: 10px;

        .activity-count {
          font-size: 15px;
          line-height: 29px;
          padding-right: 12px;
          position: relative;
          margin-right: 4px;

          &:after {
            content: " ";
            position: absolute;
            top: 5px;
            right: 0;
            background-color: $lighter-grey;
            width: 3px;
            height: 19px;
          }
        }

        .activity-button {
          .button {
            color: $text-black;
          }
          &.set .button {
            color: var(--highlight-color);
          }
        }

        & > * {
          flex: 0 0 auto;
          margin-left: 0;
          height: 29px;
        }

        .upvote-button {
          .flag {
            left: 7px;

            &.level-1,
            &.level-2,
            &.level-3 {
              left: auto;
              right: -1px;
            }
          }
        }
        .icon,
        .activity-icon-upvote {
          height: 27px;
          width: 27px;
          margin-left: 9px;
        }

        .generic-dropup {
          margin-left: 3px;

          .handle {
            display: flex;
            align-items: center;
            .icon {
              margin-left: 0;
            }
          }
        }
      } // .controls

      .reactions {
        &.open {
          .reaction-buttons:before {
            right: 97px;
          }

          .reaction-buttons-wrapper {
            margin-bottom: 10px;
          }

          .totals {
            right: 32px;
            .activity-count {
              &.downvote,
              &.repost,
              &.bookmark {
                margin-left: 2px;
              }
            }
          }
        }
      } // .reactions
    } // .card-content

    .responses-button-container {
      transform: translateY(7px);
    }

    .reply-area {
      grid-area: reply-button;
      justify-self: self-start;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      z-index: 9; // above next card!
      gap: 8px;
    }
    .reply-button-container {
      flex: 0 0 auto;
      align-self: center;
      display: flex;
      justify-content: center;
      align-items: center;

      border: 3px solid $canvas-grey;
      background-color: white;
      color: $text-black;
      border-radius: 24px;
      width: auto;
      height: 16px;
      line-height: $content-card-line-height-small;
      cursor: pointer;
      font-size: 10px;
      line-height: 14px;
      padding: 0px 8px 0 3px;
      transform: translateY(7px);

      .icon {
        color: var(--highlight-color);
        height: 16px;
        width: 16px;
      }
    } // .reply-button-container

    .door-policy-summary-container {
      flex: 0 0 auto;
      align-self: center;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translateY(7px);
      line-height: 14px;
      height: 14px;
      width: 14px;
      border-radius: 50%;
      border: 3px solid $canvas-grey;
      background-color: white;

      .door-policy-summary {
        height: 14px;
        width: 14px;
        color: var(--highlight-color);
      }
      .icon {
        height: 14px;
        width: 14px;
      }
    }

    .responses-button-container {
      grid-area: responses-button;
      justify-self: center;
      align-self: center;
      z-index: 2;
    } // .responses-button-container

    .content-card-button {
      grid-area: next-button;
      position: sticky;
      bottom: 24px;
      left: 12px;
      margin-top: 120px;
      align-self: end;
      justify-self: center;
      border: 2px solid white;
      background-color: white;
      border-radius: 50%;
      color: $text-black;
      cursor: pointer;
      height: 30px;
      width: 30px;
      z-index: 2001; // above footer
    }
  } // article

  &.expanded.has-responses {
    // the post that has been expanded (not it's children)

    .responses-button-container {
      & > .responses-button {
        box-shadow: 0 0 0 3px white;
        background-color: $canvas-grey;
        span {
          color: var(--highlight-color);
        }
      }
      .icon {
        display: block;
      }
    }
  }
} // .post-content-card

.post-content-card,
.post-list-card {
  .post-content-card,
  .post-list-card {
    .post-breadcrumbs {
      padding-top: 15px;
    }
  }

  .post-breadcrumbs {
    .toggle + .breadcrumb {
      post-list-card > article {
        grid-template-rows: [card-start] 15px [avatar-start] 34px [avatar-end] auto [content-end] 0px [next-button] 10px [card-end];
      }
    }
    .post-content-card article .card-content {
      grid-template-areas:
        ". . . . ."
        ". avatar . byline-content ."
        ". .      . content ."
        ". next-button . . ."
        ". responses-button . reply-button .";
    }
  }
}
.post-content-card,
.post-list-card {
  .responses {
    .post-content-card article .card-content {
      grid-template-areas:
        ". . . . ."
        ". avatar . byline-content ."
        ". .      . content ."
        ". next-button . . ."
        ". responses-button . reply-button .";

      .header-image {
        max-width: calc(100% + 12px);
        margin: 15px -12px 10px 0px;
      }
    }

    & > .wall > .post-list {
      & > .response:first-child {
        & > article > .card-content {
          margin-top: 10px;
        }
        & > article:before {
          top: 5px;
        }
      }

      & > .response:last-child {
        & > article:before {
          bottom: 3px;
        }
      }
    }

    .post-content-card article .card-content .parent-author {
      display: flex;
    }

    .post-content-card.expanded.has-responses {
      // the post that has been expanded (not it's children)
      & > article {
        overflow: visible;
        &:after {
          left: 58px;
        }

        & > .card-content:after {
          content: " ";
          display: block;
          position: absolute;
          bottom: -12px;
          left: 2px;
          right: auto;
          width: 56px;
          height: 16px;
          background: url("/images/chevron.png") no-repeat transparent;
          background-position: bottom center;
          background-size: 182px 16px;
          z-index: 4;
        }

        .responses-button-container {
          display: none;
        }
      }
    } // .post-content-card.expanded.has-responses
  } // .responses
} // .post-content-card

@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  .post-content-card,
  .post-list-card {
    .responses {
      .post-content-card.expanded.has-responses {
        & > article & > .card-content:after {
          background: url("/images/chevron-retina.png") no-repeat transparent;
          background-position: bottom center;
          background-size: 182px 16px;
        }
      } // .post-content-card.expanded.has-responses
    } // .responses
  } // .post-content-card
}

// deal with the child lines
.post-list-card.expanded.has-responses,
.post-content-card.expanded.has-responses {
  .responses {
    position: relative;

    & > .wall {
      // border-bottom-left-radius: 12px;
      // border-bottom-right-radius: 12px;
      // overflow: hidden;

      & > .post-list {
        .post-content-card.response:last-child {
          & > article > .card-content:after {
            display: none;
          }
        }
        .post-content-card.expanded.has-responses.response:last-child {
          & > article > .card-content:after {
            display: block;
          }
        }
      }
    }

    .post-content-card {
      & > article {
        position: relative;
        & > .card-content {
          z-index: 4;
          position: relative;
        }
        &:before {
          content: " ";
          background: linear-gradient(
              to right,
              rgba(255, 255, 255, 0) 3px,
              rgba(255, 255, 255, 0) 3px,
              $canvas-grey 4px,
              $canvas-grey 7px,
              rgba(255, 255, 255, 0) 7px,
              rgba(255, 255, 255, 0) 8px,
              rgba(255, 255, 255, 0)
            ),
            rgba(255, 255, 255, 0);
          background-size: 8px;
          background-repeat: repeat;
          position: absolute;
          top: 5px;
          left: 0;
          bottom: 0;
          width: 8px;
          z-index: 1;
        }
      }

      .responses .post-content-card {
        article:before {
          width: 16px;
        }
        .responses .post-content-card {
          article:before {
            width: 24px;
          }
          .responses .post-content-card {
            article:before {
              width: 32px;
            }
            .responses .post-content-card {
              article:before {
                width: 40px;
              }
              .responses .post-content-card {
                article:before {
                  width: 48px;
                }
                .responses .post-content-card {
                  article:before {
                    width: 56px;
                  }
                  .responses .post-content-card {
                    article:before {
                      width: 64px;
                    }
                    .responses .post-content-card {
                      article:before {
                        width: 72px;
                      }
                      .responses .post-content-card {
                        article:before {
                          width: 80px;
                        }
                        .responses .post-content-card {
                          article:before {
                            width: 88px;
                          }
                          .responses .post-content-card {
                            article:before {
                              width: 96px;
                            }
                            .responses .post-content-card {
                              article:before {
                                width: 104px;
                              }
                              .responses .post-content-card {
                                article:before {
                                  width: 112px;
                                }
                                .responses .post-content-card {
                                  article:before {
                                    width: 120px;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// dedicated styles to deal with the lines across in different circumstances
/* sets the border radius and margin for top level posts */
.post-content-card {
  margin: 0 0 3px 0;
  padding: 0;
  position: relative;
}

/* unsets the border radius and margin for responses */
.post-list-card .post-content-card,
.post-content-card .post-content-card,
.post-content-card .post-list-card {
  margin: 0;
  border-radius: 0;
}

.post-list-card .post-content-card:after,
.post-content-card .post-content-card:after,
.post-content-card .post-list-card:after {
  // draws standard line across for child posts
  content: " ";
  position: absolute;
  top: 0;
  bottom: auto;
  right: 3px;
  height: 3px;
  background: $canvas-grey;
  left: 58px;
}

.post-content-card .post-breadcrumbs .breadcrumb .post-content-card:after,
.post-content-card .post-breadcrumbs .breadcrumb .post-list-card:after {
  content: "";
  display: none;
}

.post-list-card .expanded + .post-content-card:after,
.post-content-card .expanded + .post-content-card:after,
.post-content-card .expanded + .post-list-card:after {
  // make line full width after an expanded card
  left: 3px;
}

.post-content-card.expanded:after {
  // this line shows at the top of the post that has been expanded
}

.post-content-card.expanded
  > .responses-container
  > .responses
  > .wall
  > .post-list
  > .post-content-card:first-child:after,
.post-content-card.expanded
  > .responses-container
  > .responses
  > .wall
  > .post-list
  > .post-list-card-wrapper:first-child
  > .post-list-card:after {
  // this line shows at start of new level - ie. after parent is expanded
  left: 3px;
}

.post-content-card.expanded.has-responses .responses {
  .post-content-card:not(.expanded) + .post-content-card {
    & > article:before {
      top: 0;
      // bottom: 0;
    }

    &.expanded > article:before {
      bottom: 2px;
    }
  }
}

@media only screen and (min-width: $mobile-width) {
  .post-list-card {
    .responses
      .post-content-card.expanded.has-responses
      > article
      > .card-content:after {
      left: 31px;
    }

    .post-content-card.expanded.has-responses > article:after {
      left: 96px;
    }
  }
  .post-content-card {
    border-radius: 12px;
    // overflow: hidden;
    font-size: $medium-content-card-font-size-small;
    line-height: $medium-content-card-line-height-small;

    &.has-breadcrumbs {
      & > article {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      .post-breadcrumbs {
        background-color: $card-white;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
      }
    }

    .post-content-card.expanded.has-responses {
      .responses {
        position: relative;

        & > .wall {
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;
        }
      }
    }

    .responses
      .post-content-card.expanded.has-responses
      > article
      > .card-content:after {
      left: 31px;
    }

    .post-content-card.expanded.has-responses > article:after {
      left: 96px;
    }

    & > .responses-container {
      background-color: $card-white;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }

    &.expanded.has-responses {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      & > article {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .post-breadcrumbs .toggle {
      width: calc(100% - 30px);
      margin-left: 15px;
      margin-right: 15px;
    }
    .post-breadcrumbs {
      .breadcrumb {
        margin: 0 15px;
        width: calc(100% - 30px);
        .post-content-card {
          // margin: 0 -15px;
          .reply-button-container {
            transform: translateY(12px);
          }
        }
      }
    }

    &.show-content {
      article {
        .card-content {
          .header-image {
            .image {
              max-height: 3000px;
            }
          } //.header-image
        } // .card-content
      }
    }
    & > article {
      border-radius: 12px;

      .card-content {
        grid-template-columns: [card-start] 40px [avatar-start] 40px [avatar-end] 16px [content-start] auto [content-end] 40px [card-end];
        grid-template-rows: [card-start] 26px [avatar-start] auto [avatar-end] auto [content-end] 0px [next-button] 16px [card-end];
        grid-template-areas:
          ". . . . ."
          ". avatar . byline-content ."
          ". content content content ."
          ". next-button . . ."
          ". responses-button . reply-button .";

        line-height: $medium-content-card-line-height-big;
        // font-size: $medium-content-card-font-size-big;
        font-size: 18px;

        & > .avatar-link {
          height: 44px;
          width: 44px;
          .image {
            max-height: 40px;
            max-width: 40px;
          }
          .image + .image {
            max-height: 20px;
            max-width: 20px;
          }
        }

        .reply-button-container {
          border-radius: 21px;
          height: 21px;
          line-height: 21px;
          font-size: 12px;
          transform: translateY(10px);
          padding: 0 15px 0 10px;
          .icon {
            height: 21px;
            width: 21px;
          }
        } // .reply-button-container

        .responses-button-container {
          margin-left: 2px;
          height: 40px;
          width: 40px;
          transform: translateY(11px);
          .responses-button {
            height: 36px;
            width: 36px;
            line-height: 36px;
            @include fontSansBold;
            font-size: 14px;
            span {
              max-width: 36px;
            }
          }
          .icon {
            bottom: -11px;
            transform: translateX(-13px);
          }
        }

        .door-policy-summary-container {
          transform: translateY(11px);
          line-height: 21px;
          height: 21px;
          width: 21px;

          .door-policy-summary {
            height: 18px;
            width: 18px;
          }
          .icon {
            height: 16px;
            width: 16px;
          }
        }

        .header-image {
          margin: 15px 0 10px 0;
          max-width: 100%;

          .image {
            max-height: $headerImageMaxHeightWide;
          }
          .embed {
            iframe {
              max-height: $headerImageMaxHeightWide;
            }
          }
        } //.header-image

        .post-content .post-content {
          margin-right: 0;
        }

        .actions {
          & > * {
            .button {
              border: none;
              margin-bottom: 0;
            }
            .activity-icon-upvote,
            .activity-icon-downvote {
              height: 36px;
              width: 36px;
            }
            .activity-icon-downvote {
              margin-left: 9px;
            }
          }
          .icon {
            margin-left: 9px;
            height: 36px;
            width: 36px;
          }
        }

        .byline {
          font-size: $info-font-size-medium;
          line-height: $info-line-height-medium;
          margin-top: 0;
          margin-bottom: 0;

          .reputation-stars {
            height: 15px;
            line-height: 15px;
            canvas {
              height: 15px;
              width: 54px;
            }
          }

          .author {
            font-size: $info-font-size-medium;
            line-height: $info-line-height-medium;

            time {
              font-size: $info-font-size-medium;
              line-height: $info-line-height-medium;
            }
          }
        }

        .post-content {
          @include fontSerifRegular;
          font-size: 18px;
          line-height: 33px;
        }

        h1 {
          font-size: 24px;
          line-height: 36px;
        }
        .comment {
          @include fontSerifRegular;
          font-size: 18px;
          line-height: 33px;
        }

        .user-summary {
          font-size: 18px;
          line-height: 30px;
        }

        .reposted-by {
          font-size: $info-font-size-medium;
          line-height: $info-line-height-medium;
        }

        .tags {
          font-size: $info-font-size-medium;
          line-height: $info-line-height-medium;
        }

        .embed-thumb {
          h4 {
            font-size: 18px;
            line-height: 30px;
          }
          p {
            font-size: $medium-content-card-font-size-small;
            line-height: calc($medium-content-card-line-height-small + 2px);
          }
        } // .embed-thumb

        .editor-richtext {
          @include fontSerifRegular;
          font-size: 18px;
          line-height: 33px;

          .content {
            @include fontSerifRegular;
            font-size: 18px;
            line-height: 33px;

            .text-block {
              @include fontSerifRegular;
              font-size: 18px;
              line-height: 33px;
            }

            h2.text-block {
              line-height: 30px;
              font-size: 22px;
            }

            .embed-block,
            .upload-block {
              margin-left: 0;
              margin-right: 0;
              max-width: 100%;
              width: 100%;
              .caption {
                margin-left: 0;
                margin-right: 0;
              }
            }
          }
        } // .editor-richtext

        .post-stats {
          cursor: pointer;
          font-size: $medium-content-card-font-size-tiny;
          line-height: calc($medium-content-card-line-height-tiny + 4px);
        } // .post-stats

        .reactions {
          &.open {
            .totals {
              right: 36px;
              .activity-count {
                width: 36px;
                &.downvote,
                &.repost,
                &.bookmark {
                  margin-left: 9px;
                }
              }
            }
          }
        } // .reactions
      }
    }
  }

  // dedicated styles to deal with the lines across in different circumstances
  .post-list-card .post-content-card:after,
  .post-content-card .post-content-card:after,
  .post-content-card .post-list-card:after {
    // draws standard line across for child posts
    content: " ";
    right: 3px;
    left: 96px;
  }

  .post-content-card.expanded
    > .responses-container
    > .responses
    > .wall
    > .post-list
    > .post-content-card:first-child:after,
  .post-content-card.expanded
    > .responses-container
    > .responses
    > .wall
    > .post-list
    > .post-list-card-wrapper:first-child
    > .post-list-card:after {
    left: 3px;
  }
}
