// @import './../../../../scss/colors.scss';
// @import './../../../../scss/responsive.scss';

.dummy-card {
    background-color: #ebe7e7;
    height: 400px;
    overflow: hidden;

    &:nth-child(even) {
        height: 300px;
    }

    &.card-content {
        height: 300px;
        margin-bottom: 3px;
        &:nth-child(even) {
            height: 300px;
        }
    }

    &.card-list {
        height: 150px;
        margin-bottom: 3px;
        &:nth-child(even) {
            height: 150px;
        }
    }
}
