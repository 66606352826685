@import './../../../../scss/colors.scss';
@import './../../../../scss/typography.scss';
@import './../../../../scss/mixins/linkpseudos.scss';
@import './../../../../scss/responsive.scss';

.feed-edit-menu {
    margin-left: 20px;
    box-sizing: border-box;

    .handle {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .save-button {
            @include fontSansSemiBold;
            background-color: var(--highlight-color);
            color: white;
            cursor: pointer;
            flex: 0 0 105px;
            height: 32px;
            line-height: 16px;
            width: 105px;
            padding: 8px 0;
            text-align: center;
            box-sizing: border-box;
            border-radius: 16px 0 0 16px;
        }
        .toggle-button {
            background-color: $light-grey;
            color: white;
            cursor: pointer;
            flex: 0 0 36px;
            margin-left: 2px;
            height: 32px;
            width: 36px;
            text-align: center;
            box-sizing: border-box;
            border-radius: 0 16px 16px 0;
            .icon {
              margin: 4px 4px 4px 0;
              height: 24px;
              width: 24px;
            }
        }
    }

    &.open {
        width: 145px;

        .overlay {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            // background-color: rgba(0, 20, 0, 0.2);
            z-index: 20081;

            .sidebar {
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                background-color: white;
                z-index: 20091;
                background-clip: content-box;
                width: 350px;
                border-left: 3px solid $lighter-grey;

                .handle {
                    position: fixed;
                    top: 0;
                    right: 0;
                    height: 66px;
                    box-sizing: border-box;
                    width: 350px;
                    padding-right: 20px;
                    display: flex;
                    justify-content: flex-end;
                    align-items: flex-start;

                    .save-button {
                      margin-top: 15px;
                      user-select: none;
                    }
                    .toggle-button {
                      margin-top: 15px;
                      margin-right: 15px;
                      user-select: none;
                    }

                    .delete {
                      margin: 15px auto 0 15px;
                      height: 20px;
                      width: 20px;
                      cursor: pointer;
                      user-select: none;
                      color: $placeholder-grey;
                    }
                }

                .controls {
                    position: fixed;
                    top: 0;
                    right: 0;
                    top: 66px;
                    bottom: 0;
                    width: 350px;
                    box-sizing: border-box;
                    overflow: auto;
                    background-color: white;
                    animation: slide-in 600ms ease;
                    transition: transform 600ms;
                    padding: 10px 15px;

                    .title {
                        @include fontSansSemiBold;
                        font-size: 26px;
                        line-height: 40px;
                    }

                    .field {
                        border-top: 2px solid $lighter-grey;
                        border-bottom: 2px solid $lighter-grey;
                        padding: 5px 0;
                    }

                    h2 {
                      margin: 10px 0;
                      font-size: 18px;
                      line-height: 20px;
                      color: $dark-grey;
                    }

                    .tag-editor .tag {
                      margin-top: 3px;
                      margin-bottom: 3px;
                    }
                    .tag-editor .newTag {
                      padding: 0;
                    }
                    .tag-editor .newTag [contenteditable='true'] {
                      height: 32px;
                      line-height: 32px;
                      color: $light-grey;
                      padding: 0;
                      margin-top: 3px;
                      margin-bottom: 3px;
                      @include fontSansRegular;
                      &:before {
                        height: 32px;
                        line-height: 32px;
                        color: $light-grey;
                      }
                    }
                }
            }
        }
    }
}

@keyframes slide-in {
    0% {
        transform: translateX(300px);
    }
    100% {
        transform: translateX(0);
    }
}

@media only screen and (max-width: 768px) {
  .feed-edit-menu {
    &.open {
      .overlay {
          .sidebar {
            border-left: none;
            width: 100%;
            .handle {
              width: 100%;
              .toggle-button {
                margin-right: 0;
              }
            }
            .controls {
              width: 100%;
            }
          }
      }
    }
  }
}
