@import './../../../../scss/colors.scss';
@import './../../../../scss/typography.scss';
@import './../../../../scss/responsive.scss';

.layout-toolbar {

	display: block;
	position: sticky;
	top: 0;
	width: 100%;
	z-index: 10098;
	height: 50px;
	line-height: 48px;
    padding: 0;
	background-color: white;

	&.sticky {
		box-shadow: 0 6px 0 rgba(0, 0, 0, 0.05);
	}

    a {
		line-height: 20px;
		margin: 5px 0;
		font-size: 16px;
		padding: 4px 10px 6px 10px;
		@include fontSansBold;
		white-space: nowrap;
		text-decoration: none;
		color: $text-grey;

        &.tag {
            text-transform: capitalize;
            &:before {
                content: "#";
            }
        }

		a:visited, a:active, a:hover {
			text-decoration: none;
			color: $text-grey;
		}

		&:first-child {
			margin-left: 9px;
		}

		&.active {
			color: var(--highlight-color);
			border-radius: 4px;
			background-color: $lighter-grey;
		}

		&.tag {

			@include fontSansBold;
			text-transform: capitalize;
		}
	}
}

@media only screen and (min-width: $medium-width) {

    .layout-toolbar {

        padding: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        height: 65px;
        line-height: 63px;

        a {
    		line-height: 22px;
    		margin: 5px 10px;
    		font-size: 18px;
    		padding: 5px 12px 7px 12px;

            &:first-child {
    			margin-left: 0;
    		}
    	}

        .container {
            padding-left: 0;
        }

        .user-menu {
            a:first-child {
                padding-left: 3px;
            }
        }

    }
}

@media only screen and (min-width: 1220px) {

    .layout-toolbar {

        &:before {
            padding-left: 0;
        }
        &:after {
            padding-right: 0;
        }
        a {
    		margin: 5px 10px;
            padding: 4px 12px 6px 12px;
    	}
    }
}
