@import "./../../../../scss/colors.scss";
@import "./../../../../scss/typography.scss";

.layout-requestinvite {
  .tx2 {
    p {
      margin-bottom: 30px;
    }

    .field.name {
      border-top: 1px solid $lighter-grey;
    }
  }

  .content {
    .field.interests {
      // border-bottom: none;
      margin-bottom: 20px;
      padding-bottom: 15px;
    }

    .field.recaptcha {
      border-bottom: none;
      margin-bottom: 5px;
    }

    // .card {
    // styles added below to override specific overlay stuff
    // }
  }
}

.layout-overlay:not(.overlay-sidebar) .content > .card {
  border-top: 3px solid $lighter-grey;
  max-width: calc(100% - 40px);
  width: 720px;
  padding: 12px 0 0 0;

  & .content {
    padding: 0;
    max-width: calc(100% - 56px);

    .row {
      h1 {
        font-size: 18px;
        line-height: 30px;
      }
    }
    .stats {
      font-size: 14px;
      line-height: 14px;
      margin-top: 0;
      transform: translateY(-6px);
      @include fontSansBold;
      em {
        @include fontSansBold;
      }
    }
    .follow-button-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      text-align: left;
      margin-top: 0;
      margin-bottom: 12px;
      button {
        flex: 0 0 auto;
        width: auto;
        text-align: center;
        margin: 0;
        background-color: white;
        font-size: 12px;
        line-height: 12px;
        height: 20px;
        padding: 3px 16px 5px 16px;
        &:hover {
          background-color: white;
        }
        &.set {
          background-color: var(--highlight-color);
          &:hover {
            background-color: var(--highlight-color);
          }
        }
      }
    }
  }
}

.layout-overlay:not(.overlay-sidebar) .layout-requestinvite .toolbar {
  border-bottom: 0;
}
