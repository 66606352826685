@import "./../../../../scss/colors.scss";
@import "./../../../../scss/typography.scss";
@import "./../../../../scss/responsive.scss";
@import "./../../../../scss/mixins/linkpseudos.scss";

.post-mediagallery {
  margin-top: 30px;

  .thumbnails {
    background-color: $lighter-grey;
    // display: flex;
    display: none;
    align-items: flex-start;
    flex-wrap: wrap;
    position: relative;

    &:after {
      content: " ";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 3px;
      background-color: $card-white;
    }

    .thumbnail {
      flex: 0 0 76px;

      border-right: 4px solid $card-white;
      border-bottom: 3px solid $card-white;
      display: inline-block;
      cursor: pointer;

      &.active {
        position: relative;

        &:before {
          content: " ";
          position: absolute;
          top: -8px;
          left: 0;
          width: 100%;
          height: 4px;
          background-color: $lighter-grey;
        }
      }
    }
  }

  .content {
    max-width: 100%;
    overflow: hidden;

    .embed {
      text-align: center;

      a {
        text-decoration: none;
        cursor: pointer;
        @include linkpseudos {
          text-decoration: none;
        }
        &:hover {
          text-decoration: underline;
        }
      }
      h4,
      p {
        // display: none;
        text-align: left;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 12px;
      }
      p.link {
        margin-bottom: 0;
      }

      iframe {
        width: 100%;
      }
    }
  }

  .caption {
    display: block;
    @include fontSansRegular;
    margin: 0;
    padding: 10px 0 14px 0;
    word-wrap: break-word;
    line-height: 1.6em;
    text-align: left;
    font-size: 14px;

    &:empty {
      display: none;
    }
  }
}

// @media only screen and (max-width: $mobile-width) {
//     .post-mediagallery {
//
//         .caption {
// padding: 10px 14px 0 14px;
// font-size: 12px;
//         }
//     }
// }
