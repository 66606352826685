@import './../../../../scss/colors.scss';
@import './../../../../scss/typography.scss';

.layout-overlay.overlay-sidebar .overlay .sidebar { // needed to make rules specific
    &.user-notification-settings {

    	.user-notification-settings-toolbar {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            flex: 0 0 auto;
            height: auto;

            &:before {
                content: "";
                display: none;
            }

            .row {
                flex: 1 0 66px;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                @include fontSansBold;
                box-sizing: border-box;
                line-height: 29px;
                font-size: 20px;
            }
            .cancel {
                margin-left: auto;
            }

        }

        .controls {

            h2 {
                @include fontSansBold;
                font-size: 40px;
                line-height: 47px;
                color: var(--highlight-color);
                margin: 15px 0 10px 0;
            }

            p {
                @include fontSansRegular;
            }
            p.long {
                height: auto;
                white-space: normal;
                line-height: 30px;
                padding: 0 0 15px 0;
            }

            .notification-option {

                display: flex;
                flex-direction: row;
                justify-content: left;
                align-items: center;

                .image, .icon, .tag-avatar {
                    flex: 0 0 30px;
                    height: 30px;
                    width: 30px;
                    border-radius: 50%;
                    margin-right: 15px;
                }

                .tag-avatar {
                    box-sizing: border-box;
        			border-radius: 850%;
    			    text-align: center;
                    line-height: 30px;
                    color: white;
                    text-transform: capitalize;
                }

                span {
                    flex: 1 0 auto;
                    text-transform: capitalize;
                }

                // .tag-avatar + span {
                //     &:before {
                //         content: "#";
                //     }
                // }

                .icon.tick {
                    flex: 0 0 22px;
                    margin-left: auto;
                    height: 22px;
                    width: 22px;

                    &.hide {
                        display: none;
                    }
                }
            }

        }


    }
}
