@import "./../../../../scss/colors.scss";
@import "./../../../../scss/typography.scss";
@import "./../../../../scss/mixins/linkpseudos.scss";

.layout-overlay.overlay-sidebar .overlay .sidebar {
  // needed to make rules specific
  &.user-manage-subfeeds {
    .line {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      @include fontSansRegular;

      & > * {
        flex: 0 0 auto;
      }

      &.disabled {
        cursor: default;
      }

      .save-button,
      .remove-button,
      .plus-button,
      .tick-button {
        height: 24px;
        width: 24px;
        margin: 4px;
      }

      .remove-button {
        cursor: pointer;
      }

      .edit-button {
        cursor: pointer;
        margin: 5px 5px 5px 0;
        border-right: 3px solid $lighter-grey;
        box-sizing: content-box;
        line-height: 1;
        padding-right: 5px;
      }

      .handle {
        flex: 0 0 auto;
        height: 32px;
        width: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .icon {
        flex: 0 0 auto;
        color: var(--highlight-color);
      }
      .icon-remove {
        cursor: pointer;
      }
      .button.add {
        cursor: pointer;
        background: var(--highlight-color);
        border-radius: 12px;
        color: white;
        margin: 0;
        padding: 6px 8px;
        @include fontSansSemiBold;
        font-size: 12px;
        line-height: 12px;
      }

      .icon:first-child {
        color: $lighter-grey;
      }

      .icon-plus {
        color: var(--highlight-color);
      }

      .icon-handle {
        cursor: ns-resize;
      }

      .feedName {
        @include fontSansBold;
        flex: 1 0 auto;
        margin-left: 10px;
      }

      [contenteditable],
      input {
        flex: 1 0 auto;
        color: $text-black;
        @include fontSansRegular;
        font-size: 17px;
      }

      .newFeed {
        flex: 1 1 100%;
        margin-left: 10px;

        &:after {
          display: none;
        }

        [contenteditable] {
          color: var(--highlight-color);
          background-color: white;
          @include fontSansRegular;
          font-size: 17px;
          line-height: 24px;
          height: 34px;
          padding-top: 5px;
          padding-bottom: 5px;
          width: 100%;
          box-sizing: border-box;
          &::placeholder {
            // color: var(--highlight-color);
            color: $light-grey;
            @include fontSansRegular;
            font-size: 15px;
          }
        }
      }

      a {
        text-decoration: none;
        @include linkpseudos {
          text-decoration: none;
        }
      }
    }

    label {
      display: none;
    }

    [contenteditable],
    input {
      max-width: 300px;
      margin: 0;
      height: 50px;
      line-height: 50px;
      border-radius: 0;
      border: none;
      font-size: 1.4rem;
      box-shadow: none;
      &:focus {
        box-shadow: none;
      }
      &::placeholder {
        color: $form-grey;
      }
    }
  }
}
