@import './../../../../scss/colors.scss';
@import './../../../../scss/typography.scss';
@import './../../../../scss/mixins/linkpseudos.scss';

.layout-overlay.overlay-sidebar .overlay {

    .sidebar.post-menu-container {

        .toolbar.post-menu-toolbar {

            flex: 0 0 auto;
            height: auto;
            display: block;

            .row {
                height: 66px;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                text-align: left;

                &.title {
                    height: auto;
                    margin: 0 15px 15px 15px;
                    @include fontSansSemiBold;
                    font-size: 16px;
                    line-height: 24px;
                }
            }

        } // .toolbar.post-menu-toolbar

        .controls {

            .addthis_toolbox {
                margin: 0;
                flex: 1 1 auto;
                flex-direction: column;
                flex-wrap: nowrap;
                white-space: nowrap;
                justify-content: flex-start;
                align-items: flex-start;

                .custom_images a,
                .custom_images .native-share {
                    cursor: pointer;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    @include fontSansBold;
                    height: 60px;
                    line-height: 60px;
                    font-size: 17px;
                    border-bottom: 3px solid $lighter-grey;
                    @include linkpseudos {
                        text-decoration: none;
                    }
                }

                .native-share {
                    display: flex;
                    color: var(--highlight-color);
                    .icon {
                        color: var(--highlight-color);
                    }
                }
                .addthis_button_twitter .icon {
                    color: #00aced;
                }
                .addthis_button_facebook .icon {
                    color: #3b5998;
                }
                .addthis_button_more .icon {
                    color: var(--highlight-color);
                }
            } // .addthis_toolbox

            .line {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                @include fontSansBold;
                height: 60px;
                line-height: 60px;
                font-size: 17px;
                border-bottom: none;
                cursor: pointer;
            } // .line

            .icon {
                flex: 0 0 auto;
                color: $light-grey;
                cursor: pointer;
            }
            label {
                flex: 1 1 auto;
                color: $text-black;
                @include fontSansSemiBold;
                margin-left: 15px;
                height: 60px;
                line-height: 60px;
                font-size: 17px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-decoration: none;
                cursor: pointer;
            }

        } // .controls
    } // .sidebar.post-menu-container
} // .layout-overlay.overlay-sidebar .overlay
