@import './../../../../scss/colors.scss';
@import './../../../../scss/typography.scss';

.layout-overlay.overlay-sidebar .overlay .sidebar { // needed to make rules specific
    &.repost-menu-container {

    	.repost-menu-toolbar {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            flex: 0 0 auto;
            height: auto;

            .row {
                flex: 1 0 66px;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
            }
            .row + .row {
                flex: 1 0 auto;
            }
            .row.title {
                @include fontSansBold;
                box-sizing: border-box;
                line-height: 29px;
                font-size: 20px;
                padding: 0 15px 15px 8px;
            }
            .cancel {
                margin-left: auto;
            }

        }

        .controls {

            .repostComment {

                border-bottom: 1px solid $lighter-grey;

                & > div {
                    display: block;
                    @include fontSansBold;
                    color: $text-black;
                    padding: 18px 30px 18px 0;
                    line-height: 24px;
                    font-size: 17px;
                }

                &:after {
                    font-size: 10px;
                    line-height: 24px;
                }

            }
            .channel {

                border-bottom: 1px solid $lighter-grey;

                .channel-title {
                    display: block;
                    @include fontSansBold;
                    color: $text-black;
                    height: 60px;
                    line-height: 60px;
                    font-size: 17px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    cursor: pointer;
                    background-image: url('/images/down.png');
                    background-size: 21px 60px;
                    background-position: 100% 0px;
                    background-repeat: no-repeat;
                    padding-right: 30px;
                    user-select: none;
                }

                .channel-subfeeds {
                    display: none;
                }

                &.open {
                    .channel-title {
                        background-image: url('/images/up.png');
                        border-bottom: 1px solid $lighter-grey;
                    }

                    .channel-subfeeds {
                        display: block;
                    }
                }
            }
        }


    }
}
