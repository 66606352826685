@import './../../../../scss/colors.scss';
@import './../../../../scss/typography.scss';
@import './../../../../scss/mixins/progress.scss';
@import './../../../../scss/responsive.scss';

.layout-overlay:not(.overlay-sidebar).Tremr--Theme--Pitchpage {
  a.close {
    color: white;
    background-color: transparent;
  }
  .layout-pitchpage {
    .toolbar {
      background: transparent;
      border-bottom: none;
      button.signin {
        background: transparent;
        color: white;
        &:hover {
          background: transparent;
          color: white;
        }
      }
      button.request-invite-toolbar {
        background-color: white;
        color: $text-black;
      }
    }
    .content > img,
    .content > img.wide {
      width: 400px;
      max-width: calc(100% - 100px);
    }
  }
}

.layout-pitchpage {
    min-height: 100%;
    background-color: white;

    .toolbar {
      position: fixed;
      top: 0;
      left: 0;
      width: calc(100% - 15px);
      max-width: calc(100% - 15px);
      height: 60px;
      z-index: 30;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding-right: 15px;
      background: transparent;
      border-bottom: none;
      box-sizing: border-box;

        & > button {
            user-select: none;
            background-color: transparent;
            color: $text-black;
            background-color: white;
            border: none;
            border-radius: 6px;
            height: 36px;
            line-height: 36px;
            padding: 0 25px;
            @include fontSansBold;
            font-size: 12px;
            text-transform: uppercase;
            user-select: none;

            &.loading {
                  @include progress;
                  cursor: normal;
        		}

            &.signin {
              background: none;
              color: white;
              padding: 0;
              text-transform: none;
              font-size: 14px;
            }
        }

        .close {
          margin-left: 15px;
          margin-right: auto;
          flex: 0 0 30px;
      		height: 30px;
      		width: 30px;
      		box-sizing: content-box;
      		color: white;
      		z-index: 888;
      		text-align: center;
      		cursor: pointer;
      		background-color: transparent;
      		border: 2px solid $lighter-grey;
      		border-radius: 50%;
      		.icon {
      			height: 30px;
      			width: 30px;
      			.svgWrapper {
      				top: 20%;
      				left: 20%;
      				height: 60%;
      				width: 60%;
      			}
      		}
      	}
    } // .toolbar

    .section {
      max-width: 100%;
    }

    .section-1 {
      background-image: url('/images/welcome.svg');
      background-position: center -41px;
      background-size: auto 391px;
      background-repeat: no-repeat;
      padding-top: 100px !important;
      background-color: $dark-green;

      .pitch-page-logo-symbol {
        width: 160px !important;
        height: auto !important;
        margin-bottom: 10px;
      }

      .welcome-big-copy {
        font-size: 30px;
        line-height: 40px;
        margin-top: 50px;
        margin-bottom: 25px;
        text-align: center;
      }

      .welcome-small-copy {
        font-size: 21px;
        line-height: 30px;
        margin-top: 0px;
        margin-bottom: 50px;
        text-align: center;
        color: $light-green;
      }

    }

    .section-2 {
      background-color: $light-blue;
      padding-top: 40px;
    }

    .section-3 {
      background-color: #5B86D4;
      padding-top: 40px;
      .content h2 {

      }
      .content > img {
        margin-bottom: 0px;
      }
    }

    .section-4 {
      background-color: #588D4E;
      padding-top: 40px;
      .content h2 {

      }
      .content > img {
        margin-bottom: 0px;
      }
    }

    .section-5 {
      background-color: #52A1DA;
      padding-top: 20px;
      .content h2 {

      }
      .content > img {
        margin-bottom: 0px;
        width: 100% !important;
        max-width: 720px !important;
      }
    }

    .section-6 {
      background-color: $salmon-pink;
      padding-top: 40px;
      .content h2 {

      }
      .content > img {
        margin-bottom: 50px;
      }
    }

    .section-7 {
      background-color: $dark-green;
      padding-top: 40px;

      #tremr-logo {
          margin-bottom: 20px;
      }

      .pitch-page-logo-symbol {
        width: 84px !important;
        height: auto !important;
        margin-bottom: 5px;
      }

      .content {
          & > p {
              color: #FFFFFF;
              @include fontSansRegular;
              strong {
                  @include fontSansBold;
              }
          }
          .request-invite-bottom {
            margin-top: 20px;
            margin-bottom: 20px;
          }
          .link {
              @include fontSansRegular;
              font-size: 18px;
              color: $light-green;
          }
      }
    }

    .content {
        padding-top: 0 !important;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 0;

         text-align: center;

        & > * {
    		max-width: calc(100% - 40px);
            width: calc(100% - 40px);
    		margin-left: 20px;
    		margin-right: 20px;
    	}
        & > .field,
        & > p,
        & > h2,
        & > ul,
        & > h3,
        & > h4,
        & > img,
        & > .option-list {
            max-width: calc(100% - 40px);
            width: 720px;
            margin: 0 0 1em 0;
        }

        & > img, & > img.wide {
          width: 400px;
          margin: 20px 0 0 0;
          max-width: calc(100% - 100px);
        }

        .welcome-chevron {
            max-width: 20px;
        }

        .center {
          text-align: center;
        }

        a.request-invite, a.request-invite-bottom {
            background-color: $light-green;
            color: $dark-green;
            height: 36px;
            line-height: 36px;
            text-decoration: none;
            @include fontSansBold;
            font-size: 12px;
            padding: 0 30px;
            border-radius: 6px;
            text-transform: uppercase;
            margin: 0;
        }

        & > h2 {
            @include fontSansBold;
            font-size: 40px;
            line-height: 40px;
            color: $light-green;
            margin: 15px 0 20px 0;
            text-align: left;
        }

        & > h3 {
            @include fontSansBold;
            font-size: 30px;
            line-height: 38px;
            color: $light-green;
            text-align: center;
            margin: 30px 0 30px 0;
        }

        & > p {
            @include fontSansRegular;
            text-align: left;
            font-size: 21px;
            line-height: 30px;
            color: white;

            strong {
              @include fontSansBold;
            }

            a {
              color: white;
              text-decoration: none;
            }
        }





    }

}

// above mobile we change the header
@media only screen and (min-width: $mobile-width) {
  .layout-pitchpage {
    .section-1 {
      background-position: center -120px;
      background-size: auto 720px;
      padding-top: 400px;
      .content {
          #tremr-logo {
              height: 80px;
          }
      }
    }
  }
}

// nothing changing for medium width
// @media only screen and (min-width: $medium-width) {
// }

// change the header again
@media only screen and (min-width: $enhanced-width) {
  .layout-pitchpage {
    .section-1 {
      background-position: center -120px;
      background-size: 100% auto;
      // background-size: auto 640px;
      // padding-top: 400px;
    }
  }
}
