@import "./../../../../scss/responsive.scss";
@import "./../../../../scss/colors.scss";

$desktop-content-width: 730px;

.layout-page {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  padding: 0;

  &.feed .primary {
    .post-list,
    .post-grid {
      .reputation-badge {
        display: none;
      }
    }
  }

  // &.has-overlay, &.has-editor {
  &.has-editor,
  &.has-modal-overlay {
    max-height: 100vh;
    max-height: calc(100vh);
    overflow: hidden;

    .primary {
      max-height: 100vh;
      max-height: calc(100vh - 208px); // 66 90 52
      overflow: hidden;
    }
  }

  .header {
    width: 100%;
    flex: 1 1 100%;
  }

  .primary {
    width: 100%;
    max-width: 100%;
    flex: 1 1 100%;

    & > .generic-loadmorecontainer {
      & > .wall {
        padding-top: 3px;
        display: block;

        & > .list {
          max-width: 100%;
          width: 100%;
          flex: 1 1 100%;
          position: relative;
          padding-bottom: 60px;
          &:empty {
            min-height: 80vh;
          }
        }

        & > .grid {
          max-width: 100%;
          width: 100%;
          flex: 1 1 100%;
          position: relative;
          &:empty {
            min-height: 80vh;
          }
        }
      } // & .wall

      & > .post-show {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        & > .article-container {
          padding: 0;
          box-sizing: border-box;
          max-width: 100%;
          width: 100%;
          flex: 1 1 100%;
          background-color: white;
        } // & > .article-container

        & > .post-show-responses {
          max-width: 100%;
          width: 100%;
          flex: 1 1 100%;
          background-color: $canvas-grey;
          padding-top: 3px;
          padding-bottom: 100px;
        } // & > .post-show-responses
      } // & > .post-show
    } // & .generic-loadmorecontainer
  }
}

@media only screen and (min-width: $mobile-width) {
  .layout-page {
    .primary {
      & > .generic-loadmorecontainer {
        & > .wall {
          padding-top: 30px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;

          &.grid {
            display: block;
          }

          & > .list {
            margin: 0 30px;
            max-width: $desktop-content-width;
            width: calc(100% - 60px);
            flex: 1 1 calc(100% - 60px);
          }

          & > .grid {
            // not flex
            margin: 0 auto;
            max-width: 1220px;
            width: calc(100% - 60px);
            // flex: 1 1 calc(100% - 60px);
          }
        } // & .wall

        & > .post-show {
          & > .article-container {
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;

            & > * {
              margin-left: 20px;
              margin-right: 20px;
              max-width: $desktop-content-width;
              width: calc(100% - 40px);
              flex: 1 1 100%;

              // &.post-breadcrumbs {
              //   max-width: calc($desktop-content-width + 50px);
              // }
            }
          } // & > .article-container

          & > .post-show-responses {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            padding-top: 40px;

            & > * {
              margin: 0 20px;
              max-width: $desktop-content-width;
              width: calc(100% - 40px);
              flex: 1 1 calc(100% - 40px);

              &.wall.grid {
                margin: 0 30px;
                max-width: 1220px;
                width: calc(100% - 60px);
              }
            }
          } // & > .post-show-responses
        } // & > .post-show
      } // & .generic-loadmorecontainer
    }
  }
}

@media only screen and (min-width: $medium-width) {
  .layout-page {
    .primary {
    }
  }
}
