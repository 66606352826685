@import './../../../../scss/colors.scss';
@import './../../../../scss/typography.scss';
@import './../../../../scss/mixins/linkpseudos.scss';

@media
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (   min--moz-device-pixel-ratio: 2),
only screen and (     -o-min-device-pixel-ratio: 2/1),
only screen and (        min-device-pixel-ratio: 2),
only screen and (                min-resolution: 192dpi),
only screen and (                min-resolution: 2dppx) {
    .post-responsesmenu {
        .menu {
            a.active:before {
                background: url('/images/arrow-retina.png');
            }
        }
    }
}

.post-responsesmenu {

	display: block;
    flex: 1 1 100%;
    width: 100%;
    max-width: 100%;
    background-color: white;

	.menu {
		display: flex;
        justify-content: center;
        height: 90px;
        width: 100%;
        max-width: 700px;
        margin: 0 auto;
        border-bottom: 3px solid $lighter-grey;

		a {
            flex: 0 0 100px;
			display: flex;
            flex-direction: column;
			padding: 10px 8px;
			@include fontSansBold;
			font-size: 12px;
            line-height: 18px;
			cursor: pointer;

            @include linkpseudos {
                text-decoration: none;
            }

			label {
                order: 2;
                flex: 0 0 auto;
				display: block;
				@include fontSansBold;
                font-size: 12px;
                line-height: 18px;
                color: $light-grey;
				text-align: center;
                text-transform: uppercase;
                cursor: pointer;
			}

			output {
                order: 1;
                flex: 0 0 auto;
				display: block;
                font-size: 36px;
                line-height: 36px;
                color: $light-grey;
				@include fontSansBold;
				text-align: center;
                cursor: pointer;
			}

            &.active {
                position: relative;

                label, output {
                    color: var(--highlight-color);
                }

                &:before {
                    content: " ";
                    position: absolute;
                    bottom: -3px;
                    left: 50%;
                    transform: translateX(-27.5px);
                    height: 10px;
                    width: 55px;
                    background: url('/images/arrow.png');
                    background-size: 55px 10px;
                    background-position: 0px 0px;
                    background-origin: border-box;
                    background-repeat: no-repeat;
                }
			}

		}
	} // .menu

    .layout-filter {
        display: flex;
        justify-content: center;
        margin: 0 60px 0 60px;

        a {
            flex: 0 0 auto;
            box-sizing: border-box;
            line-height: 22px;
            padding: 25px 15px 25px 15px;
            cursor: pointer;
            @include fontSansBold;
            color: $light-grey;
            font-size: 20px;
            text-transform: capitalize;

            @include linkpseudos {
                text-decoration: none;
            }

			&.active {
                position: relative;
                color: var(--highlight-color);
            }
        }
    } // .layout-filter



    // showing both levels
	&.level-0 {

	}

    // only showing top level
	&.level-1 {

	}

	// only showing sub level
	&.level-2 {

	}


}
