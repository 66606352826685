@import "./../../../../scss/colors.scss";
@import "./../../../../scss/typography.scss";
@import "./../../../../scss/mixins/linkpseudos.scss";
@import "./../../layout/scss/sidebar.scss";
@import "./../../../../scss/responsive.scss";
@import "./../../../../scss/mixins/progress.scss";

.post-menu {
  margin-left: 20px;
  box-sizing: border-box;

  .postmenu-toolbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .close {
      height: 28px;
      width: 28px;
      cursor: pointer;
      border-radius: 50%;
      border: 3px solid $lighter-grey;
      color: var(--highlight-color);
      margin: 0 auto 0 15px;

      .icon {
        height: 28px;
        width: 28px;
        & > .svgWrapper {
          top: 10%;
          left: 10%;
          height: 80%;
          width: 80%;
        }
      }
    }

    .save-button {
      background-color: var(--highlight-color);
      color: white;
      cursor: pointer;
      flex: 0 0 84px;
      height: 34px;
      line-height: 20px;
      width: 84px;
      padding: 6px 0 8px 0;
      text-align: center;
      box-sizing: border-box;
      border-radius: 17px 0 0 17px;
      @include fontSansBold;
      font-size: 17px;
    }
    .toggle-button {
      background-color: var(--highlight-color);
      color: white;
      cursor: pointer;
      flex: 0 0 34px;
      margin-left: 2px;
      height: 34px;
      width: 34px;
      text-align: center;
      box-sizing: border-box;
      border-radius: 0 17px 17px 0;
      padding: 5px;
      .icon {
        height: 24px;
        width: 24px;
      }
    }
  }

  &.loading {
    .postmenu-toolbar {
      .save-button {
        @include progress;
      }
    }
  }

  &.open {
    @include sidebar;

    .overlay {
      .sidebar {
        .toolbar {
          &:after {
            content: " ";
            position: absolute;
            bottom: -3px;
            right: 4px;
            height: 10px;
            width: 55px;
            background: url("/images/arrow.png");
            background-size: 55px 10px;
            background-position: 0px 0px;
            background-origin: border-box;
            background-repeat: no-repeat;
          }
        }
        .controls {
          .summary-line {
            height: auto;
            min-height: 60px;
            .summary {
              @include fontSansBold;
              padding: 15px 0;
              line-height: 30px;
            } // .summary
          } // .summary-line

          .line {
            .draft,
            .discard {
              cursor: pointer;
            }
            .discard {
              border-bottom: 3px solid $lighter-grey;
            }
          }

          .draft {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 15px;
          }

          .post-subfeeds,
          .post-doorpolicysettings {
            option-list {
              display: block;
              margin: 0;
              padding: 0;
            }
            h2 {
              display: block;
              @include fontSansBold;
              color: var(--highlight-color);
              height: 60px;
              line-height: 60px;
              font-size: 17px;
              border-bottom: 1px solid $lighter-grey;
              margin: 0;
            }
          }
        } //.controls
      } // .sidebar
    } // .overlay
  } // .open
}

@media only screen and (min-width: $mobile-width) {
  .post-menu {
    .postmenu-toolbar {
      .close {
        display: none;
      }
      .save-button {
        width: 104px;
        flex: 0 0 104px;
      }
    }
  }
}
