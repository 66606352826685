@import './../../../../scss/colors.scss';
@import './../../../../scss/typography.scss';
@import './../../../../scss/mixins/linkpseudos.scss';
@import './../../../../scss/responsive.scss';

.post-subfeeds {

	position: relative;

	.option-list {
		padding: 0;
		margin: 0;
	}

	.option-item {
		display: flex;
		align-items: center;
        list-style: none;
		margin-left: 0;
        @include fontSansRegular;
        color: $text-black;
        height: 60px;
        line-height: 60px;
        font-size: 17px;
        border-bottom: 1px solid $lighter-grey;

		.icon {
			flex: 0 0 auto;
			cursor: pointer;
            height: 16px;
            width: 16px;
            margin-left: 5px;
            color: var(--highlight-color);
		}

		.feedName {
			text-transform: capitalize;
		}

		.feedName, .newFeed {
			flex: 1 0 auto;
			text-transform: capitalize;
		}
        .newFeed {
            margin-left: 15px;
            input::placeholder {
                color: $text-grey;
            }
        }
        .newFeed + .icon {
            height: 32px;
            width: 32px;
            color: $light-grey;
        }

		a {
			flex: 0 0 auto;
			cursor: pointer;
		}
        .marker {
            float: none;
            flex: 0 0 auto;
            line-height: 60px;
        }
        .label {
            float: none;
            flex: 1 0 auto;
            line-height: 60px;
            height: 60px;
        }
	}

	label {
		display: none;
	}

	input {
		max-width: 300px;
		margin: 0;
		border-radius: 0;
		border: none;
		box-shadow: none;
        @include fontSansRegular;
        color: $text-black;
        height: 60px;
        line-height: 60px;
        font-size: 17px;
        border-bottom: 1px solid $lighter-grey;

		&:focus {
			box-shadow: none;
		}
		&::placeholder {
			color: $form-grey;
		}
	}

}
