@import './../../../../scss/colors.scss';
@import './../../../../scss/typography.scss';


.embed-prompt {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	overflow: hidden;
	min-width: 255px;
	max-width: 500px;

	div {
		display: inline-block;
		height: 44px;
		line-height: 44px;
	}
	.contenteditable {
		flex-grow: 4;
		overflow: hidden;
		margin: 0;
		padding: 0;
		max-width: 390px;

		&:after {
			display: none;
		}
		[contenteditable] {
			margin: 0;
			padding: 0 10px;
            user-select: text;
            width: 100%;
            min-width: 100%;
		}
	}
	.start, .cancel {
		flex-grow: 0;
		flex-shrink: 1;
		margin: 0;
		width: 53px;
		border: 0;
		border-left: 1px solid $canvas-grey;
		border-radius: 0;
		background-color: white;
		color: $text-grey;
		vertical-align: top;
		text-align: center;
		cursor: pointer;

		&:hover {
			background-color: $lighter-grey;
		}
		.icon {
			display: inline-block;
			width: 53px;
			height: 42px;

			.svgWrapper {
				top: 20%;
				left: 20%;
				width: 60%;
				height: 60%;
			}
		}
		&:focus {

		}
	}
}
