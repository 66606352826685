@import './../../../../scss/colors.scss';
@import './../../../../scss/typography.scss';
@import './../../../../scss/mixins/linkpseudos.scss';
@import './../../../../scss/responsive.scss';

.layout-overlay.Tremr--Feed--Edit {
  background-color: white;
}

.layout-overlay.Tremr--Feed--Edit:not(.overlay-sidebar) a.close {
  position: absolute;
  color: var(--highlight-color);
}

.feed-edit {

    max-width: 768px;
    margin: 0 auto;
    padding-bottom: 30px;
    background-color: white;
    min-height: 100vw;

    h2 {
        @include fontSansSemiBold;
        font-size: 26px;
        line-height: 40px;
        color: #5F5C6A;
        margin: 60px 0 20px 10px;
        user-select: none;
    }

    h3 {
      @include fontSansSemiBold;
    }

    .feed-edit-menu {
        position: absolute;
        top: 15px;
        right: 20px;
    }

    .tabs {
        display: flex;
        flex: 0 0 auto;
        margin-left: calc(16px + calc(calc(100vw - 768px) / -2));
        margin-right: calc(16px + calc(calc(100vw - 768px) / -2));
        padding-left: calc(4px + calc(calc(100vw - 768px) / 2));
        padding-right: calc(4px + calc(calc(100vw - 768px) / 2));
        align-self: flex-end;
        border-bottom: 2px solid $lighter-grey;
        color: $placeholder-grey;

        & > * {
            @include fontSansSemiBold;
            flex: 0 0 auto;
            border-radius: 6px 6px 0 0;
            box-sizing: border-box;
            cursor: pointer;
            transform: translateY(2px);
            padding: 15px 24px 15px 24px;
            border-bottom: 2px solid $lighter-grey;
            user-select: none;
            color: $placeholder-grey;
            text-transform: uppercase;
            &.active {
                padding: 13px 15px 15px 15px;
                border: 2px solid $lighter-grey;
                border-bottom: 2px solid white;
                color: $placeholder-grey;
            }
        }
    } //.tabs

    .fields {

      padding-top: 10px;
      margin: 0 15px;

      .field {

        label {
          color: var(--highlight-color);
        }

        .info {
          position: relative;
          .meta {
              position: absolute;
              bottom: -20px;
              right: 0;
              color: $placeholder-grey;
          }
        }

      }

        .channel-icon {
            border-bottom: 2px solid $lighter-grey;
            margin-bottom: 15px;

            .row {
                display: flex;

                p {
                    flex: 1 1 auto;
                }

                .image-upload {
                    flex: 0 0 120px;
                    margin-left: 30px;

                    .image {
                        min-width: 120px !important; // must force the height because of auto calculating in js
                        min-height: 120px !important;
                        width: 120px !important;
                        height: 120px !important;
                    }

                    .remove-image {
                        top: 2px;
                        right: 2px;
                    }
                }
            }
        } // .channel-icon

        .channel-background {

            .image-upload {

                .image {
                    min-height: 138px !important;
                    height: 138px !important;
                }
            }

        } // .channel-background.fields {

        .field.newsletter-section {

          p {
            font-size: 18px;
            color: $light-grey;
            line-height: 22px;
            font-style: normal;
            em {
              color: var(--highlight-color);
              font-style: italic;
            }
          }

          .option-list {
            .option-item {

              .label {
                font-size: 18px;
                line-height: 25px;
              }
            }
          }

          .field.newsletter {
            margin: 20px 0;
          }
          .field.newsletterPromo {
            margin-top: 20px;
          }

        }
    } // .fields
} //.feed-edit

// specific style to deal with the calculations we use for position tabs
// that only work at 768px or wider
@media only screen and (max-width: 768px) {

    .feed-edit {

        .tabs {
            margin-left: 0;
            margin-right: 0;
            padding-left: 10px;
        }
    }
}

// responsive - above mobile
// @media only screen and (min-width: $mobile-width) {
@media only screen and (min-width: 795px) {

  .feed-edit {

    h2 {
      font-size: 46px;
      line-height: 70px;
    }

    .fields {
      margin: 0;

      .channel-background {

          .image-upload {

              .image {
                  min-height: 300px !important;
                  height: 300px !important;
              }
          }

      } // .channel-background.fields
    }
  }
}
