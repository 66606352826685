@import './../../../../scss/colors.scss';
@import './../../../../scss/typography.scss';
@import './../../../../scss/mixins/linkpseudos.scss';
@import './../../../../scss/responsive.scss';

$post-show-font-size: 15px;
$post-show-line-height: 22px;

$post-show-font-size-medium: 17px;
$post-show-line-height-medium: 27px;

.tag-list {
   white-space: nowrap;
   margin: 12px 0 6px 0;
   display: flex;
   flex-wrap: wrap;

   a {
      flex: 0 0 auto;
      display: inline-block;
      @include fontSansBold;
      line-height: calc($post-show-line-height + 1px);
      font-size: calc($post-show-font-size + 1px);
      background-color: white;
      color: var(--highlight-color);
      text-transform: capitalize;
      text-decoration: none;
      margin-right: 5px;

      &:before {
          content: "#";
      }
      &:after {
          content: ",";
      }

      @include linkpseudos {
         text-decoration: none;
      }

      &:last-child {
          margin-right: 0;
      }
      &:last-child:after {
          content: "";
      }
   }
}

// @media only screen and (min-width: $mobile-width) {
//
//
// }
