@import './../../../../scss/colors.scss';
@import './../../../../scss/typography.scss';

.activity-icon-button {

    display: flex;
    background-color: white;
    color: $text-black;
    fill: $text-black;
    justify-content: center;
    align-items: center;

    .button {
        padding: 0;
    }

    &:hover {
        background-color: white;
        color: $text-black;
        fill: $text-black;
        .icon {
            background-color: white;
            color: $text-black;
            fill: $text-black;
        }
    }
    .icon {
        background-color: white;
        color: $text-black;
        fill: $text-black;
    }
    &.set {
        .icon {
            color: var(--highlight-color);
            fill: var(--highlight-color);
        }
    }

}
