@mixin linkpseudos {

	& {
		@content;
	}

    &:visited {
	  @content;
	}
	&:focus {
	  @content;
	}
	&:hover {
	  @content;
	}
	&:active {
	  @content;
	}
}
