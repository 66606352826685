@import './../../../../scss/colors.scss';
@import './../../../../scss/typography.scss';

.post-contentsummary {

	output {
		display: inline;

		&:before {
			content: ", ";
		}
		&:first-child:before {
			content: "";
		}
	}
}