@import './../../../../scss/colors.scss';
@import './../../../../scss/typography.scss';
@import './../../../../scss/mixins/linkpseudos.scss';
@import './../../../../scss/responsive.scss';

.header.tag {

	display: block;
	background: white;
    z-index: 1001;
    position: relative;
    overflow: hidden;

    .generic-horizontalscroller .scroll-content {
        padding: 0 12px;
    }
    .generic-horizontalscroller.content-fits .scroll-content {
        padding: 0 12px;
    }

    &.homeui {
        height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;

        a.tagName {
			flex: 0 0 auto;
            line-height: 38px;
    		margin: 5px 10px;;
    		font-size: 16px;
    		padding: 4px 12px 6px 0;
    		@include fontSansBold;
    		white-space: nowrap;
    		text-decoration: none;
    		color: $text-grey;
            cursor: pointer;
            @include linkpseudos {
                text-decoration: none;
                color: $text-grey;
            }
    		&:after {
                content: ":"
            }

            &:before {
                content: "#";
            }
        }

		.tag-posts-subfeeds-summary {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
        }

        .menu {
            flex: 1 1 auto;
    		display: flex;
    		align-items: flex-start;
    		justify-content: flex-start;
    		margin: 0;
            line-height: 22px;
            font-size: 14px;

            a {
                flex: 0 0 auto;
                display: block;
                @include fontSansBold;
                line-height: 20px;
        		margin: 5px 10px;
                font-size: 16px;
        		padding: 4px 12px 6px 12px;
        		@include fontSansBold;
        		white-space: nowrap;
        		text-decoration: none;
        		color: $text-grey;
                cursor: pointer;

                @include linkpseudos {
                    text-decoration: none;
                    color: $text-grey;
                }

                &.active {
                    color: var(--highlight-color);
        			border-radius: 4px;
        			background-color: $lighter-grey;
                    @include linkpseudos {
                        color: var(--highlight-color);
                    }
                    & > output, & > label {
                        color: var(--highlight-color);
                    }
                }

                &:first-child {
                    margin-left: 0;
                }
                &:last-child {
                    margin-right: 0;
                }

                & > * {
                    @include fontSansBold;
                    line-height: 20px;
            		font-size: 16px;
            		display: inline;
                    color: $text-grey;
                    cursor: pointer;
                }

                & > output {
                    @include fontSansBold;
                    line-height: 20px;
            		font-size: 16px;
            		color: $text-grey;

                    &:before {
                        content: " (";
                    }
                    &:after {
                        content: ")";
                    }
                }
    		} // a
    	} // .menu
	}

    &:not(.homeui) {

        .content {
    		margin: 20px 0 0 0;
    		padding: 20px 0 0 0;
    		display: flex;
    		@include fontSansRegular;
    		line-height: 1.6em;
    		align-items: flex-start;
    		flex-wrap: wrap;
            flex-direction: column;
            align-items: center;

    		.info {
    			flex: 1 0 30%;
    			margin: 0 20px 20px 20px;
    			font-size: 1.6rem;
    			line-height: 1.6em;
                text-align: center;
                max-width: calc(100% - 40px);

    			h1 {
    				margin: 0;
    				@include fontSansSemiBold;
    				font-size: 36px;
    				line-height: 42px;
            text-align: center;
            color: var(--highlight-color);
						text-transform: capitalize;
            &:before {
                content: "#";

            }
    			}
    		}

            .actions-tabs-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                max-width: 100%;
                flex: 1 0 100%;
                width: 100%;
                border-bottom: 3px solid $lighter-grey;
                margin: 0 auto 10px auto;
            }

    		.actions {
    			flex: 0 0 auto;
                margin: 0 0 20px 0;
    			display: flex;
    			align-items: center;
    			justify-content: center;

    			& > * {
    				flex: 0 0 auto;
    				margin: 0 6px;
    			}

    			& > .follow-button-container > .follow-button,
                & > .join-button-container > .join-button {
                    height: 30px;
                    border-radius: 15px;
                    line-height: 22px;
                    font-size: 14px;
    			}

                .addthis_toolbox {
                    margin: 0;
                    position: relative;
                    flex: 0 1 auto;
                    display: flex;

                    .custom_images {
                        max-height: 30px;
                        overflow: hidden;
                    }

                    .addthis_button_more, .native-share {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 28px;
                        width: 28px;
                        border: 1px solid var(--highlight-color);
                        border-radius: 50%;
                        margin: 0;
                        padding: 0;
                    }

                    .icon {
                        max-height: 28px;
                        max-width: 28px;
                    }
                    .native-share {
                        color: var(--highlight-color);
                    }
                    .addthis_button_more {
                        color: var(--highlight-color);
                    }
                } // .addthis_toolbox
    		} // .actions
        } // .content

    	.tabs {
            flex: 1 1 auto;
    		display: flex;
    		align-items: flex-start;
    		justify-content: flex-start;
    		margin: 0;

    		a {
    			flex: 0 0 auto;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 4px 6px 20px 6px;
                text-decoration: none;
                @include fontSansBold;
                color: $text-grey;
                position: relative;
                cursor: pointer;

                @include linkpseudos {
                    text-decoration: none;
                    color: $text-grey;
                }

                &.active {
                    color: var(--highlight-color);

                    &:before {
                        content: " ";
                        position: absolute;
                        bottom: -3px;
                        left: 50%;
                        transform: translateX(-27.5px);
                        height: 10px;
                        width: 55px;
                        background: url('/images/arrow.png');
                        background-size: 55px 10px;
                        background-position: 0px 0px;
                        background-origin: border-box;
                        background-repeat: no-repeat;
                    }

                    @include linkpseudos {
                        color: var(--highlight-color);
                    }
                    label {
                        color: var(--highlight-color);
                    }
                } // &.active

                label {
                    flex: 1 1 auto;
                    display: block;
                    order: 2;
                    font-size: 12px;
                    line-height: 16px;
                    text-transform: uppercase;
                    text-align: center;
                    letter-spacing: -0.2px;
                    color: $text-grey;
                    @include fontSansBold;
                }
                output {
                    flex: 1 1 auto;
                    display: block;
                    order: 1;
                    font-size: 30px;
                    line-height: 36px;
                    text-align: center;
                }
    		} // a
    	} // .tabs

        .menu {
            flex: 1 1 auto;
    		display: flex;
    		align-items: flex-start;
    		justify-content: flex-start;
    		margin: 0;
            line-height: 22px;
            font-size: 14px;

            a {
                flex: 0 0 auto;
                display: block;
                @include fontSansBold;
                color: $text-grey;
                line-height: 22px;
                font-size: 18px;
                padding: 10px 5px 24px 5px;
                margin: 0 15px;
                cursor: pointer;

                @include linkpseudos {
                    text-decoration: none;
                    color: $text-grey;
                }

                &.active {
                    color: var(--highlight-color);
                    @include linkpseudos {
                        color: var(--highlight-color);
                    }
                    & > output, & > label {
                        color: var(--highlight-color);
                    }
                }

                &:first-child {
                    margin-left: 0;
                }
                &:last-child {
                    margin-right: 0;
                }

                & > * {
                    @include fontSansBold;
                    line-height: 22px;
                    font-size: 18px;
                    display: inline;
                    color: $text-grey;
                }

                & > output {
                    @include fontSansBold;
                    line-height: 22px;
                    font-size: 18px;
                    color: $text-grey;

                    &:before {
                        content: " (";
                    }
                    &:after {
                        content: ")";
                    }
                }
    		} // a
    	} // .menu

    } // &:not(.homeui)
}

@media only screen and (min-width: $mobile-width) {

    .header.tag {

        .generic-horizontalscroller {

            .scroll-content:first-child {
                padding-left: 30px;
            }
            .scroll-content:last-child {
                padding-right: 30px;
            }

        }

        &.homeui {
            height: 65px;
            line-height: 63px;

            a.tagName {
                line-height: 22px;
        		margin: 5px 10px;
        		font-size: 18px;
        		padding: 5px 12px 7px 0;
    		}

            .menu {
                font-size: 18px;
                line-height: 63px;

                a {
                    line-height: 22px;
                    font-size: 18px;
            		margin: 5px 10px;
            		padding: 5px 12px 7px 12px;

                    & > * {
                        line-height: 22px;
                        font-size: 18px;
                	}

                    & > output {
                        line-height: 22px;
                        font-size: 18px;
                    }
        		} // a
        	} // .menu

        	&:before, &:after {
                content: " ";
                position: absolute;
                height: 65px;
                width: 120px;
                top: 0;
                z-index: 2;
                left: calc(((100% - 1220px) / 2) - 120px);
                background: linear-gradient(90deg, white 0px, rgba(255, 255, 255, 0));
            }
            &:after {
                left: auto;
                background: linear-gradient(270deg, white 0px, rgba(255, 255, 255, 0));
                right: calc(((100% - 1220px) / 2) - 120px)
            }
        }

    	&:not(.homeui) {

            .generic-horizontalscroller {

                .scroll-content:first-child {
                    padding-left: 20px;
                }
                .scroll-content:last-child {
                    padding-right: 20px;
                }

            }

            .content {
                // margin-top: 10px;

                .info {
                    max-width: 1180px;
                    margin-bottom: 15px;

                    h1 {
                        font-size: 76px;
        				line-height: 80px;
                    }

                }

                .actions-tabs-container {
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    max-width: 1220px;
                    flex: 1 0 calc(100% - 40px);
                    width: calc(100% - 40px);
                    border-bottom: 3px solid $lighter-grey;
                    .tabs {
                        flex: 1 1 auto;
                        order: 1;
                        a output {
                            font-size: 36px;
                            line-height: 42px;
                            padding-left: 16px;
                            padding-right: 16px;
                        }
                    }
                    .actions {
                        flex: 0 1 auto;
                        order: 2;
                        margin-left: auto;
                        margin: 0;
                    }
                }
            } // .content


        } // &:not(.homeui)
    }
}

@media only screen and (min-width: 1280px) {

    .header.tag:not(.homeui) {

        .generic-horizontalscroller {

            .scroll-content:first-child {
                padding-left: 30px;
            }
            .scroll-content:last-child {
                padding-right: 30px;
            }

        }
    }
}
