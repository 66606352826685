@import "./../../../../scss/colors.scss";
@import "./../../../../scss/typography.scss";
@import "./../../../../scss/responsive.scss";

.responses-button-container {
  height: 28px;
  width: 28px;
  cursor: pointer;
  z-index: 1;
  position: relative;
  transform: translateY(11px);

  .responses-button {
    border-radius: 50%;
    background-color: white;
    @include fontSansBold;
    height: 28px;
    width: 28px;
    box-sizing: border-box;
    line-height: 28px;
    text-align: center;
    cursor: pointer;
    user-select: none;
    position: relative;
    box-shadow: 0 0 0 3px $canvas-grey;
    z-index: 2; // on-top of arrow
    font-size: 12px;

    span {
      display: block;
      max-width: 28px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: $text-black;
    }
  } // .responses-button

  .icon {
    position: absolute;
    top: auto;
    right: auto;
    bottom: -15px;
    left: 50%;
    transform: translateX(-11px);
    height: 22px;
    width: 22px;
    color: $canvas-grey;
    z-index: 1; // behind button shadow
    display: none;
  }
} // .responses-button-container

@media only screen and (min-width: $mobile-width) {
  .responses-button-container {
    transform: translateY(14px);
  }
}
