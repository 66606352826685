@import './../../../../scss/colors.scss';
@import './../../../../scss/typography.scss';
@import './../../../../scss/responsive.scss';

.activity-button {

	display: block;
	align-items: center;
	margin: 2px;
	padding: 0;
	position: relative;
    background-color: transparent;
    color: $text-black;
    flex: 0 0 auto;
    border: 1px solid $lighter-grey;
    border-radius: 6px;
    box-sizing: border-box;
    height: 30px;
    white-space: nowrap;
    min-width: 100px;
    overflow: hidden;

    .percent {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 0%;
		background-color: $lighter-grey;
		z-index: 1;
        transition: width ease 100ms;
	}

	.label {
        position: relative;
        display: block;
		height: 28px;
		z-index: 2;
        line-height: 16px;
        padding: 6px 0 6px 0;
        text-align: center;
        @include fontSansRegular;
        font-size: 10px;
        text-transform: uppercase;
    }

	&.set {
        color: var(--highlight-color);

		.label {
			color: var(--highlight-color);
		}
	}
}

@media only screen and (min-width: $mobile-width) {
    .activity-button {
        height: 36px;

    	.label {
            font-size: 12px;
            height: 24px;
            line-height: 24px;
        }
    }
}
