.layout-generic-alert {

  .layout-alert-content {

    p, & > div {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      height: 22px;
      line-height: 22px;
      padding-top: 4px;

      span {
        flex: 1 1 auto;
        height: 22px;
        line-height: 22px;
      }
      .addthis_toolbox {
        flex: 0 0 auto;
        margin-left: auto;
        height: 22px;
        line-height: 22px;
        margin-bottom: 4px;
        .addthis_button_twitter .icon {
            color: #00aced;
        }
        .addthis_button_facebook .icon {
            color: #3b5998;
        }
      }

      #social_link {
        flex: 0 0 auto;
        margin-left: auto;
        max-width: 30px;
        line-height: 30px;
        margin: 0;
        min-height: 30px !important;
        max-height: 30px !important;
        iframe {
          max-width: 30px;
          min-height: 30px;
          max-height: 30px;
        }
      }
    }
  }
}
