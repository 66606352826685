@import "./../../../../scss/colors.scss";
@import "./../../../../scss/typography.scss";
@import "./../../../../scss/responsive.scss";

@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  .reactions {
    &.recommend-level-2,
    &.recommend-level-2,
    &.recommend-level-3,
    &.recommend-level--1,
    &.recommend-level--2,
    &.recommend-level--3 {
      .reaction-buttons:before {
        background: url("/images/arrow-retina.png");
      }
    }
  }
}

.reactions {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;

  .reaction-summary {
    cursor: pointer;
    flex: 0 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    height: auto;
    min-height: 6px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 11px;
    @include fontSansItalic;
    color: var(--highlight-color);
    font-size: 9px;
    line-height: 20px;

    .activity {
      white-space: nowrap;
      text-transform: uppercase;
      &:before {
        content: ", ";
      }
      &:first-child:before {
        content: "";
      }
    }
  }

  &.open {
    margin-top: 12px;

    .totals {
      position: absolute;
      top: -10px;
      right: 32px;
      display: flex;
      flex-direction: row;
      // width: 171px;
      width: auto;
      justify-content: space-between;
      background-color: white;
      @include fontSansBold;
      font-size: 9px;
      line-height: 20px;
      height: 20px;

      .activity-count {
        flex: 0 1 auto;
        max-width: 32px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;

        &.downvote {
          max-width: 32px;
          width: 32px;
          margin-left: 2px;
          &:before {
            content: "-";
          }
        }

        &.value-0 {
          &:before {
            content: "";
          }
        }

        &.upvote {
          max-width: 32px;
          width: 32px;
        }
        &.repost {
          max-width: 32px;
          width: 32px;
          margin-left: 2px;
        }
        &.bookmark {
          max-width: 32px;
          width: 32px;
          margin-left: 2px;
        }
      }
    }
  }

  .reaction-buttons-wrapper {
    flex: 1 0 auto;
    display: block;
    margin: 0;
    border-top: 3px solid $lighter-grey;
    padding-top: 3px;
  }

  .reaction-buttons {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;
    margin: 10px -2px 0 -2px;
    width: calc(100% + 4px);
    max-width: calc(100% + 4px);

    & > * {
      flex: 1 0 auto;
    }

    & > .activity-button {
      min-width: 75px;
    }

    label {
      font-size: 10px;
      line-height: 33px;
    }
  }
}

@media only screen and (min-width: $mobile-width) {
  .reactions {
    .reaction-buttons {
      & > .activity-button {
        min-width: 100px;
      }
    }

    &.open {
      .totals {
        right: 36px;
        .activity-count {
          max-width: 36px;
          &.downvote {
            max-width: 36px;
            width: 36px;
            margin-left: 9px;
          }

          &.upvote {
            max-width: 36px;
            width: 36px;
          }
          &.repost {
            max-width: 36px;
            width: 36px;
            margin-left: 9px;
          }
          &.bookmark {
            max-width: 36px;
            width: 36px;
            margin-left: 9px;
          }
        }
      }
    }
  }
}
