@import "./../../../../scss/colors.scss";
@import "./../../../../scss/typography.scss";

.post-list-card-wrapper {
  position: relative;

  .expand {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}
