@import "./../../../../scss/colors.scss";
@import "./../../../../scss/typography.scss";
@import "./../../../../scss/mixins/linkpseudos.scss";
@import "./../../../../scss/responsive.scss";

.layout-titlebar {
  color: $dark-grey;
  background-color: white;
  position: static;
  // top: 0;
  // left: 0;
  // right: 0;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  z-index: 10100;
  box-sizing: border-box;
  display: flex;
  justify-content: center;

  &.light {
    color: white;
    background-color: transparent;
    .search-menu-button {
      color: white;
    }
  }

  &.stripes {
    color: white;
    background-color: var(--highlight-color);
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.1) 50%,
        rgba(255, 255, 255, 0) 50%
      )
      var(--highlight-color);
    background-size: 20px auto;
    .search-menu-button {
      color: white;
    }
    .layout-titlebar-content {
      background-color: transparent;
    }
  }

  .layout-titlebar-content {
    position: relative;
    background-color: white;
    max-width: 1220px;
    margin: 0;
    // border-bottom: 3px solid $lighter-grey;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1 1 100%;

    & > a {
      display: inline-block;
      height: 22px;
      padding: 22px 20px 22px 12px;
      // margin-right: 43px;
      margin-right: auto;
      flex: 0 0 auto;

      svg {
        height: 22px;
        width: 88px;
      }

      &.logotr {
        padding: 17px 10px 17px 12px;
        height: 32px;
        margin-right: 0;

				svg {
					height: 32px;
					width: 32px;
				}
			}			
		}

		& > a.back {
			color: white;
			padding: 0 12px;
			margin: 0;
			height: 34px;
			.icon {
				height: 34px;
				width: 34px;
			}
			svg {
				height: 34px;
				width: 34px;
			}
		}

      .icon .icon-back svg {
        height: 32px;
        width: 32px;
        color: #fefefe;
        fill: #fefefe;
      }
    }

    .button {
      background-color: transparent;
      margin: 0 0 0 auto;
      padding: 0 22px 0 0;
      height: 28px;
      width: 28px;
      border: none;

      .icon,
      .icon-admin-config {
        height: 28px;
        width: 28px;
      }
    }

    .button + .button {
      margin-left: 0;
    }

    .button + .icon {
      margin-left: 0;
    }

    nav.search-menu + .user-profilemenu {
      margin-left: 0;
    }

    .search-menu-button,
    .search-menu-container {
      flex: 0 0 auto;
      margin-left: auto;
      border: none;
      margin-right: 0;

      & + .user-profilemenu,
      & + .user-signinupmenu {
        margin-left: 0;
      }
    }

    .search-menu-button {
      padding-right: 22px;
    }

    .user-signinupmenu {
      padding-right: 22px;
    }

    .user-profilemenu,
    .user-signinupmenu {
      cursor: pointer;
      flex: 0 0 auto;
      margin-left: auto;
      // margin-right: 12px;
      background-color: transparent;

      .generic-iconbutton {
        background-color: transparent;
        border-radius: 50%;
        height: 28px;
        width: 28px;
      }

      .icon {
        // padding: 2px;
        background-color: transparent;
      }

      .unseencount {
        // border: 2px solid white;
        // background-color: var(--highlight-color);
        background-color: red;
        color: white;
        height: 18px;
        width: 18px;
        line-height: 18px;
        // bottom: -6px;
        // right: -10px;
        bottom: 0;
        right: -2px;
      }
    }

    .user-signinupmenu {
      flex: 0 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
    .signinup-link {
      height: 26px;
      width: auto;
      text-align: center;
      line-height: 26px;
      font-size: 12px;
      background-color: transparent;
      border-radius: 6px;
      border: 1px solid white;
      color: white;

      a {
        padding: 0 12px;
        @include linkpseudos {
          color: white;
          text-decoration: none;
          cursor: pointer;
        }
      }

      &.get-started {
        background-color: white;
        color: var(--highlight-color);
        margin-right: 10px;
        a {
          @include linkpseudos {
            color: var(--highlight-color);
          }
        }
      }

      & + .signinup-link {
        margin-left: 10px;
      }
    } // .signinup-link
  } // .layout-titlebar-content

// .layout-titlebar
@media only screen and (min-width: $mobile-width) {
  .layout-titlebar {
    .layout-titlebar-content {
      margin: 0 20px;

      & > a {
        height: 24px;
        padding: 21px 10px 21px 8px;
        margin-right: 43px;

        svg {
          height: 24px;
          width: 96px;
        }

				svg {
					height: 24px;
					width: 96px;
				}				
			}			

      user-profilemenu,
      .user-signinupmenu {
        .generic-iconbutton {
          height: 32px;
          width: 32px;
        }
      }
    } // .layout-titlebar-content
  } // .layout-titlebar
}
