@import './../../../../scss/colors.scss';
@import './../../../../scss/typography.scss';

.layout-overlay.overlay-sidebar .overlay .sidebar { // needed to make rules specific
    &.follow-menu-container {

    	.follow-menu-toolbar {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            flex: 0 0 auto;
            height: auto;

            .row {
                flex: 1 0 66px;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
            }
            .row + .row {
                flex: 1 0 auto;
            }
            .row.entity {
                @include fontSansBold;
                box-sizing: border-box;
                line-height: 29px;
                font-size: 20px;
                padding: 0 15px 15px 15px;
            }
            .cancel {
                margin-left: auto;
            }

        }

        .controls {


        }


    }
}
