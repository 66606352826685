@import "./../../../../scss/colors.scss";
@import "./../../../../scss/typography.scss";

.post-wallcontrols {
  display: flex;
  height: 60px;
  align-items: center;

  .wallStyle {
    flex: 0 0 auto;
    display: flex;
    padding: 0 5px;

    .generic-iconbutton {
      margin: 6px 5px 18px 5px;
      display: inline-block;
      height: 36px;
      line-height: 36px;
      width: auto;
      flex: 0 0 auto;
      background-color: transparent;
      color: $text-grey;
      text-align: center;
      box-sizing: border-box;

      &.active {
        color: var(--highlight-color);
      }

      .icon {
        height: 36px;
        width: 36px;
      }
    }
  }
}
