// background based images
@import './colors.scss';
@import './responsive.scss';

:focus {
	outline: none;
}

p, h1, h2, h3, h4, h5, div, section {
	max-width: 100%;
  word-break: break-word;
}

img {
  max-width: 100%;
	height: auto;
  -ms-interpolation-mode: bicubic;
  display: inline-block;
  vertical-align: middle;
}

.image {
	&.cover {
		position: relative;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
    max-width: 100%;
	}
	&.fadein {
		position: relative;
		.fader {
			opacity: 1;
		}
	}
	&.error {
		background-color: rgba(0, 0, 0, 0.3) !important;
	}
	// images which fade in on load
	.fader {
		opacity: 0; // default to hidden
		transition: opacity 250ms ease;
		position: absolute;
		top: 0; left: 0;
		height: 100%; width: 100%;
		z-index: 1;
	}
}

// default fonts
body {
	color: $text-black;
	background-color: $canvas-grey;
	-webkit-tap-highlight-color:rgba(0,0,0,0);
}

// default link styles
a {

	color: $text-black;
	text-decoration: underline;

	// &:hover, &:active {
	// 	color: black;
	// }

	&:visited {
		color: $text-black;

		// &:hover, &:active {
		// 	color: black;
		// }
	}
}

abbr[title] {
	text-decoration: none;
	border-bottom: 1px dotted #ccc;
}

// generic handling of tags inside main content only
.primary .tag {
    &:before {
        content: "#";
    }
}

// handle avatars the same everywhere by default
.avatar, .avatar-link, .avatars {
	.image {

		border-radius: 50%;

		.fader {
			border-radius: 50%;
		}

		&.error {
			// use live default avatar for failed images
			background-image: url(http://res.cloudinary.com/tremr/image/upload/dpr_1.0,f_auto,q_90,w_36,h_36,c_fill,g_face/avatar-default.png) !important;
		}
	}
}

// progress bars
.progress {
	background-color: $lighter-grey;
	border: 1px solid $text-black;
	height: 20px;
	margin-bottom: 10px;
	padding: 2px;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    transform: translateX(-50%) translateY(-13px);
    border-radius: 20px;

	.meter {
        border-radius: 20px;
		background: var(--highlight-color);
		display: block;
		height: 100%;
		transition: width 1000ms ease-in-out 0s;
	}
}

.sideways-scroller {

	width: 100%;
	max-width: 100%;
	min-width: 100%;

    overflow: scroll;
    // overflow-y: hidden; // can't use this as it draws a background behind the horizontal scroll bar

    // -ms-overflow-style: none;
    // -ms-overflow-style: -ms-autohiding-scrollbar;

	::-webkit-scrollbar {
	    width: 0px;  /* remove scrollbar space */
	    background: transparent;  /* optional: just make scrollbar invisible */
	}
	/* optional: show position indicator in red */
	::-webkit-scrollbar-thumb {
	    background: #000000;
	}


}

@media only screen and (max-width: $mobile-width) {
	.hide-mobile {
		display: none;
	}	
}
// @media only screen and (min-width: $medium-width) {
// 	.hide-desktop {
// 		display: none;
// 	}	
// }

  
.loading-ellipsis:after {
	content: "";
	overflow: hidden;	
	display: inline-block;
	vertical-align: bottom;
	animation: ellipsis-dot 0.88s infinite 0.3s;
	animation-fill-mode: forwards;
	text-align: left;
	width: 1em;
  }
  
  @keyframes ellipsis-dot {
	0% {
		content: "";
	  }
	  25% {
		content: ".";
	  }
	  50% {
	  content: "..";
	}
	75% {
	  content: "...";
	}
	100% {
	  content: "";
	}
  }