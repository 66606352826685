@import './../../../../scss/colors.scss';
@import './../../../../scss/typography.scss';
@import './../../../../scss/mixins/linkpseudos.scss';
@import './../../../../scss/responsive.scss';

$form-grey: color($dark-grey tint(20%));

.search-menu-button {

    cursor: pointer;
    background-color: transparent;
    border-radius: 50%;
    height: 28px;
    width: 28px;
    color: $light-grey;

    .icon-search {
      height: 28px;
      width: 28px;
    }
}

.search-menu {

    background: white;
    z-index: 99;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 11px 16px 11px 9px;
    border-radius: 0;
    border: none;

    display: flex;
	align-items: flex-start;
    flex-direction: column;

    .suggestions {
        display: block;
        @include fontSansBold;
        font-size: 12px;
        line-height: 40px;
        margin-left: 15px;
        strong {
            color: $text-black;
            font-weight: 700;
            text-transform: uppercase;
            @include fontSansBold;
            font-size: 12px;
            line-height: 40px;
        }
        .tag-list {
            margin: 0;
            flex-direction: column;
            a {
                @include fontSansBold;
                font-size: 16px;
                line-height: 34px;
                &:after {
                    content: "";
                    display: none;
                }
            }
        }
    }

    .search-form {

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        position: relative;
        height: 40px;
        width: 100%;
        padding-bottom: 10px;
        margin-bottom: 5px;
        border-bottom: 2px solid $lighter-grey;

        .icon {
            color: var(--highlight-color);
            border: 2px solid $lighter-grey;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            cursor: pointer;
            flex: 0 0 32px;
            margin-left: 9px;
            .svgWrapper {
                height: 80%;
                width: 80%;
                top: 10%;
                left: 10%;
            }
        }

        input {
            flex: 1 1 auto;
        	box-sizing: border-box;
        	display: inline-block;
            line-height: 36px;
            height: 36px;
            background-color: white;
            border: none;
            margin: 0;
            vertical-align: top;
            padding: 0 0 0 16px;
            color: $dark-grey;
            border-radius: 18px;
            box-shadow: none;
            font-size: 16px;
            // border: 2px solid $lighter-grey;

            &::placeholder {
    		    color: $light-grey;
                opacity: 1;
    		  }

            &:focus {
                outline: none;
            }
        }
    }
}

@media only screen and (min-width: $mobile-width) {

  .search-menu-button {

      height: 32px;
      width: 32px;

      .icon-search {
        height: 32px;
        width: 32px;
      }
  }
    .search-menu {
        background: white;
        z-index: 99;
        position: absolute;
        top: 0;
        right: 10px;
        left: auto;
        bottom: auto;
        width: 300px;
        height: auto;
        border-radius: 0 0 6px 6px;
        border-left: 1px solid rgba(45, 45, 45, 0.06);
        border-right: 1px solid rgba(45, 45, 45, 0.06);
        border-bottom: 1px solid rgba(45, 45, 45, 0.06);
    }
}
