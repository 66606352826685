@import "./../../../../scss/colors.scss";
@import "./../../../../scss/typography.scss";

.reputation-tags {
  display: flex;
  @include fontSansBold;
  font-size: 14px;
  line-height: 22px;
  justify-content: center;
  align-items: center;
  color: var(--highlight-color);

  .reputation-tag {
    flex: 0 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    .reputation-tag-name {
      flex: 0 1 auto;
      text-transform: capitalize;
    }
    .reputation-stars {
      flex: 0 1 auto;
      width: auto !important;
      height: 15px !important;
      // display: flex;
      line-height: 15px !important;
      // justify-content: center;
      // align-items: center;
      canvas {
        width: auto !important;
        height: 15px !important;
      }
    }
  }

  .reputation-tag + .reputation-tag {
    margin-left: 5px;
  }
}
