@import './../../../../scss/colors.scss';
@import './../../../../scss/typography.scss';

.upvote-button {

    position: relative;

    .activity-icon-upvote,
    .activity-icon-downvote {
        cursor: pointer;
        .set {
            fill: var(--highlight-color);
        }

        & + .activity-icon-upvote {
            margin-left: 10px;
        }
    }

    .flag {

        position: absolute;
        top: -22px; // minimum space above (before background overlaps)
        // top: -26px; // best for layout (except toolbar)
        left: -23px;
        width: 60px;
        height: 30px;
        background-color: var(--highlight-color);
        @include fontSansBold;
        text-align: center;
        font-size: 12px;
        color: var(--highlight-color);
        border-radius: 4px;
        line-height: 30px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        background-color: white;
        transform: translateY(46px);
        opacity: 0;
        -webkit-animation: upvoteanimation 2s 1 ease;
        animation: upvoteanimation 2s 1 ease;

        .text {
          display: block;
          flex: 0 0 auto;
          width: 30px;
          text-align: center;
        }

        svg {
          display: block;
          flex: 0 0 auto;
          height: 16px;
          width: 22px;

          .level {
            stroke: var(--highlight-color);
            stroke-width: 1;
            fill: white;
          }
        }

        &.level1 svg .level1 {
          fill: var(--highlight-color);
        }
        &.level2 svg .level1,
        &.level2 svg .level2 {
          fill: var(--highlight-color);
        }
        &.level3 svg .level1,
        &.level3 svg .level2,
        &.level3 svg .level3 {
          fill: var(--highlight-color);
        }

        &.level-1 svg .level-1 {
          fill: var(--highlight-color);
        }
        &.level-2 svg .level-1,
        &.level-2 svg .level-2 {
          fill: var(--highlight-color);
        }
        &.level-3 svg .level-1,
        &.level-3 svg .level-2,
        &.level-3 svg .level-3 {
          fill: var(--highlight-color);
        }

        &.level-1, &.level-2, &.level-3, &.level-0 {
            left: auto;
            right: -1px;
        }
    }
}

@-webkit-keyframes upvoteanimation {
  0% { transform: translateY(46px ); opacity: 0; }
  10% { transform: translateY(0); opacity: 1; }
  50% { transform: translateY(0); opacity: 1; }
  100% { transform: translateY(-64px); opacity: 0;}
}

@keyframes upvoteanimation {
  0% { transform: translateY(46px); opacity: 0; }
  10% { transform: translateY(0); opacity: 1; }
  50% { transform: translateY(0); opacity: 1; }
  100% { transform: translateY(-64px); opacity: 0;}
}
