@import "./../../../../scss/colors.scss";
@import "./../../../../scss/typography.scss";

// some specific styles when on a card
.post-card,
.post-content-card,
.post-list-card {
  .reputation-generic-overview {
    .reputation-post-warning-badge {
      line-height: 13px;
      font-size: 9px;
      height: 14px;
      border-radius: 7px;
      padding: 0 16px 0 6px;

      & > div {
        padding: 0.5px 0 0 0;
      }

      .icon {
        padding: 0;
        right: -6px;
        top: -4px;
        height: 22px;
        width: 22px;
        clip-path: circle(9px at 11.5px 11.3px);
      }
    }
    &.open {
      .reputation-generic-overview-popup {
        transform: translateY(29px);
      }
    }
  }
}

// standard styles
.reputation-generic-overview {
  position: relative;
  cursor: pointer;

  &:empty {
    display: none;
  }

  .reputation-post-warning-badge {
    background-color: var(--highlight-color);
    color: white;
    display: block;
    position: relative;
    line-height: 12px;
    font-size: 12px;
    height: 20px;
    border-radius: 10px;
    padding: 0 24px 0 16px;
    box-sizing: border-box;
    margin-left: auto;
    @include fontSansBlack;
    text-transform: uppercase;

    & > div {
      padding: 4px 0 0 0;
    }
    .icon {
      padding: 0;
      position: absolute;
      right: -12px;
      top: -8px;
      height: 36px;
      width: 36px;
      background: var(--highlight-color);
      color: white;
      clip-path: circle(14px at 18px 18px);
    }
  }

  & > .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1234;
  }

  &.left.open {
    .reputation-generic-overview-popup {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 20px;
      & > .icon {
        position: absolute;
        left: 16px;
      }
    }
  }

  &.left.open {
    &:after {
      left: -15px;
      right: auto;
    }
  }
  &.open {
    &:after {
      content: " ";
      position: absolute;
      top: -15px;
      right: -15px;
      height: 50px;
      width: 100px;
      background: transparent;
    }

    .reputation-generic-overview-popup {
      background: white;
      border: 3px solid var(--highlight-color);
      border-bottom-left-radius: 20px;
      position: fixed;
      transform: translateY(34px);
      height: auto;
      width: 250px;
      max-width: min(250px, 100%);
      margin: 0;
      padding: 0;
      @include fontSansRegular;
      padding: 0 0 10px 0;
      user-select: none;
      z-index: 1235;

      & > .icon {
        transform: rotate(180deg);
        fill: var(--highlight-color);
        position: absolute;
        top: -13px;
        height: 12px;
        width: 18px;
        right: 16px;
        cursor: pointer;
        .triangle {
          fill: var(--highlight-color);
          stroke: var(--highlight-color);
          stroke-width: 1;
        }
        .edge {
          fill: var(--highlight-color);
          stroke: var(--highlight-color);
          stroke-width: 1;
        }
      }

      h4 {
        background: var(--highlight-color);
        color: white;
        text-transform: uppercase;
        line-height: 34px;
        font-size: 11px;
        @include fontSansBold;
        margin: 0 0 10px 0;
        padding: 0 0 1px 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        .icon {
          margin-left: auto;
          margin-right: 13px;
          color: white;
          fill: white;
          height: 30px;
          width: 30px;
          cursor: pointer;
        }
      }

      & > p {
        margin: 0;
        padding: 0 15px;
        color: $text-grey;
        line-height: 20px;
        font-size: 13px;
        display: block;
        color: $text-black;
        @include fontSansRegular;

        a {
          font-style: italic;
          font-size: 11px;
          text-transform: uppercase;
          color: var(--highlight-color);
        }
      }
    }
  }
}
