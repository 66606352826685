// generic styles for the right-hand side menu

@import "./../../../../scss/colors.scss";
@import "./../../../../scss/typography.scss";
@import "./../../../../scss/mixins/linkpseudos.scss";
@import "./../../../../scss/responsive.scss";

@mixin sidebar {
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // background-color: rgba(0, 20, 0, 0.2);
    z-index: 20081;

    .sidebar {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: white;
      z-index: 20091;
      background-clip: content-box;
      animation: slide-in 100ms ease;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      -webkit-overflow-scrolling: touch;

      .toolbar {
        flex: 0 0 66px;
        height: 66px;
        box-sizing: border-box;
        width: 100%;
        padding-right: 15px;
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        &:before {
          content: " ";
          display: block;
          position: absolute;
          bottom: 0;
          left: 6px;
          right: 6px;
          height: 3px;
          background-color: $lighter-grey;
        }

        .button {
          background-color: var(--highlight-color);
          color: white;
          cursor: pointer;
          flex: 0 0 auto;
          height: 34px;
          line-height: 20px;
          width: auto;
          padding: 0 25px 0 25px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          box-sizing: border-box;
          border-radius: 17px;
          @include fontSansBold;
          font-size: 15px;
          text-decoration: none;
          margin: 0 0 0 5px;
          box-sizing: border-box;
          @include linkpseudos {
            color: white;
            text-decoration: none;
          }

          &.cancel {
            background: white;
            border: 3px solid $lighter-grey;
            color: var(--highlight-color);
            @include linkpseudos {
              color: var(--highlight-color);
            }
          }
        }

        .close.icon {
          height: 28px;
          width: 28px;
          cursor: pointer;
          border-radius: 50%;
          border: 3px solid $lighter-grey;
          color: var(--highlight-color);
          margin: 0 auto 0 15px;
          & > .svgWrapper {
            top: 10%;
            left: 10%;
            height: 80%;
            width: 80%;
          }
        }

        .row.title {
          margin-left: 12px;
        }
      } // .toolbar

      .controls {
        flex: 1 1 auto;
        width: 100%;
        box-sizing: border-box;
        overflow: auto;
        background-color: white;
        transition: transform 600ms;
        padding: 0 15px;

        .section {
          border-bottom: 3px solid $lighter-grey;
        }

        p.multiline {
          line-height: 24px;
        }

        .line {
          display: block;
          @include fontSansBold;
          color: $text-black;
          height: 60px;
          line-height: 60px;
          font-size: 17px;
          border-bottom: 1px solid $lighter-grey;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-decoration: none;

          @include linkpseudos {
            text-decoration: none;
            white-space: normal;
          }

          .beta {
            color: var(--highlight-color);
            margin-left: 3px;
          }          

          &.expandable {
            min-height: 60px;
            height: auto;
            .contenteditable {
              line-height: 30px;
              padding: 15px 0;
            }
          }

          .contenteditable[data-remaining]:after,
          [contenteditable="true"][data-remaining]:after {
            font-size: 10px;
            line-height: 24px;
            bottom: -4px;
          }

          a {
            cursor: pointer;
            text-decoration: none;
            @include linkpseudos {
              text-decoration: none;
            }
          }
        }
        a.line {
          cursor: pointer;
        }

        .button.save {
          background-color: var(--highlight-color);
          height: 36px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          @include fontSansBold;
          font-size: 17px;
          line-height: 16px;
          color: white;
          border-radius: 18px;
          padding: 0 5px;
          max-width: 100px;
          box-sizing: border-box;
        }
      }
    }
  } // .overlay

  @media only screen and (min-width: $mobile-width) {
    .overlay {
      .sidebar {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: auto;
        width: 350px;
        border-left: 3px solid $lighter-grey;
        animation: slide-in-desktop 100ms ease;

        .toolbar,
        .controls {
          left: auto;
          width: 350px;
        }
      }
    }
  }
} // sidebar mixin

@keyframes slide-in-desktop {
  0% {
    transform: translateX(350px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slide-in {
  0% {
    transform: translateX(100vh);
  }
  100% {
    transform: translateX(0);
  }
}
