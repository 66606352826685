@import './../../../../scss/colors.scss';
@import './../../../../scss/typography.scss';

$multibutton_height: 30px;
$base-border-radius: 4px;

// simple dropdown for a preset option that can be toggled
.dropdown-option {
	display: inline-block;
	width: auto;
	padding: 16px;

	.label {
		display: inline-block;
		vertical-align: top;
	}

	.icon {
		height: 1em;
		width: 1em;
		display: inline-block;
		vertical-align: top;
	}

	.handle {
		display: block;
	}
	.items {
		display: none;
	}

	&.open {
		.handle {
			display: block;
		}
		.items {
			position: absolute;
			bottom: 40px;
			display: block;
			background-color: white;
			z-index: 500;
			& > * {
				display: block;
			}
		}
	}
}

// button that opens dropdown panel containing child components
.dropdown {

	position: relative;
	display: inline-block;

	.handle {
		user-select: none;
		text-transform: capitalize;
		position: relative;
		display: inline-block;
		float: left;
		height: 32px;
		line-height: 30px;
		padding: 0 30px;
		white-space: nowrap;
		cursor: pointer;
		background-color: $text-grey;
		border-radius: $base-border-radius;
		transition: transform 100ms ease 0s;

		&:hover {
			background-color: color($canvas-grey shade(20%));
		}

		.label {
			height: 30px;
			font-size: 10px;
			line-height: 30px;
			display: inline-block;
			vertical-align: middle;
		}

		.icon {
			width: 25px;
			height: 25px;
			line-height: 30px;
			display: inline-block;
			vertical-align: middle;
		}
	}

	.content {
		z-index: 499;
		border-radius: 4px;
		cursor: pointer;
		border: 1px solid $text-grey;
		color: $text-grey;
		display: block;
		line-height: $multibutton_height;
		background-color: white;
		position: absolute;
		top: 34px;
		width: auto;
	}

	&.dropup .content {
		top: auto;
		bottom: 34px;
	}
}
