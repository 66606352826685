@import "./../../../../scss/colors.scss";
@import "./../../../../scss/typography.scss";
@import "./../../../../scss/responsive.scss";

.expand {
  color: $text-black;
  position: absolute;
  right: 0;
  background: white;
  bottom: 0;
  cursor: pointer;
  height: 34px;
  width: 34px;
  text-align: center;
  z-index: 100;
  border-radius: 50%;

  .outer {
    height: 28px;
    width: 28px;
    border: 3px solid $canvas-grey;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    &:after {
      content: " ";
      position: absolute;
      height: 17px;
      width: 34px;
      top: -3px;
      background-color: white;
      z-index: 1;
    }

    .inner {
      z-index: 2;
      background-color: $canvas-grey;
      height: 22px;
      width: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;

      .icon {
        height: 20px;
        width: 20px;
        color: var(--highlight-color);
      }
    }
  }
}

@media only screen and (min-width: $mobile-width) {
  .expand {
    height: 40px;
    width: 40px;
    .outer {
      height: 34px;
      width: 34px;
      &:after {
        height: 20px;
        width: 40px;
      }
      .inner {
        height: 28px;
        width: 28px;
        .icon {
          height: 24px;
          width: 24px;
        }
      }
    }
  }
}
