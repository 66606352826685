@import './../../../../scss/colors.scss';

footer.layout-footer {
	position: fixed;
	bottom: 0;
	left: 50%;
	background-color: $lighter-grey;
	z-index: 10099;
	transition: height 200ms ease 0s;
	transform: translateX(-50%);
	height: 0;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 6px 6px 0 0;

	& > * {
		flex: 0 0 auto;
	}

	&.shown {
		height: 60px;
	}
}
