@import './../../../../scss/colors.scss';
@import './../../../../scss/responsive.scss';

.generic-horizontalscroller {

    box-sizing: border-box;
    width: 100%;

	.scroll-content {
        overflow-x: auto;
    	-webkit-overflow-scrolling: touch;
    	max-width: 100vw;
        -webkit-transform: translateZ(0); /* fixes scrollbar bug where scrollbar shows over other elements when overlapping */
        word-break: normal !important;
    }

    &.no-scrolling .scroll-content {
        position: relative;
        padding: 0 30px;
    }

    &.content-fits .scroll-content {
        position: relative;
        padding: 0 0;
    }

    &.custom-scrolling {
        position: relative;
        padding: 0 30px;

        .scroll-content {
            overflow-x: hidden;
            z-index: 1;
            padding: 0;
            scroll-behavior: smooth;

            & > * {
                // min-width: max-content;
                min-width: fit-content;
            }
        }

		.scroll-button {
            position: absolute;
            background-color: white;
            color: $canvas-grey;
            width: 30px;
            top: 3px;
            z-index: 2;
            height: calc(100% - 6px);
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;

            &.left {
                left: 0;
            }
            &.right {
                right: 0;
            }
            &.active {
                cursor: pointer;
                color: var(--highlight-color);
            }
        }
	}
}

@media only screen and (min-width: $mobile-width) {

    .generic-horizontalscroller {

        .scroll-content:first-child {
            padding-left: 20px;
        }
        .scroll-content:last-child {
            padding-right: 20px;
        }

        &.no-scrolling {
            .scroll-content {
                padding-left: 20px;
                padding-right: 20px;
            }
        }

    }
}

@media only screen and (min-width: 1220px) {

    .generic-horizontalscroller {

        width: 100%;
        max-width: 1280px;

        .custom-scrolling {
            width: 100%;
            max-width: 1280px;
        }
    }
}

@media only screen and (min-width: 1280px) {

    .generic-horizontalscroller.custom-scrolling {

        width: 1280px;

        custom-scrolling {
            max-width: 1280px;
        }
    }
}
