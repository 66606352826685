@import './../../../../scss/colors.scss';
// @import './../../../../scss/typography.scss';
// @import './../../../../scss/mixins/linkpseudos.scss';
// @import './../../../../scss/responsive.scss';

.user-sidebar-reputation {

  display: block;
  position: relative;

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 28px;
    width: 28px;
    cursor: pointer;
    border-radius: 50%;
    border: 3px solid $lighter-grey;
    color: var(--feed-highlight-color);
    margin: 0 auto 0 15px;
    z-index: 999;
    background-color: white;
    box-shadow: 0 0 0 3px white;
    & > .svgWrapper {
        top: 10%;
        left: 10%;
        height: 80%;
        width: 80%;
    }
  }



}
