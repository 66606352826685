@import "./../../../../scss/colors.scss";
@import "./../../../../scss/typography.scss";
@import "./../../../../scss/responsive.scss";

$breadcrumb-grey: white;
$content-card-font-size: 14px;
$content-card-line-height: 21px;

$content-card-font-size-medium: 17px;
$content-card-line-height-medium: 27px;

.post-breadcrumbs {
  .ancestor-title {
    display: none;
  }

  .post-list-card-wrapper {
    .expand {
      transform: translateY(-23px) translateX(-50%);
    }
  }

  .toggle {
    display: block;
    margin-bottom: -14px;
    z-index: 1;
    .count,
    .show {
      font-size: 13px;
      @include fontSansBlack;
      cursor: pointer;
    }
  }

  .post-content-card > article .card-content .editor-richtext .content {
    .embed-block,
    .upload-block {
      margin-left: 0;
      max-width: calc(100% + 12px);
      width: calc(100% + 12px);
    }
  }

  .toggle + .breadcrumb:first-of-type {
    .show {
      padding-top: 10px;

      // &:before {
      //     top: 0;
      //     bottom: 0;
      //     height: auto;
      // }
    }
    .show {
      padding-top: 10px;
    }
  }

  .breadcrumb {
    flex-direction: column;
  }

  .breadcrumb,
  .toggle {
    display: flex;
    background-color: $card-white;
    position: relative;
    width: 100%;

    .post-content-card > article .reply-button-container {
      transform: translateY(1px);
    }
    .post-content-card > article .door-policy-summary-container {
      transform: translateY(1px);
    }

    &.breadcrumb article:before {
      top: 0;
      bottom: 0;
      height: 100%;
      content: " ";
      position: absolute;
      left: 28px;
      // transform: translateX(-1px);
      width: 3px;
      background-color: $canvas-grey;
    }
    &.breadcrumb:after {
      top: auto;
      bottom: -8px;
      left: 17px;
      height: 24px;
      width: 24px;
      content: " ";
      position: absolute;
      z-index: 1;
      // transform: translateX(-12px) translateY(11px);
      background-color: $canvas-grey;
      border-radius: 50%;
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 512 512'><path stroke-width='40' stroke='white' d='M393.657,206.02 C397.148,206.429 399.737,208.048 401.641,210.966 C404.421,215.738 402.806,221.861 398.034,224.641 L271.605,298.295 C261.96,303.913 250.04,303.913 240.395,298.295 L113.966,224.641 C109.194,221.861 107.579,215.738 110.359,210.966 C113.139,206.194 119.262,204.579 124.034,207.359 L250.463,281.013 C253.885,283.007 258.115,283.007 261.537,281.013 L387.966,207.359 C389.937,206.164 391.372,206.202 393.657,206.02 z' /></svg>");
      background-position: 0 1px;
      background-size: 24px 24px;
    }
    &.breadcrumb .card-content:after {
      top: auto;
      bottom: 3px;
      left: 58px;
      right: 12px;
      margin-right: 0;
      height: 3px;
      width: auto;
      content: " " !important;
      display: block !important;
      position: absolute;
      z-index: 1;
      background-color: $canvas-grey;
    }

    &.breadcrumb:first-child article:before {
      background-color: $canvas-grey;
      top: auto;
      height: calc(100% - 35px);
    }

    .post-content-card,
    .post-list-card {
      margin-bottom: 0;
    }

    .post-content-card .card-content,
    .post-list-card .card-content {
      // padding-bottom: 20px;

      &:after {
        content: "";
        display: none;
      }
    }

    &.toggle {
      color: var(--highlight-color);
      font-size: 13px;
      line-height: 22px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-top: 20px;

      .count {
        height: 34px;
        width: 34px;
        border: 3px solid $canvas-grey;
        color: var(--highlight-color);
        background-color: $card-white;
        line-height: 22px;
        font-size: 12px;
        z-index: 4;
        border-radius: 50%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 12px;
        box-sizing: border-box;
      }

      .number {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .show {
        padding: 0;
        border-bottom: none;
        flex: 1 1 calc(100% - 93px);
        margin-right: 15px;
        position: relative;
        margin-left: 12px;
      }
    } // &.toggle
  } // .breadcrumb
}

@media only screen and (min-width: $mobile-width) {
  .post-breadcrumbs {
    // margin: 0 20px;
    .breadcrumb.breadcrumb {
      article:before {
        left: 44px;
      }
      &:after {
        left: 34px;
      }
      .card-content:after {
        bottom: 0;
        left: 81px;
        right: 25px;
      }
      .post-list-card {
        article {
          // display: grid;
          grid-template-columns:
            [card-start] 25px [avatar-start] 40px [avatar-end] 16px [content-start] minmax(
              auto,
              714px
            )
            [content-end] 25px [card-end];
        }
      }
      .post-content-card {
        .card-content:after {
          left: 97px;
        }
        // article:before {
        //   left: 59px;
        // }
        article > .card-content {
          // display: grid;
          grid-template-columns:
            [card-start] 25px [avatar-start] 40px [avatar-end] 16px [content-start] minmax(
              auto,
              714px
            )
            [content-end] 25px [card-end];

          .reply-button-container {
            transform: translateY(7px);
          }
          .door-policy-summary-container {
            transform: translateY(7px);
          }
        }
      }
    }
    .toggle.toggle {
      .count {
        margin-left: 28px;
      }
      .show {
        font-size: 15px;
        margin-left: 20px;
      }
    }
    .post-list-card > article .card-content .reputation-stars {
      margin-right: 0;
    }
  }
}
