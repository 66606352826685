@import './../../../../scss/typography.scss';
@import './../../../../scss/colors.scss';

.post-subfeeds {

	position: relative;

    h2.handle {
        cursor: pointer;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
        background-image: url('/images/down.png');
        background-size: 21px 60px;
        background-position: 100% 0px;
        background-repeat: no-repeat;
        padding-right: 30px;

        &.open {
            background-image: url('/images/up.png');
        }
    }

    .button.add {
        cursor: pointer;
        background: var(--highlight-color);
        border-radius: 12px;
        color: white;
        margin: 0;
        padding: 6px 8px;
        @include fontSansSemiBold;
        font-size: 12px;
        line-height: 12px;
    }

	.option-list {
		padding: 0;
		margin: 0;
	}

	.option-item {
		display: flex;
		align-items: center;
		height: 30px;
		line-height: 30px;
		list-style: none;
		margin-left: 0;

		.icon {
			flex: 0 0 auto;
			cursor: pointer;
		}

		.feedName, .label {
			text-transform: capitalize;
		}

		.feedName, .newfeed {
			flex: 1 0 auto;
			text-transform: capitalize;
		}

		a {
			flex: 0 0 auto;
			cursor: pointer;
		}
	}

	label {
		display: none;
	}

    .newFeed {
        flex: 1 1 100%;
        margin-left: 10px;

        &:after {
            display: none;
        }

        [contenteditable] {
            color: var(--highlight-color);
            background-color: white;
            @include fontSansRegular;
            font-size: 17px;
            line-height: 24px;
            height: 34px;
            padding-top: 5px;
            padding-bottom: 5px;
            width: 100%;
            box-sizing: border-box;
            -webkit-user-select: text;
            user-select: text;
            &::placeholder {
                // color: var(--highlight-color);
                color: $light-grey;
                @include fontSansRegular;
                font-size: 15px;
            }
        }
    }

	input, [contenteditable] {
		max-width: 300px;
		margin: 0;
		height: 50px;
		line-height: 50px;
		border-radius: 0;
		border: none;
		font-size: 1.4rem;
		box-shadow: none;
		&:focus {
			box-shadow: none;
		}
		&::placeholder {
			color: $form-grey;
		}
	}

}
