@import './../../../../scss/typography.scss';
@import './../../../../scss/responsive.scss';

.factcheck-badge {
  display: block;
  max-width: 122px;

  .badge {
    background-color: var(--highlight-color);
    border-radius: 3px;
    color: white;
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    @include fontSansBold;
    padding: 0;
    height: 18px;
    margin-top: 4px;

    .icon {
      flex: 0 0 auto;
      color: white;
      height: 11px;
      width: 11px;
      margin: 0 4px 0 7px;
    }
    label {
      flex: 0 0 auto;
      color: white;
      line-height: 10px;
      font-size: 10px;
      margin-right: 9px;
      @include fontSansBold;
    }
  }
}

.post-show.post .article-container {
  .factcheck-badge {

    .badge {
      border-radius: 4px;
      height: 24px;
      margin-top: 4;

      .icon {
        height: 14px;
        width: 14px;
        margin: 0 7px 0 7px;
      }
      label {
        line-height: 12px;
        font-size: 12px;
        margin-right: 9px;
      }
    }
  } // .factcheck-badge
} // .post-show.post .article-container

@media only screen and (min-width: $mobile-width) {

.post-show.post .article-container {
    .factcheck-badge {

      .badge {
        border-radius: 4px;
        height: 28px;
        margin-top: 0;

        .icon {
          height: 16px;
          width: 16px;
          margin: 0 8px 0 7px;
        }
        label {
          line-height: 14px;
          font-size: 14px;
          margin-right: 9px;
        }
      }
    } // .factcheck-badge
  } // .post-show.post .article-container
}
