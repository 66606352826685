@import './../../../../scss/colors.scss';
@import './../../../../scss/typography.scss';
@import './../../../../scss/mixins/linkpseudos.scss';

.layout-overlay.overlay-sidebar .overlay .sidebar { // needed to make rules specific

    .layout-notifications {

        .section {

            &.menu {

                a {
                    display: inline-block;
                    margin: 0 0 5px 0;
                    @include fontSansRegular;
                    font-size: 14px;
                    line-height: 32px;
                    color: $text-black;
                    text-decoration: none;
                    cursor: pointer;

                    @include linkpseudos {
                        text-decoration: none;
                    }

                    &:before {
                        content: "•";
                        display: inline;
                        padding: 0 5px;
                        color: $text-black;
                        cursor: default;
                        font-family: serif;
                    }
                    &:first-child {
                        margin-left: 0;
                        &:before {
                            content: "";
                            padding-right: 0;
                        }
                    }
                } // a
            } // .menu

        }

        .notifications.section {
            &:empty {
                background: red;
                border-bottom: none;
            }
        }

    	.activity-item.line  {

            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            height: auto;
            overflow: visible;
            text-overflow: ellipsis;
            white-space: normal;

            line-height: 22px;
            background-color: white;

            &.seen {
                .avatar {
                    opacity: 0.8;
                }
                .info {
                    a {
                        color: $light-grey;
                        @include linkpseudos {
                            color: $light-grey;
                        }
                        time {
                            color: $light-grey;
                            &:before {
                                color: $light-grey;
                            }
                        }
                    }
                    p {
                        color: $light-grey;
                        a {
                            color: $light-grey;
                            @include linkpseudos {
                                color: $light-grey;
                            }
                        }
                    }
                }
    		}

            .avatar {
                transition: opacity 200ms ease;
                flex: 0 0 50px;
                margin: 15px 15px 15px 0;
                img {
                    border-radius: 50%;
                    background-color: white;
                }
            }

            .info {
                transition: color 200ms ease;
                flex: 1 1 auto;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                margin: 15px 0 15px 0;
                @include fontSansRegular;

                .reactions-list {
                    text-transform: capitalize;
                }

                a {
                    transition: color 200ms ease;
                    flex: 0 0 auto;
                    margin: 0;
                    padding: 0;
                    display: inline-block;
                    @include fontSansBold;
                    font-size: 15px;
                    line-height: 22px;
                    color: var(--highlight-color);
                    time {
                        transition: color 200ms ease;
                        @include fontSansRegular;
                        font-size: 15px;
                        line-height: 22px;
                        display: inline-block;
                        color: var(--highlight-color);

                        &:before {
                            transition: color 200ms ease;
                            content: "•";
                            display: inline;
                            padding-right: 5px;
                            margin-left: 5px;
                            color: var(--highlight-color);
                            cursor: default;
                            font-family: serif;
                        }

                    }
                }
                p {
                    transition: color 200ms ease;
                    flex: 0 0 auto;
                    margin: 0;
                    padding: 0;
                    display: block;
                    @include fontSansRegular;
                    font-size: 15px;
                    line-height: 22px;
                    a {
                        transition: color 200ms ease;
                        text-decoration: none;
                        color: $text-black;
                        @include fontSansBold;
                        @include linkpseudos {
                            transition: color 200ms ease;
                            text-decoration: none;
                            color: $text-black;
                        }
                    }
                    .repost-comment {
                        display: block;

                        &:before {
                            content: '‘';
                        }
                        &:after {
                            content: '’';
                        }
                    }
                    .channels-list a {
                        &:before {
                            content: ", ";
                        }
                        &:first-child {
                            &:before {
                                content: "";
                            }
                        }
                    }
                }
            }
    	}
    }
}
