// NOTE: watchout for block comments at the end of commented out lines as they break everything silently!

$dark-grey: #2d2d2d;
$tremr-highlight: #FF7F10;
$light-grey: #969696;
$lighter-grey: #f5f4f4;

$error-color: red;
$success-color: #bada55;

$text-black: #2d2d2d;
$canvas-grey: $lighter-grey;
$card-white: white;

// some ui elements
$lighter-grey-border: $lighter-grey;
$tr-editor-highlight-color: $tremr-highlight;

// maybe not used after new t3 design implemented - try to replace with tints of dark-grey
$mid-grey: $light-grey;
$text-grey: $mid-grey;
$form-grey: $mid-grey; //color($text-black tint(20%));
$placeholder-grey: $mid-grey;

$light-green: #aae540;
$dark-green: #58904f;
$light-blue: #52a1db;
$salmon-pink: #ec7977;
$light-yellow: #fbdb01;
$orange: #FF7F10;

$profile-colors-red: #e74101;
$tr-news-orange: #FF7F10;
