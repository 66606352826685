@import './../../../../scss/colors.scss';
@import './../../../../scss/typography.scss';

.layout-overlay.overlay-sidebar .overlay .sidebar { // needed to make rules specific
    &.user-sidebar-activity {

    	.user-sidebar-activity-toolbar {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            flex: 0 0 auto;
            height: auto;
            overflow: visible;

            &:before {
                bottom: -2px;
            }

            .row {
                flex: 1 0 66px;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;

                .title {
                    flex: 1 0 auto;
                    margin: 15px 0 10px 15px;
                    @include fontSansBold;
                    font-size: 30px;
                    line-height: 30px;
                } // .title

                .close {
                    margin: 10px 0 0 auto;
                }

            }

        }

        .controls {

            margin-top: 10px;

            .feed-views {
                margin: 0 0 5px 0;
                flex: 1 0 auto;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                flex-wrap: wrap;
                .stat {
                    flex: 0 0 50%;
                    line-height: 32px;
                    color: $text-grey;
                    label {
                        line-height: 16px;
                        font-size: 12px;
                        color: $text-grey;
                        @include fontSansRegular;
                        em {
                            font-style: normal;
                        }
                    }
                    output {
                        color: $text-grey;
                        line-height: 28px;
                        font-size: 28px;
                        @include fontSansBold;
                    }
                }
                .stat + .stat {
                    text-align: right;
                    color: var(--highlight-color);
                    label, output {
                        color: var(--highlight-color);
                    }
                }
                svg {
                    flex: 1 0 100%;
                    overflow: visible;
                    padding-bottom: 2px;
                    margin-top: 10px;

                    .axis {
                        stroke: #EFEEF0;
                    }
                    .bars {
                        fill: #cbcbcb;
                        rect:last-of-type {
                            fill: var(--highlight-color);
                        }
                    }
                }
            } // .feed-views

        }


    }
}
