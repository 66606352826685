@import './../../../../scss/colors.scss';
@import './../../../../scss/typography.scss';

.unseencount {

	position: absolute;
	top: auto;
	left: auto;
	bottom: -6px;
	right: -10px;
	background-color: var(--highlight-color);
	color: white;
	height: 20px;
	width: 20px;
	font-size: 10px;
    text-align: center;
	line-height: 20px;
	border-radius: 50%;
	overflow: hidden;
	// text-overflow: clip;
	text-overflow: ellipsis;
}
