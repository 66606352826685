@import "./../../../../scss/colors.scss";
@import "./../../../../scss/typography.scss";
@import "./../../../../scss/responsive.scss";

$breadcrumb-grey: white;

@mixin tremr-editor-caption {
  display: block;
  @include fontSansRegular;
  margin: 0;
  padding: 10px 11px 14px 11px;
  word-wrap: break-word;
  line-height: 1.6em;
  text-align: left;
  font-size: 14px;
  &[data-remaining]:after,
  &[data-remaining]:after {
    right: 11px;
  }
  @media only screen and (min-width: $mobile-width) {
    padding: 10px 0 14px 0;
    &[data-remaining]:after,
    &[data-remaining]:after {
      right: 11px;
    }
  }
}

@mixin tremr-editor-embed {
  transition: background-color 100ms ease 0s;

  background-color: $breadcrumb-grey;

  &.loading {
    background-color: $breadcrumb-grey;
  }

  .caption {
    @include tremr-editor-caption;
  }

  .info {
    background-color: var(--highlight-color);
    padding: 1em 11px;
    @include fontSansRegular;
    line-height: 28px;
    font-size: 18px;

    a {
      color: white;
      text-decoration: none;
      @include linkpseudos {
        color: white;
        text-decoration: none;
      }
      &:hover {
        text-decoration: underline;
      }
    }

    h4 {
      @include fontSansBold;
      line-height: 28px;
      font-size: 18px;
      margin: 0;
      padding: 0;
    }
    p {
      @include fontSansRegular;
      line-height: 28px;
      font-size: 18px;
      margin: 0;
      padding: 0;
      &.link {
        margin: 0;
      }
    }
  } // .info
}

@mixin tremr-editor-remove-button {
  position: absolute;
  top: 30px;
  right: 34px;
  z-index: 66;
  display: inline-block;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  color: $tr-editor-bg;
  text-align: center;
  cursor: pointer;

  .icon {
    position: absolute;
    top: 15%;
    left: 15%;
    width: 70%;
    height: 70%;
  }
}
