@import "./../colors.scss";

$control-grey: color($canvas-grey shade(20%));

@mixin progress {
  background-color: var(--highlight-color);
  position: relative;

  &:after {
    content: " ";
    position: absolute;
    top: 0;
    left: auto;
    bottom: 0;
    width: 0;
    right: 100%;
    background-color: rgba(255, 255, 255, 0.2);
    -webkit-animation: growhorizontal 0.8s infinite ease;
    animation: growhorizontal 0.8s infinite ease;
  }
}

@mixin progressGrey {
  background-color: $control-grey;
  position: relative;

  &:after {
    content: " ";
    position: absolute;
    top: 0;
    left: auto;
    bottom: 0;
    width: 0;
    right: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-animation: growhorizontal 0.8s infinite ease;
    animation: growhorizontal 0.8s infinite ease;
  }
}

@-webkit-keyframes growhorizontal {
  0% {
    width: 0;
    right: 100%;
  }
  50% {
    width: 100%;
    right: 0;
  }
  100% {
    width: 0;
    right: 0;
  }
}

@keyframes growhorizontal {
  0% {
    width: 0;
    right: 100%;
  }
  50% {
    width: 100%;
    right: 0;
  }
  100% {
    width: 0;
    right: 0;
  }
}
