.layout-highlightlist {

    .dropdown {

    	display: block;

    	.handle {
    		width: 270px;
    		box-sizing: border-box;
    		border-radius: 6px 6px 0 0;
    		float: none;
    	}
    }
}
