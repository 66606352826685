@import "./../../../../scss/colors.scss";
@import "./../../../../scss/typography.scss";
@import "./../../../../scss/mixins/linkpseudos.scss";
@import "./../../../../scss/responsive.scss";

$another-light-grey: #f3f3f4;

article.feed-card {
  @include fontSansRegular;
  background-color: white;
  display: flex;
  box-sizing: border-box;
  position: relative;
  padding: 21px 14px 27px 9px;
  border-radius: 6px;
  margin: 0 4px 3px 4px;

  &.channel {
    .image {
      border-radius: 6px;
    }
  }

  article.post-list-card:first-child {
    border-top: none;
  }

  .avatar-link {
    flex: 0 0 auto;
    line-height: 52px;
    height: 52px;
    cursor: pointer;
    margin-right: 13px;
    text-decoration: none;

    .image,
    .channel-avatar {
      flex: 0 0 100px;
      border-radius: 50%;
      height: 52px;
      width: 52px;
      max-height: 52px;
      max-width: 52px;
      box-sizing: border-box;
      cursor: pointer;
    }
    .channel-avatar {
      border-radius: 6px;
      line-height: 52px;
      font-size: 24px;
      position: relative;
      text-align: center;
      text-transform: capitalize;
      text-decoration: none;
    }
  }

  & > .content {
    display: block;
    align-items: flex-start;
    width: 100%;
    min-height: 65px;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    position: relative;
    cursor: pointer;
    max-width: calc(100% - 70px);

    a {
      text-decoration: none;
    }

    p {
      display: block;
      line-height: 24px;
      @include fontSansRegular;
      font-size: 16px;
      margin: 0;
    } // p

    .row {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      h1 {
        width: auto;
        flex: 0 1 auto;
        line-height: 24px;
        box-sizing: border-box;
        height: auto;
        margin: 0;
        font-size: 16px;
        @include fontSansBlack;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        a {
          color: $text-black;
          cursor: pointer;
          @include linkpseudos {
            color: $text-black;
            text-decoration: none;
          }
        }
      } // h1

      .actions {
        flex: 0 0 auto;
        margin: 0 0 0 auto;
        height: auto;

        .reputation-stars {
          height: 13px;
          width: 39px;
          margin-right: 4px;
          canvas {
            height: 13px;
            width: 39px;
          }
        }
      } // .actions
    } // .row

    .endorsement {
      color: var(--highlight-color);
      // font-size: 16px;
      // line-height: 24px;
      @include fontSansItalic;
      text-transform: capitalize;
    }

    .endorsement-comment {
      @include fontSansItalic;
    }

    .stats {
      font-size: 13px;
      line-height: 22px;
      @include fontSansRegular;
      display: block;
      margin-top: 2px;
      color: var(--highlight-color);

      em {
        @include fontSansRegular;
      }
      span {
        &:before {
          font-family: sans-serif;
          content: "·";
          padding: 0 3px;
          line-height: 22px;
        }
        &:first-child:before {
          content: "";
          display: none;
        }
      }
    } // .stats

    .follow-button-container {
      display: inline-flex;
      margin-top: 5px;
    }
  } // .content
}

// medium styles
@media only screen and (min-width: $mobile-width) {
  article.feed-card {
    padding: 17px 40px 23px 23px;
    margin: 0 0 3px 0;

    .avatar-link {
      margin-right: 24px;
    } // .avatar-link

    & > .content {
      .row {
        .actions {
          .reputation-stars {
            height: 15px;
            width: 48px;
            canvas {
              height: 15px;
              width: 48px;
            }
          }
        }
      } // .row
    } // .content
  } // .feed-card
} // end of medium styles
