@import "./../../../../scss/colors.scss";
@import "./../../../../scss/typography.scss";
@import "./../../../../scss/mixins/linkpseudos.scss";

.embed-thumb {
  background-color: var(--highlight-color);
  transition: background-color 200ms ease-in-out;
  @include fontSansRegular;
  color: white;

  &.no-image {
    padding-top: 30px;
  }

  .info {
    color: white;
  }

  h4 {
    color: white;
    margin: 0 24px 0 16px;
    padding: 8px 0 0 0;
    font-size: 14px;
    line-height: 20px;
    @include fontSansRegular;

    a {
      cursor: pointer;
      @include linkpseudos {
        color: white;
      }
      &:hover {
        color: white;
      }
    }
  }

  p {
    margin: 0 24px 0 16px;
  }

  .link {
    padding: 0 0 16px 0;
    font-size: 14px;
    line-height: 20px;
    color: white;
    @include fontSansSemiBold;

    a {
      cursor: pointer;
      @include linkpseudos {
        color: white;
      }
    }
  }
}
