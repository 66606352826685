@import "./../../../../scss/colors.scss";
@import "./../../../../scss/typography.scss";
@import "./../../../../scss/mixins/linkpseudos.scss";

.layout-overlay.overlay-sidebar .overlay {
  .sidebar.post-report-container {
    .toolbar.post-report-toolbar {
      flex: 0 0 auto;
      height: auto;
      display: block;

      &:before {
        content: "";
        display: none;
      }

      .row {
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
        line-height: 28px;
        font-size: 16px;
        margin: 0 15px;
        @include fontSansRegular;
        border-bottom: none;

        &:first-child {
          height: 66px;
          margin: 0;
        }

        &.title {
          height: auto;
          // margin: 0 15px 15px 15px;
          @include fontSansBold;
          font-size: 24px;
          line-height: 36px;
        }

        &.help {
          font-size: 14px;
        }

        a {
          color: var(--highlight-color);
          &:active,
          &:visited,
          &:hover {
            color: var(--highlight-color);
          }
        }
      }
    } // .toolbar.post-report-toolbar

    .controls {
      .postreport-buttons {
        margin-left: -3px;
        margin-right: -3px;

        button {
          .label {
            @include fontSansBold;
            font-size: 11px;
          }
        }
      }
    } // .controls
  } // .sidebar.post-report-container
} // .layout-overlay.overlay-sidebar .overlay
