@import "./../../../../scss/colors.scss";
@import "./../../../../scss/typography.scss";
@import "./../../../../scss/responsive.scss";

$toggle-dark: $lighter-grey;
$toggle-light: white;

.post-card {
  &.embed,
  &.image {
    .post-cardbreadcrumbs {
      &:not(.expanded) {
        .toggle {
          position: absolute;
          top: 12px;
          left: 14px;
          margin: 0;
          color: white;
          opacity: 0.5;
          .count,
          &:before,
          &:after {
            color: white;
            border-color: white;
          }
        }
      }
    }
  }
}

.post-cardbreadcrumbs {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  @include fontSansRegular;
  z-index: 22;
  font-size: 14px;
  line-height: 22px;

  &.expanded {
    display: flex;
    margin: 0;
    justify-content: flex-start;
    align-items: center;
    border-radius: 8px 8px 0 0;
    overflow: hidden;

    .toggle {
      flex: 0 0 auto;
      // position: absolute;
      // left: 50%;
      // transform: translateX(-12px);
      margin: 0 24px 0 20px;
    }
  }

  .toggle {
    width: 24px;
    height: auto;
    color: var(--highlight-color);
    cursor: pointer;
    user-select: none;
    z-index: 12;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 24px 12px 20px;

    .count {
      border: 3px solid $lighter-grey;
      border-radius: 50%;
      text-align: center;
      width: 19px;
      height: 19px;
      line-height: 19px;
      font-size: 12px;
      color: var(--highlight-color);
      flex: 0 0 auto;
      order: 1;
    }

    &.show:before {
      content: " ";
      background-color: $lighter-grey;
      border-radius: 50%;
      height: 14px;
      width: 14px;
      flex: 0 0 auto;
      order: 3;
    }

    &.show:after {
      content: " ";
      background-color: $lighter-grey;
      height: 6px;
      width: 3px;
      flex: 0 0 auto;
      order: 2;
    }
  }
  .breadcrumbs-headline {
  }

  &.expanded {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    overflow: visible;

    .breadcrumbs-headline {
      background-color: white;
      border-radius: 8px 8px 0 0;
      .toggle {
        color: $toggle-dark;
      }
    }

    .breadcrumbs-list {
      width: 100%;
    }
  }

  .breadcrumb {
    padding: 16px 24px 16px 20px;
    height: auto;
    border-top: 2px solid $toggle-dark;
    background-color: $card-white;
    line-height: 46px;
    cursor: pointer;
    user-select: none;
    color: $text-black;
    position: relative;

    &:before,
    &:after {
      position: absolute;
      top: auto;
      bottom: -8px;
      left: 50%;
      transform: translateX(-14px);
      width: 0;
      height: 0;
      border-width: 8px 14px 0 14px;
      border-style: solid;
      border-color: $toggle-dark transparent transparent transparent;
      content: " ";
      z-index: 9;
    }

    &:after {
      bottom: -6px;
      left: 50%;
      transform: translateX(-11px);
      border-width: 6px 11px 0 11px;
      border-color: $card-white transparent transparent transparent;
      z-index: 10;
    }

    &.first {
      padding: 0 24px 16px 20px;
      border-top: none;
    }
    &:last-child {
      padding: 16px 24px 16px 20px;
      border-bottom: 2px solid $toggle-dark;
      &.first {
        padding-top: 0;
      }
    }
    &:hover {
      color: $text-black;
    }
    .author {
      display: block;
      margin: 0;
      @include fontSansBold;
      line-height: 22px;
      color: var(--highlight-color);
    }
    .title {
      display: block;
      margin: 0;
      line-height: 22px;
    }
  }
}

@media only screen and (max-width: $mobile-width) {
  .post-cardbreadcrumbs {
    .toggle {
      margin-left: 12px;
    }

    &.expanded {
      border-radius: 0;

      .breadcrumbs-headline {
        border-radius: 0;
      }
      .toggle {
        margin-left: 12px;
      }
    }

    .breadcrumb {
      padding-left: 12px;
      padding-right: 20px;
      &.first {
        padding-left: 12px;
        padding-right: 20px;
      }
      &:last-child {
        padding-left: 12px;
        padding-right: 20px;
      }
    }
  }
}
