@import './../../../../scss/colors.scss';
@import './../../../../scss/typography.scss';

.tag-editor {
	display: block;
	line-height: 34px;

	.tag {
		display: inline-block;
		position: relative;
		// background-color: $canvas-grey;
		background-color: transparent;
		border: 1px solid $canvas-grey;
		padding: 4px 12px 4px 12px;
		color: $text-grey;
		border-radius: 4px;
		margin-right: 5px;
		line-height: 1em;
		cursor: pointer;
		font-size: 17px;
        @include fontSansRegular;
		transition: padding 200ms ease 0s;

		&:last-child {
			margin-right: 10px;
		}

		.label {
			display: inline-block;
			vertical-align: middle;
			padding: 3px 10px 5px 10px;
            color: var(--highlight-color);
            @include fontSansBold;
            &:before {
                content: "#";
            }
		}
		.remove {
			display: inline-block;
			position: absolute;
			top: 4px;
			right: 4px;
			opacity: 0;
			vertical-align: middle;
			transition: opacity 200ms ease 0s;
			.icon {
				height: 22px;
				width: 22px;
			}
		}
		&:hover {
			padding: 4px 20px 4px 4px;
			background-color: color($card-white shade(2%));
			.remove {
				opacity: 1;
			}
		}
	}

	.newTag {
		display: inline-block;
		position: relative;
        @include fontSansBold;
		line-height: 1;
		padding: 8px 0;
		height: 32px;
		font-size: 17px;
        color: var(--highlight-color);

		[contenteditable='true'] {
			line-height: 1;
			height: 16px;
			line-height: 17px;
            color: var(--highlight-color);

			&:empty:before {
				content: attr(data-placeholder);
				display: block;
				cursor: text;
                color: var(--highlight-color);
			}
		}

		&:after {
			display: none;
		}
	}

}
