// @import './../../../../scss/colors.scss';
@import './../../../../scss/responsive.scss';
@import './../../../../scss/mixins/linkpseudos.scss';
@import './../../../../scss/typography.scss';

.layout-alerts {
  background-color: transparent;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
  display: flex;
	align-items: center;
	z-index: 99250;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
  flex-direction: column;

  & > span {
    background-color: transparent;
    position: relative;
    height: auto;
    display: flex;
  	align-items: center;
  	z-index: 99250;
  	box-sizing: border-box;
  	display: flex;
  	justify-content: center;
    flex-direction: column;
    width: 100%;
  }

  .layout-alert-wrapper {
    background-color: white;
    flex: 1 1 auto;
    width: 100%;
    border-top: 1px solid #efefef;
    background-clip: padding-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    &:first-child {
      border-top: 4px solid rgba(0, 0, 0, 0.07);
    }

  }

  .layout-alert-content {
		position: relative;
		max-width: min(718px, 100vw);
		margin: 0;
		display: flex;
    flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		flex: 1 1 100%;
    color: var(--highlight-color);
    padding: 15px 13px;
    box-sizing: border-box;
    height: 50px;
    width: 718px;
    @include fontSansBold;

    &:before {
      content: " ";
      display: list-item;
      list-style-type: circle;
      list-style-position: inside;
      flex: 0 0 auto;
      font-size: 13px;
      line-height: 22px;
    }

    p, & > div {
      flex: 1 1 auto;
      @include fontSansBold;
      font-size: 13px;
      line-height: 22px;
      height: 22px;
      padding-top: 4px;
      margin: 0;
      text-align: center;
      color: var(--highlight-color);
      text-align: left;

      a {
        @include fontSansBold;
        color: var(--highlight-color);
        text-decoration: underline;
        @include linkpseudos {
          color: var(--highlight-color);
          text-decoration: underline;
        }      
      }
    }

    .icon {
      flex: 0 0 auto;
      margin-left: auto;
      color: var(--highlight-color);
      height: 24px;
      width: 24px;
      cursor: pointer;
    }

  }
}
