@import './colors.scss';
@import './typography.scss';

// forms from foundation 6 but modified to use my mixins, colours etc.

/* Forms
     ========================================================================== */
/**
   * Known limitation: by default, Chrome and Safari on OS X allow very limited
   * styling of `select`, unless a `border` property is set.
   */
/**
   * 1. Correct color not being inherited.
   *    Known issue: affects color of disabled elements.
   * 2. Correct font properties not being inherited.
   * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
   */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */ }

/**
   * Address `overflow` set to `hidden` in IE 8/9/10/11.
   */
button {
  overflow: visible; }

/**
   * Address inconsistent `text-transform` inheritance for `button` and `select`.
   * All other form control elements do not inherit `text-transform` values.
   * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
   * Correct `select` style inheritance in Firefox.
   */
button,
select {
  text-transform: none; }

/**
   * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
   *    and `video` controls.
   * 2. Correct inability to style clickable `input` types in iOS.
   * 3. Improve usability and consistency of cursor style between image-type
   *    `input` and others.
   */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */ }

/**
   * Re-set default cursor for disabled elements.
   */
button[disabled],
html input[disabled] {
  cursor: not-allowed; }

/**
   * Remove inner padding and border in Firefox 4+.
   */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
   * Address Firefox 4+ setting `line-height` on `input` using `!important` in
   * the UA stylesheet.
   */
input {
  line-height: normal; }

/**
   * It's recommended that you don't attempt to style these elements.
   * Firefox's implementation doesn't respect box-sizing, padding, or width.
   *
   * 1. Address box sizing set to `content-box` in IE 8/9/10.
   * 2. Remove excess padding in IE 8/9/10.
   */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
   * Fix the cursor style for Chrome's increment/decrement buttons. For certain
   * `font-size` values of the `input`, it causes the cursor style of the
   * decrement button to change from `default` to `text`.
   */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
   * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
   * 2. Address `box-sizing` set to `border-box` in Safari and Chrome.
   */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  box-sizing: content-box;
  /* 2 */ }

/**
   * Remove inner padding and search cancel button in Safari and Chrome on OS X.
   * Safari (but not Chrome) clips the cancel button when the search input has
   * padding (and `textfield` appearance).
   */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
   * Define consistent border, margin, and padding.
   * [NOTE] We don't enable this ruleset in Foundation, because we want the <fieldset> element to have plain styling.
   */
/* fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
  } */
/**
   * 1. Correct `color` not being inherited in IE 8/9/10/11.
   * 2. Remove padding so people aren't caught out if they zero out fieldsets.
   */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
   * Remove default vertical scrollbar in IE 8/9/10/11.
   */
textarea {
  overflow: auto; }

/**
   * Don't inherit the `font-weight` (applied by a rule above).
   * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
   */
optgroup {
  font-weight: bold; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 3px; }

select {
  width: 100%;
  border-radius: 3px; }

button {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  padding: 0;
  border: 0;
  border-radius: 3px;
  line-height: 1; }
  [data-whatinput='mouse'] button {
    outline: 0;}

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  padding: 0.5rem;
  border: 1px solid #cacaca;
  margin: 0 0 1rem;
  font-family: inherit;
  font-size: 1.2rem;
  color: #0a0a0a;
  background-color: #fefefe;
  box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none; }
  [type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
  textarea:focus {
    background-color: #fefefe;
    outline: none;
    box-shadow: 0 0 5px rgba(200, 200, 200, 0.2);
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

textarea {
  max-width: 100%; }
  textarea[rows] {
    height: auto; }

input, textarea {
  &::placeholder {
    color: $placeholder-grey;
  };
}

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #e6e6e6;
  cursor: not-allowed; }

[type='submit'],
[type='button'] {
  border-radius: 3px;
  -webkit-appearance: none;
  -moz-appearance: none; }

input[type='search'] {
  box-sizing: border-box; }

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1rem; }

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0;
  vertical-align: baseline; }
  [type='checkbox'] + label[for],
  [type='radio'] + label[for] {
    cursor: pointer; }

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.5rem; }

[type='file'] {
  width: 100%; }

label {
  display: block;
  margin: 0;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.8;
  color: #0a0a0a; }

select {
  height: 2.4375rem;
  padding: 0.5rem;
  border: 1px solid #cacaca;
  margin: 0 0 1rem;
  font-size: 1rem;
  font-family: inherit;
  line-height: normal;
  color: #0a0a0a;
  background-color: #fefefe;
  border-radius: 3px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  background-size: 9px 6px;
  background-position: right -1rem center;
  background-origin: content-box;
  background-repeat: no-repeat;
  padding-right: 1.5rem; }
  @media screen and (min-width: 0\0) {
    select {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg=="); } }
  select:disabled {
    background-color: #e6e6e6;
    cursor: not-allowed; }
  select::-ms-expand {
    display: none; }
  select[multiple] {
    height: auto;
    background-image: none; }

.button {
  display: inline-block;
  text-align: center;
  line-height: 1;
  cursor: pointer;
  -webkit-appearance: none;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  vertical-align: middle;
  border: 1px solid transparent;
  border-radius: 3px;
  padding: 0.85em 1em;
  margin: 0 0 0 0;
  font-size: 0.9rem;
  background-color: #cccccc;
  color: #fefefe; }
  [data-whatinput='mouse'] .button {
    outline: 0; }
  .button:hover, .button:focus {
    background-color: #b9b9b9;
    color: #fefefe; }
  .button.tiny {
    font-size: 0.6rem; }
  .button.small {
    font-size: 0.75rem; }
  .button.large {
    font-size: 1.25rem; }

  .button.dropdown::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: inset 0.4em;
    border-color: #fefefe transparent transparent;
    border-top-style: solid;
    border-bottom-width: 0;
    position: relative;
    top: 0.4em;
    float: right;
    margin-left: 1em;
    display: inline-block; }
  .button.arrow-only::after {
    margin-left: 0;
    float: none;
    top: -0.1em; }
