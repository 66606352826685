@import './../../../../scss/colors.scss';
@import './../../../../scss/responsive.scss';

.user-profilemenu {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding-right: 12px;

	& > div {
		flex: 0 0 auto;
		margin-right: 22px;
		display: inline-block;
		height: 28px;
		width: 28px;
		position: relative;
    .image {
        margin-top: 3px;
				max-height: 22px;
				max-width: 22px;
    }
		&:last-child {
			margin-right: 0;
		}
	}

	.icon {
		color: white;
		display: inline-block;
		height: 28px;
		width: 28px;
	}
}
// special case for icons in some palces
.channelheader .user-profilemenu,
.userheader .user-profilemenu {
	.icon {
		color: white;
	}
}

@media only screen and (min-width: $mobile-width) {

	.user-profilemenu {

		& > div {
			height: 32px;
			width: 32px;

			.image {
	        margin-top: 3px;
					max-height: 26px;
					max-width: 26px;
	    }
		}

		.icon {
			height: 32px;
			width: 32px;
		}
	}
}
