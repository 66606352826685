.factcheck-button {

  user-select: none;
  
  .icon {

    .icon-unticked {
      transform: translateX(-2px);
    }
  }
}
