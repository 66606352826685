@import "./../../../../scss/colors.scss";
@import "./../../../../scss/typography.scss";
@import "./../../../../scss/mixins/linkpseudos.scss";
@import "./../../../../scss/responsive.scss";

$fixedHeaderHeight: 66px;

$post-show-font-size: 15px;
$post-show-line-height: 22px;

$post-show-font-size-medium: 17px;
$post-show-line-height-medium: 27px;

.post-show {
  &.v1 article .post-show-content .post-content {
    @include fontSansRegular;
    p,
    h1 p,
    h2 p,
    h3 p {
      font-size: 16px;
      line-height: 26px;
      margin: 0 0 1em 0;
      font-weight: 400;
    }
    .media {
      margin: 0 -12px 0 -12px;
      width: calc(100% + 24px);
      max-width: calc(100% + 24px);
      iframe {
        width: 100%;
        max-width: 100%;
        height: 60vw;
        max-height: 66vh;
        overflow: hidden;
      }
    }
  }
  // v1 content styles

  font-size: $post-show-font-size;
  line-height: $post-show-line-height;

  .addthis_toolbox {
    margin-right: auto;
    position: relative;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: nowrap;
    white-space: nowrap;

    .custom_images {
      position: relative;
      left: -3px;
      .icon {
        margin-left: 0;
      }
    }
    .icon {
      margin-left: 9px;
      height: 34px;
      width: 34px;
    }
    .native-share {
      display: inline;
      color: var(--highlight-color);
    }
    .addthis_button_twitter {
      color: #00aced;
    }
    .addthis_button_facebook {
      color: #3b5998;
    }
    .addthis_button_more {
      color: var(--highlight-color);
    }
  } // .addthis_toolbox

  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    color: $text-black;
    margin-top: 10px;

    .activity-sum {
      cursor: pointer;
      font-size: 15px;
      line-height: 36px;
      padding-right: 15px;
      position: relative;
      margin-right: 7px;
      cursor: pointer;

      &:after {
        content: " ";
        position: absolute;
        top: 5px;
        right: 0;
        background-color: $lighter-grey;
        width: 3px;
        height: 25px;
      }
    }
    .activity-button {
      .button {
        cursor: pointer;
        color: $text-black;
      }
      &.set .button {
        color: var(--highlight-color);
      }
    }

    & > * {
      flex: 0 0 auto;
      margin-left: 0;
      height: 32px;
      // border: 1px dotted red;
      .button {
        border: none;
        margin-bottom: 0;
      }

      .activity-icon-upvote,
      .activity-icon-downvote {
        height: 32px;
        width: 32px;
      }
      .activity-icon-downvote {
        margin-left: 2px;
      }
      .icon {
        height: 32px;
        width: 32px;
      }
    }

    .custom_images {
      .icon {
        margin-left: 0;
      }
    }
    .icon {
      height: 32px;
      width: 32px;
      margin-left: 2px;
      cursor: pointer;

      &.post-menu-icon {
        margin-left: 0;
      }
    }

    // .generic-dropup {
    //     margin-left: 3px;
    //
    //     .handle {
    //         display: flex;
    //         align-items: center;
    //         .icon {
    //             margin-left: 0;
    //         }
    //     }
    // }

    // activity at this level so the styles also carry-through to the footer
    .activity {
      font-size: $post-show-font-size;
      line-height: 36px;
      @include fontSansRegular;

      .activity-sum {
        display: inline;
        position: relative;
        padding: 3px 15px 3px 0;

        &.activity-sum-0 {
          display: none;
        }

        &:after {
          // font-family: sans-serif;
          // content: "·";
          // padding: 0 4px 0 22px;
          // line-height: 40px;
          content: " ";
          position: absolute;
          top: 0;
          right: 0;
          width: 3px;
          bottom: 0;
          background: $canvas-grey;
        }
      }
    } // .activity
  } // .actions

  .article-container {
    .post-breadcrumbs {
      .breadcrumb .post-content-card {
        margin-bottom: 0;
      }
      .post-content-card article .card-content {
        grid-template-areas:
          ". . . . ."
          ". avatar . byline-content ."
          ". .      . content ."
          ". next-button . . ."
          ". responses-button . reply-button .";
      }
    }
  }

  .post-breadcrumbs + article {
    &:before {
      content: " ";
      position: absolute;
      top: -10px;
      left: 16px;
      height: 40px;
      width: 3px;
      background-color: $canvas-grey;
    }
  } // ..post-breadcrumbs + article

  &.content-blocks-0 > .article-container > article {
    .post-mediagallery {
      &:empty {
        display: none;

        &:after {
          content: "";
          display: none;
        }
      } // .post-mediagallery:empty
    }
  }

  & > .article-container > article {
    margin: 10px 12px;
    box-sizing: border-box;
    position: relative;

    .post-breadcrumbs {
      margin: 0 -12px 0 -12px;
    }

    .byline {
      display: flex;
      margin: 0;
      padding: 6px 0 0 0;
      line-height: $post-show-line-height;
      font-size: $post-show-font-size;

      align-items: flex-start;
      justify-content: flex-start;

      .avatar {
        transform: translateX(
          -2px
        ); // adjust for border - dealth with by grid elsewhere
        margin-top: 2px;
        flex: 0 1 38px;
        height: 38px;
        width: 38px; // includes below border
        .image {
          max-height: 34px;
          max-width: 34px;
          border: 2px solid white;
        }
      } // .avatar-link

      .info {
        flex: 1 1 auto;
        margin-left: 12px;

        .name-container {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;

          .name {
            flex: 0 1 auto;
            @include fontSansBlack;
            line-height: $post-show-line-height;
            cursor: pointer;
            a {
              color: var(--highlight-color);
              text-decoration: none;
              @include linkpseudos {
                text-decoration: none;
                color: var(--highlight-color);
              }
            }
          }

          .follow-button-container {
            margin: 1px 5px;
            flex: 0 0 auto;
          }
          .reputation-generic-overview {
            margin: 1px 5px 0 auto;
            flex: 0 0 auto;
          }

          .reputation-post-overview {
            margin-left: auto;
            .reputation-stars {
              cursor: pointer;
            }
            & > .reputation-stars {
              margin-top: 2px;
              margin-bottom: 2px;
              height: 18px;
              line-height: 18px;
              font-size: 12px;
              & > canvas {
                height: 18px;
                width: 54px;
              }
            }
          } // .reputation-post-overview
        } // .name-container

        .author-tags {
          color: var(--highlight-color);
          @include fontSansBlack;
          font-size: 11px;
          line-height: 19px;
          text-transform: uppercase;
        }

        .subheader {
          @include fontSansItalic;
          color: $text-grey;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          line-clamp: 2;
        }
      } // .info
    } // .byline

    .tag-list {
      a {
        line-height: 26px;
        font-size: 18px;
      }
    }

    h1 {
      @include fontSansBlack;
      font-size: 28px;
      line-height: 40px;
      margin: 0 0 12px 0;
    } // h1

    .comment {
      @include fontSansRegular;
      font-size: 28px;
      line-height: 40px;
      margin: 0 0 12px 0;
    }

    .tagline {
      @include fontSansRegular;
      line-height: 28px;
      font-size: 18px;
      margin: 0 0 12px 0;
    }

    time {
      @include fontSansBold;
      line-height: calc($post-show-line-height - 1px);
      font-size: calc($post-show-font-size - 1px);
      margin: 0;
    }

    .post-contentsummary {
      @include fontSansRegular;
      line-height: calc($post-show-line-height - 1px);
      font-size: calc($post-show-font-size - 1px);
      margin: 0;
    }

    .post-mediagallery {
      @include fontSansRegular;
      line-height: calc($post-show-line-height + 3px);
      font-size: calc($post-show-font-size + 3px);
      margin: 9px 0 18px 0;

      &:empty {
        position: relative;
        margin: 0;
        height: 30px;

        &:after {
          content: " ";
          display: block;
          background-color: $lighter-grey;
          height: 3px;
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
        }
      } // .post-mediagallery:empty

      .thumbnails {
        margin: 0 -12px;
      }
      .content {
        overflow: visible !important;
        & > .image,
        & > .embed {
          min-width: calc(100% + 24px);
          max-width: calc(100% + 24px);
          margin: 0 -12px;
        }
      }
    } // .post-mediagallery

    hr {
      height: 3px;
      background-color: $lighter-grey;
      display: block;
      border: none;
      margin: 15px 0 35px 0;
      padding: 0;
    }

    .post-show-content {
      @include fontSerifRegular;
      line-height: 26px;
      font-size: 16px;

      .newsletter-promo {
        font-style: italic;
        margin-bottom: 2em;
        color: var(--highlight-color);
        cursor: pointer;
        text-decoration: none;
        display: block;
        &:hover {
          color: var(--highlight-color);
          cursor: pointer;
          text-decoration: none;
        }
      }

      .dummycontent {
        padding-top: 10px;
      }

      .editor-richtext .content {
        blockquote.block {
          padding: 0 20px;
          border: none;
          color: $text-black;
          font-style: italic;
        }

        .block {
          @include fontSerifRegular;
          margin: 0 0 1em 0;
          padding: 0;
          line-height: 26px;
          font-size: 16px;

          &:after {
            content: "";
            display: none;
          }

          &.upload-block {
            .image {
              margin: 0 -12px 0 -12px;
              min-width: calc(100% + 24px);
              max-width: calc(100% + 24px);
            }
          }

          &.embed-block {
            .embed {
              margin: 0 -12px 0 -12px;
              width: calc(100% + 24px);
              max-width: calc(100% + 24px);
              iframe {
                width: 100%;
                max-width: 100%;
                // height: 60vw;
                max-height: 50vh;
                overflow: hidden;
                // height: auto;
                // max-height: none;
              }
            }
          } // &.embed-block

          &.twitter-block {
            .tweet {
              overflow: hidden;
              .twitter-tweet {
                min-width: 100px !important;
              }
            }
          }
        } // .block

        h2.block {
          @include fontSerifBold;
          line-height: 26px;
          font-size: 20px;
        }
      }
    } // .post-show-content

    .embed-thumb {
      .info {
        padding: 1em 11px;
        @include fontSansRegular;
        line-height: 28px;
        font-size: 18px;

        a {
          color: white;
          text-decoration: none;
          @include linkpseudos {
            color: white;
            text-decoration: none;
          }
          &:hover {
            text-decoration: underline;
          }
        }

        h4 {
          @include fontSansBlack;
          line-height: 24px;
          font-size: 16px;
          margin: 0;
          padding: 0;
        }
        p {
          @include fontSansRegular;
          line-height: 1.6em;
          font-size: 14px;
          margin: 0;
          padding: 0;
          &.link {
            margin: 0;
          }
        }
      }
    } // .embed-thumb
    .thumbnail .embed-thumb .info {
      padding: 0;
    }

    .react-title {
      @include fontSansBold;
      // line-height: $post-show-line-height;
      // font-size: $post-show-font-size;
      font-size: 12px;
      line-height: 20px;
      margin: 40px 0 4px 0;
      color: var(--highlight-color);
      text-align: center;
      text-transform: uppercase;
    }

    .reply-prompt {
      max-width: calc(
        768px - 4px
      ) !important; // take ino account margin on react buttons
      margin: 3px 1px 20px 1px;
      @include fontSansRegular;
      line-height: 44px;
      font-size: 16px;
      height: 80px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border: 3px solid $lighter-grey;
      padding: 0 10px;
      border-radius: 6px;
      cursor: text;
      box-sizing: border-box;

      .icon {
        height: 22px;
        width: 22px;
        flex: 0 0 auto;
      }

      .prompt {
        flex: 1 0 auto;
      }

      &.reply-prompt-readonly {
        color: $light-grey;
        .icon, .prompt {
          color: $light-grey;
        }
      }
    }

    .door-policy-summary-container {
      width: 100%;
      padding: 0;
      // background-color: #f9f9f9;
      margin: 0;
      transform: translateY(-14px);
    }
    .door-policy-summary {
      // width: calc($tr-editor-max-width - 22px);
      margin: 0 auto;
      max-width: 100%;
      padding: 0;
      @include fontSansBold;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      .icon {
        flex: 0 0 auto;
        width: 32px;
        height: 32px;
        padding: 0 3px;
      }
      p {
        flex: 1 0 auto;
        margin-left: 11px;
      }
      canvas {
        width: 54px;
        height: 14px;
      }
    }
  } // article

  & > .responses-button-container {
    position: fixed;
    height: 34px;
    width: 34px;
    bottom: 34px;
    left: 12px;
    z-index: 2001;
    .responses-button {
      background-color: var(--highlight-color);
      color: white;
      // box-shadow: 0 0 0 3px white;
      box-shadow: none;
      height: 34px;
      width: 34px;
      line-height: 34px;
      @include fontSansBlack;
      font-size: 14px;
      span {
        color: white;
        max-width: 34px;
      }
    }
    .icon {
      display: block;
      color: var(--highlight-color);
      bottom: -14px;
      transform: translateX(-12px);
      height: 24px;
      width: 24px;
    }
  } // & > .responses-button-container

  &.scroll-wall > .responses-button-container {
    display: none;
  }

  .post-show-responses {
    & > .wall {
      & > .list,
      & > .grid {
        &:empty {
          min-height: 100vh;
        }
      }
      & > .list {
        // background-color: white;
      }

      & > .grid {
        margin-top: 40px;
        .ancestor-title {
          display: none;
        }
      }
    }
  } // .post-show-responses

  .toolbar {
    display: flex;
    position: fixed;
    // transform: translateY(-$fixedHeaderHeight);
    transform: translateY(-160px);
    transition: transform 300ms ease;
    top: 0;
    left: 0;
    right: 0;
    // height: $fixedHeaderHeight;
    height: auto;
    z-index: 20051;
    background-color: white;
    color: $text-black;
    position: fixed;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;

    .toolbar-content {
      display: flex;
      flex-direction: row;
      max-width: 100%;
      width: 1040px;
      margin: 0;
      align-items: center;
      // justify-content: flex-start;
      justify-content: center;
      flex: 1 1 100%;
      padding: 0 12px;

      .addthis_toolbox {
        margin-left: 10px;
      }

      .reactions {
        margin-left: auto;
        &.open {
          margin-left: 0;
          width: calc(100% - 22px);
          padding-bottom: 10px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          & > .reaction-buttons-wrapper {
            flex: 1 0 auto;
          }
        }
        .totals {
          right: 23px;
        }
        .reaction-summary {
          // padding: 0 11px 11px 0;
          // box-sizing: border-box;
          display: none;
        }
      }
    }

    .title-container {
      flex: 1 0 auto;
      height: $fixedHeaderHeight;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .title {
      display: none;
      flex: 1 1 auto;
    }

    .actions {
      flex: 0 1 auto;
      margin-top: 0;
      &:first-child {
        width: 70px;
        min-width: 70px;
        max-width: 70px;
      }
    }
  } //, .toolbar

  &.scroll-post .post-toolbar,
  &.scroll-wall .post-toolbar {
    transform: translateY(0);
    // box-shadow: none;
    background-clip: padding-box;
    box-shadow: 0 6px 0 rgba(0, 0, 0, 0.05);

    .reactions {
      &.open {
        margin-top: 0;
        border-top: 3px solid $lighter-grey;

        .reaction-buttons-wrapper {
          border-top: none;
        }
      }

      .reaction-buttons-wrapper {
        max-width: 718px;
      }
    }
  }

  // &.scroll-wall .wall-toolbar {
  //
  // 	transform: translateY(0);
  //     border-bottom: 6px solid rgba(0, 0, 0, 0.01);
  //     background-clip: padding-box;
  // }

  .footer {
    position: fixed;
    z-index: 10000;
    bottom: 0;
    left: 50%;
    background-color: $lighter-grey;
    transition: transform 200ms ease;
    transform: translateX(-50%) translateY(60px);
    height: 60px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px 6px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .responses-link {
      flex: 0 0 auto;
      cursor: pointer;
      margin-left: 40px;
      display: flex;

      .icon {
        flex: 0 0 36px;
        color: $text-black;
        height: 36px;
        width: 36px;
      }
      .count {
        flex: 0 0 auto;
        font-size: 20px;
        line-height: 40px;
        @include fontSansLight;
        color: $text-black;
        margin-left: 4px;
      }
    }

    .actions {
      flex: 0 0 auto;
      display: flex;
      align-items: center;

      .activity-sum {
        top: 0;
      }

      button {
        flex: 0 0 auto;
      }
    }
  }
  // .footer

  &.scroll-none .wall-footer,
  &.scroll-post .wall-footer {
    transform: translateX(-50%) translateY(60px);
    background-clip: padding-box;
  }

  &.scroll-wall .wall-footer {
    transform: translateX(-50%) translateY(0);
    background-clip: padding-box;
  }
  // end of header/footer position and style
}

@media only screen and (min-width: 1220px) {
  .post-show {
    & > .responses-button-container {
      left: calc(calc(calc(100% - 1220px) / 2) + 0px);
    }
  }
}

@media only screen and (min-width: $mobile-width) {
  .post-show {
    // v1 content styles
    &.v1 article .post-show-content .post-content {
      @include fontSansRegular;
      p,
      h1 p,
      h2 p,
      h3 p {
        @include fontSerifRegular;
        font-size: 18px;
        line-height: 33px;
      }
      .media {
        margin: 0;
        width: 100%;
        max-width: 100%;
        iframe {
          width: 100%;
          max-width: 100%;
          // height: 60vw;
          max-height: 66vh;
          overflow: hidden;
        }
      }
    }

    .actions {
      & > * {
        height: 36px;
        .activity-icon-upvote,
        .activity-icon-downvote {
          height: 36px;
          width: 36px;
        }
        .activity-icon-downvote {
          margin-left: 9px;
        }
      }
      .icon {
        height: 36px;
        width: 36px;
        margin-left: 9px;
      }
    }

    .post-breadcrumbs + article {
      padding-top: 10px !important;
      &:before {
        left: 45px;
      }
    } // ..post-breadcrumbs + article

    & > .article-container > article {
      padding: 20px 25px 10px 25px;

      .byline {
        line-height: $post-show-line-height-medium;
        font-size: $post-show-font-size-medium;
        padding-top: 0;
        margin-bottom: 20px;

        .avatar {
          flex: 0 1 44px;
          height: 44px;
          width: 44px; // includes below border
          .image {
            max-height: 40px;
            max-width: 40px;
            border: 2px solid white;
          }
        } // .avatar-link

        .info {
          margin-left: 15px;

          .name-container {
            .name {
              line-height: $post-show-line-height-medium;
              font-size: $post-show-font-size-medium;
              a {
                color: var(--highlight-color);
                @include linkpseudos {
                  color: var(--highlight-color);
                }
              }
            }
            .follow-button-container,
            .reputation-generic-overview {
              margin-top: 3px;
              margin-bottom: 4px;
            }
            .reputation-stars {
              margin-top: 4px;
              margin-bottom: 5px;
            }
          } // .name-container

          .author-tags {
            font-size: 12px;
            line-height: 21px;
          }
        } // .info
      } // .byline

      .tag-list {
        @include fontSansBold;
        a {
          font-size: 24px;
          line-height: 34px;
        }
      }

      h1 {
        @include fontSansBlack;
        font-size: 38px;
        line-height: 52px;
      }

      .tagline {
        font-size: 24px;
        line-height: 34px;
      }

      time {
        @include fontSansBold;
        font-size: 16px;
        line-height: 28px;
      }

      .post-contentsummary {
        @include fontSansRegular;
        font-size: 16px;
        line-height: 28px;
      }

      .post-mediagallery {
        margin-top: 13px;

        .thumbnails {
          margin: 0;
        }
        .content {
          overflow: hidden !important;
          & > .image,
          & > .embed {
            min-width: 100%;
            max-width: 100%;
            margin: 0;
          }
        }
      }

      .embed-thumb {
        .info {
          h4 {
            @include fontSansBlack;
            font-size: 18px;
            line-height: 30px;
          }
        }
      }

      .post-show-content {
        @include fontSerifRegular;
        font-size: 18px;
        line-height: 33px;

        .editor-richtext .content {
          .block {
            @include fontSerifRegular;
            font-size: 18px;
            line-height: 33px;

            &.upload-block {
              .image {
                margin: 0;
                min-width: 100%;
                max-width: 100%;
              }
            }

            &.embed-block {
              .embed {
                margin: 0;
                width: 100%;
                max-width: 100%;
                iframe {
                  width: 100%;
                  max-width: 100%;
                  // height: 60vw;
                  max-height: 66vh;
                  // max-height: none;
                  overflow: hidden;
                }
              }
            }
          } // .block

          h2.block {
            line-height: 30px;
            font-size: 22px;
          }
        }
      }
    } // article

    & > .responses-button-container {
      height: 40px;
      width: 40px;
      bottom: 40px;
      // left: 12px;
      // left: calc(calc(100% - min(730px, 100%)) / 2);
      left: max(0px, calc((100% - 770px) / 2));
      // transform: translateX(65px) translateY(11px);
      transform: translateX(45px) translateY(11px);

      .responses-button {
        height: 40px;
        width: 40px;
        line-height: 40px;
        font-size: 14px;
        span {
          max-width: 40px;
        }
      }
      .icon {
        bottom: -16px;
        transform: translateX(-13px);
        height: 26px;
        width: 26px;
      }
    } // & > .responses-button-container

    .toolbar {
      .toolbar-content {
        padding: 0 22px;

        .addthis_toolbox {
          min-width: 200px;
          margin-right: 0;
          // margin-left: 0;
        }

        .actions {
          min-width: 300px;
        }

        .reactions {
          .totals {
            right: 25px;
          }
        }

        .title-container {
          flex: 1 1 auto;
          .title {
            max-height: 36px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            margin: 0 20px;
            text-align: center;
            @include fontSansBlack;
            font-size: 20px;
            line-height: 32px;
          }
        }
      }
    } // .toolbar
  } // .post-show
}
