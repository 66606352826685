@import './../../../../scss/typography.scss';
@import './../../../../scss/colors.scss';
@import './../../../../scss/mixins/progress.scss';

.user-acceptinvite {

    #social_signup {
        max-width: calc(100% - 40px);
        width: 720px;
        text-align: left;
        iframe {
            width: auto;
        }
    }

    .tx2 {
        h2 {
            margin-bottom: 30px;
        }

        .field.name {
            border-top: 1px solid $lighter-grey;
        }
    }

    .toolbar {

        .stages {
            display: flex;
            margin: 0 auto;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .stage {
                flex: 0 0 36px;
                box-sizing: border-box;
                height: 36px;
                width: 36px;
                line-height: 30px;
                border-radius: 50%;
                border: 3px solid $lighter-grey;
                background-color: $lighter-grey;
                color: white;
                text-align: center;
                margin: 0 2px;

                &.active {
                    color: var(--highlight-color);
                    background-color: white;
                }
            }
        }
    }

    &.tab-username {

        .field.name,
        .field.email {
            display: none;

            &.error, &.fixederror {
                display: block;
            }
        }
    }

    &.tab-rules {

        p.actions {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            a.profile-link {
                @include fontSansBold;
                font-size: 16px;
                line-height: 30px;
                color: $text-black;
                text-align: left;
                margin-left: 30px;
                text-decoration: none;
                cursor: pointer;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    &.tab-follows {
        & > .toolbar {
            & > button {
                background-color: var(--highlight-color);
                color: white;
            
                
                &:hover {
                    background: var(--highlight-color) linear-gradient(90deg, rgba(0, 0, 0, 0.05) 0px, rgba(0, 0, 0, 0.05));        		
                }
        
                &.loading {			
                    @include progress;
                }
            }
        }
    }
}


.layout-overlay:not(.overlay-sidebar) .user-acceptinvite .toolbar {
    border-bottom: 0;
}
