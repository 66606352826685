@import './../../../../../scss/colors.scss';

.fieldset.article {

    .title-length {
        position: relative;
        display: block;
        height: 3px;
        top: -2px;
        background-color: var(--highlight-color);
    }

}
