@import './../../../../scss/colors.scss';
@import './../../../../scss/typography.scss';
@import './../../../../scss/mixins/linkpseudos.scss';
@import './../../../../scss/mixins/progress.scss';

$iconbutton_height: 30px;
$base-border-radius: 4px;

.generic-iconbutton {

	user-select: none;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	color: white;
	height: $iconbutton_height;
	line-height: $iconbutton_height;
	cursor: pointer;
	border-radius: $base-border-radius 0 0 4px;
	background-color: $text-grey;

	&:hover {
		background-color: color($canvas-grey shade(20%));
	}

}
