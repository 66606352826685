@import "./../../../../scss/colors.scss";
@import "./../../../../scss/typography.scss";

.layout-overlay.overlay-sidebar .overlay {
  .user-sidebar-reputation.sidebar {
    & > .controls {
      padding: 0;
    }
  }
}

.reputation-dashboard {
  width: 100%;

  .reputation-dashboard-title {
    @include fontSansRegular;
    font-size: 26px;
    line-height: 32px;
    font-weight: 700;
    flex: 1 1 auto;
    display: block;
    text-align: left;
    width: calc(100% - 15px);
    margin: 15px 0 0 15px;
  }
  .reputation-dashboard-subtitle {
    @include fontSansRegular;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    flex: 1 1 auto;
    text-align: left;
    width: calc(100% - 15px);
    margin: 6px 0 0 15px;
    text-transform: uppercase;
    // color: var(--highlight-color);
    color: var(--feed-highlight-color);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    div {
      flex: 1 1 auto;
    }
    div.icon {
      flex: 0 0 20px;
      height: 20px;
      width: 20px;
      margin-left: 2px;
    }
  }
  .reputation-dashboard-top {
    position: relative;
    flex: 1 1 auto;
    width: 100%;
    height: 270px;
    max-height: 270px;
  }

  .reputation-avatar {
    height: 260px;
    max-height: 260px;
    width: 260px;
    margin-left: 50%;
    margin-top: 5px;
    position: absolute;
    top: 0;
    transform: translateX(-50%);

    canvas {
      height: 260px;
      width: 260px;
    }
  }

  .reputation-dial {
    flex: 0 1 100%;
    height: 270px;
    max-height: 270px;
    width: 100%;

    canvas {
      height: 270px;
      width: 100%;
    }
  }

  .reputation-dashboard-middle {
    flex: 1 1 auto;
    width: 100%;
    height: 140px;
    max-height: 140px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .reputation-gauge {
    flex: 0 1 100px;
    height: 120px;
    max-height: 120px;
    width: 80px;
    text-align: center;
    font-family: "Lato", sans-serif;
    font-size: 14px;
    line-height: 14px;
    font-weight: 700px;
    letter-spacing: -0.26px;

    &.shield {
      color: #379de8;
    }
    &.heart {
      color: #93cc58;
    }

    canvas {
      height: 80px;
      width: 80px;
      margin: 0;
    }

    .reputation-gauge-title {
      margin: 0;
      text-transform: uppercase;
    }

    .reputation-gauge-score {
      font-size: 12px;
      line-height: 16px;
      margin: 0;
    }
  }

  .reputation-info {
    flex: 0 0 100px;
    height: 140px;
    max-height: 140px;
    width: 140px;
    min-width: 140px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 15px;

    .reputation-stars {
      width: 100px;
      height: 28px;
      text-align: center;
      margin-top: 2px;
    }

    canvas {
      width: 100px;
      height: 28px;
    }
  }

  .reputation-membership {
    height: 26px;
    line-height: 26px;
    border-radius: 13px;
    max-width: auto;
    margin: 12px 0 9px 0;
    background: var(--feed-highlight-color);
    color: white;
    text-transform: uppercase;
    padding: 0 15px;
    font-family: "Lato", sans-serif;
    font-size: 12px;
    font-weight: 700px;
    letter-spacing: -0.22px;
  }

  .reputation-score {
    color: var(--feed-highlight-color);
    font-family: "Lato", sans-serif;
    font-size: 32px;
    line-height: 36px;
    font-weight: 700;
    margin-bottom: 3px;
  }

  .reputation-change {
    font-family: "Lato", sans-serif;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
  }

  .reputation-linegraph {
    flex: 0 1 100%;
    height: 125px;
    max-height: 125px;
    width: 100%;

    canvas {
      height: 125px;
      width: 100%;
    }
  }

  .reputation-barchart {
    flex: 0 1 100%;
    height: 18px;
    max-height: 18px;
    width: 100%;

    canvas {
      height: 18px;
      width: 100%;
    }
  }

  .reputation-tags {
    display: block;
    // margin-top: 0;

    .reputation-bar {
      position: relative;
      height: 46px;
      width: calc(100% - 26px);
      margin: 3px 13px 3px 13px;
      box-sizing: border-box;

      & > canvas {
        position: absolute;
        top: 0;
        left: 0;
        height: 46px;
        width: 100%;
        z-index: 2;
      }

      .reputation-bar-children {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        height: 46px;
        position: relative;
        z-index: 3;
        box-sizing: border-box;
        padding: 0 22px 0 14px;
        font-family: "Lato", sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 46px;

        .tag {
          flex: 1 0 auto;
          text-transform: uppercase;
          &:before {
            content: "";
          }
        }
        .reputation-stars {
          position: relative;
          flex: 0 0 auto;
          height: 16px;
          width: 58px;
          & > canvas {
            display: block;
            margin: 0;
            height: 16px;
            width: 58px;
          }
        }
        .score {
          flex: 0 0 auto;
          margin-left: 5px;
          min-width: 30px;
          text-align: right;
        }
      }
    }
  }
}
