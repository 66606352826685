@import './../../../../scss/colors.scss';
@import './../../../../scss/typography.scss';
@import './../../../../scss/mixins/linkpseudos.scss';
@import './../../../../scss/responsive.scss';

.header.search {

	display: block;
	background: white;
    z-index: 1001;

    .generic-horizontalscroller .scroll-content {
        padding: 0 12px;
    }
    .generic-horizontalscroller.content-fits .scroll-content {
        padding: 0 12px;
    }

    .content {
		margin: 20px 0 0 0;
		padding: 20px 0 0 0;
		display: flex;
		@include fontSansRegular;
		line-height: 1.6em;
		align-items: flex-start;
		flex-wrap: wrap;
        flex-direction: column;
        align-items: center;

		.info {
			flex: 1 0 30%;
			margin: 0 20px 20px 20px;
			font-size: 1.6rem;
			line-height: 1.6em;
            text-align: center;
            max-width: calc(100% - 40px);

			h1 {
				margin: 0;
				@include fontSansSemiBold;
				font-size: 36px;
				line-height: 42px;
                text-align: center;
                color: var(--highlight-color);
			}
		}

        .actions-tabs-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            max-width: 100%;
            flex: 1 0 100%;
            width: 100%;
            border-bottom: 3px solid $lighter-grey;
            margin: 0 auto 10px auto;
        }

		.actions {
			flex: 0 0 auto;
            margin: 0 0 20px 0;
			display: flex;
			align-items: center;
			justify-content: center;

			& > * {
				flex: 0 0 auto;
				margin: 0 6px;
			}

			.addthis_toolbox {
                margin: 0;
                position: relative;
                flex: 0 1 auto;
                display: flex;

                .custom_images {
                    max-height: 30px;
                    overflow: hidden;
                }

                .addthis_button_more, .native-share {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 28px;
                    width: 28px;
                    border: 1px solid var(--highlight-color);
                    border-radius: 50%;
                    margin: 0;
                    padding: 0;
                }

                .icon {
                    max-height: 28px;
                    max-width: 28px;
                }
                .native-share {
                    display: flex;
                    color: var(--highlight-color);
                }
                .addthis_button_more {
                    color: var(--highlight-color);
                }
            } // .addthis_toolbox
		} // .actions
    } // .content

	.tabs {
        flex: 1 1 auto;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		margin: 0;

		a {
			flex: 0 0 auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 4px 6px 20px 6px;
            text-decoration: none;
            @include fontSansBold;
            color: $text-grey;
            position: relative;
            cursor: pointer;

            @include linkpseudos {
                text-decoration: none;
                color: $text-grey;
            }

            &.active {
                color: var(--highlight-color);

                &:before {
                    content: " ";
                    position: absolute;
                    bottom: -3px;
                    left: 50%;
                    transform: translateX(-27.5px);
                    height: 10px;
                    width: 55px;
                    background: url('/images/arrow.png');
                    background-size: 55px 10px;
                    background-position: 0px 0px;
                    background-origin: border-box;
                    background-repeat: no-repeat;
                }

                @include linkpseudos {
                    color: var(--highlight-color);
                }
                label {
                    color: var(--highlight-color);
                }
            } // &.active

            label {
                flex: 1 1 auto;
                display: block;
                order: 2;
                font-size: 12px;
                line-height: 16px;
                text-transform: uppercase;
                text-align: center;
                letter-spacing: -0.2px;
                color: $text-grey;
                @include fontSansBold;
            }
            output {
                flex: 1 1 auto;
                display: block;
                order: 1;
                font-size: 30px;
                line-height: 36px;
                text-align: center;
            }
		} // a
	} // .tabs

    .menu {
        flex: 1 1 auto;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		margin: 0;
        line-height: 22px;
        font-size: 14px;

        a {
            flex: 0 0 auto;
            display: block;
            @include fontSansBold;
            color: $text-grey;
            line-height: 22px;
            font-size: 18px;
            padding: 10px 5px 24px 5px;
            margin: 0 15px;
            cursor: pointer;

            @include linkpseudos {
                text-decoration: none;
                color: $text-grey;
            }

            &.active {
                color: var(--highlight-color);
                @include linkpseudos {
                    color: var(--highlight-color);
                }
                & > output, & > label {
                    color: var(--highlight-color);
                }
            }

            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }

            & > * {
                @include fontSansBold;
                line-height: 22px;
                font-size: 18px;
                display: inline;
                color: $text-grey;
            }

            & > output {
                @include fontSansBold;
                line-height: 22px;
                font-size: 18px;
                color: $text-grey;

                &:before {
                    content: " (";
                }
                &:after {
                    content: ")";
                }
            }
		} // a
	} // .menu

}

@media only screen and (min-width: $mobile-width) {

    .header.search {

        .generic-horizontalscroller {

            .scroll-content:first-child {
                padding-left: 20px;
            }
            .scroll-content:last-child {
                padding-right: 20px;
            }

        }

        .content {
            margin-top: 50px;

            .info {
                max-width: 1180px;
                margin-bottom: 15px;

                h1 {
                    font-size: 46px;
    				line-height: 60px;
                }

            }

            .actions-tabs-container {
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                max-width: 1220px;
                flex: 1 0 calc(100% - 40px);
                width: calc(100% - 40px);
                border-bottom: 3px solid $lighter-grey;
                .tabs {
                    flex: 1 1 auto;
                    order: 1;
                    a output {
                        font-size: 36px;
                        line-height: 42px;
                        padding-left: 16px;
                        padding-right: 16px;
                    }
                }
                .actions {
                    flex: 0 1 auto;
                    order: 2;
                    margin-left: auto;
                    margin: 0;
                }
            }
        } // .content

    }
}

@media only screen and (min-width: 1280px) {

    .header.search {

        .generic-horizontalscroller {

            .scroll-content:first-child {
                padding-left: 30px;
            }
            .scroll-content:last-child {
                padding-right: 30px;
            }

        }
    }
}
