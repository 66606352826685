@import "./../../../../scss/colors.scss";
@import "./../../../../scss/typography.scss";
@import "./../../../../scss/mixins/linkpseudos.scss";
@import "./../../../../scss/responsive.scss";

$info-line-height: 18px;
$info-font-size: 12px;

$info-line-height-medium: 22px;
$info-font-size-medium: 14px;

article.post-card {
  @include fontSansRegular;

  background-color: white;
  border-radius: 8px;

  &.deleted {
    opacity: 0.5;
  }
  &.injected {
    // border: 2px dashed #bada55;
  }

  &.show-breadcrumbs {
    .header-image,
    .content {
      opacity: 0.4;
    }
  }

  &.image-comment {
    background-color: #2d2d2d;
    .content {
      background-color: #2d2d2d;
      .comment, h1 {
        color: white;
        a {
          color: white;
        }
        a:hover,
        a:active,
        a:visited {
          color: white;
        }
      }
      .byline {
        color: white;
        .author {
          color: white;
          time {
            color: white;
          }
        }
      }
      .tags {
        color: white;
        a {
          color: white;
        }
        a:hover,
        a:active,
        a:visited {
          color: white;
        }
      }
      .ancestor-title {
        color: white;
      }
      .parent-author {
        color: white;
      }
      p {
        color: white;
      }
      .post-stats {
        color: white;
        .post-contentsummary {
          color: white;
          output {
            color: white;
          }
        }
      }
    }
  }

  &.columns-2 {
    .header-image {
      .embed,
      .embed-thumb {
        iframe {
          max-height: 331px;
        }

        .info {
          h4 {
            font-size: 20px;
            line-height: 28px;
          }
        }
      }
    }

    .content {
      h1,
      .comment {
        font-size: 20px;
        line-height: 28px;
        margin: 0 0 2px 0;
      }
    }
  }

  &.columns-4,
  &.columns-3 {
    box-sizing: border-box;
    min-height: 574px;
    padding-top: 30px;
    padding-bottom: 40px;

    &.has-breadcrumbs .header-image {
      margin-top: 0;
    }

    .header-image {
      position: absolute;
      top: 2px;
      right: 0;
      margin: 0;
      left: auto;

      .info {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: auto;
        background-color: rgba(0, 0, 0, 0.5);
        padding-top: 5px;
        color: white;
        z-index: 2; // above image fader!
        h4,
        p {
          color: white;
          a {
            color: white;
            @include linkpseudos {
              color: white;
              text-decoration: none;
            }
          }
        }

        &:before {
          content: " ";
          position: absolute;
          top: -50px;
          left: 0;
          right: 0;
          height: 50px;
          background: -moz-linear-gradient(
            top,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.5) 100%
          ); /* FF3.6-15 */
          background: -webkit-linear-gradient(
            top,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.5) 100%
          ); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.5) 100%
          ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        }
      }

      .image,
      .embed,
      .embed-thumb {
        iframe {
          max-height: 530px;
        }
      }
    }

    &.alternate-design {
      .header-image {
        left: 0;
        right: auto;
      }

      .content {
        padding-left: 36px;
      }

      // .activity-bar {
      // 	left: auto;
      // 	right: 0;
      // }
    }
  }

  a {
    @include linkpseudos {
      text-decoration: none;
    }
  }

  &.article {
    .header-image {
      .embed,
      .embed-thumb {
        .info {
          h4 {
            font-size: 14px;
            line-height: 22px;
            @include fontSansBold;
          }
        }
      }
    }
  }

  .header-image {
    z-index: 21;
    cursor: pointer;
    // margin-top: 7px;
    display: block;
    border-radius: 8px 8px 0 0;
    overflow: hidden;

    // &:after {
    //   content: " ";
    //   border-radius: 8px 8px 0 0;
    //   overflow: hidden;
    //   display: block;
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   right: 0;
    //   height: 69px;
    //   background: linear-gradient(
    //     180deg,
    //     rgba(0, 0, 0, 1) 0%,
    //     rgba(0, 0, 0, 0) 100%
    //   );
    //   opacity: 0.27;
    // }

    .image,
    .embed {
      position: relative;
      overflow: hidden;
    }
    .embed,
    .embed-thumb {
      iframe {
        max-height: 157px;
        width: 100%;
        overflow: hidden;

        .media .placeholder {
          background-size: cover;
        }
      }

      .info {
        word-wrap: break-word;
        word-break: normal;
        overflow-wrap: break-word;
        font-size: 14px;
        line-height: 22px;
        padding-bottom: 16px;

        h4 {
          font-size: 18px;
          line-height: 26px;
          @include fontSansBlack;
          margin-left: 20px;
          margin-right: 24px;
        }
        p {
          font-size: 14px;
          line-height: 22px;
          margin-left: 20px;
          margin-right: 24px;
          @include fontSansRegular;
        }
        p.link {
          padding-bottom: 0;
        }
      }
    }
  }
  &.unseen {
    &:before {
      content: " ";
      position: absolute;
      top: 15px;
      right: 10px;
      height: 5px;
      width: 5px;
      z-index: 99;
      border-radius: 50%;
      background-color: var(--highlight-color);
      box-shadow: 0 0 5px 5px rgba(255, 255, 255, 0.3);
    }
  }
  &.has-breadcrumbs {
    &.show-breadcrumbs {
      .header-image {
        opacity: 1;
      }
      .content {
        opacity: 0.4;
      }
    }
    .header-image {
      // margin-top: 12px;

      .image,
      .embed {
        max-height: 360px;

        &:before {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          display: block;
          height: 64px;
        }
      }
    }
    .content {
      padding-top: 70px;
      // padding-top: 34px;
    }
    .generic-mouseover:empty + .content {
      padding-top: 70px;
    }
  }

  .content {
    @include fontSansRegular;

    position: relative;
    padding: 4px 24px 46px 20px;
    background-color: $card-white;
    margin: 0;
    font-size: 14px;
    line-height: 22px;
    word-wrap: break-word;
    word-break: normal;
    overflow-wrap: break-word;
    cursor: pointer;
    border-radius: 8px;
    overflow: hidden;

    .link {
    }

    .reposted-by {
      @include fontSansItalic;
      margin: 0;
      display: block;
      color: var(--highlight-color);
      line-height: $info-line-height-medium;
      font-size: $info-font-size-medium;
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;

      .reposted-by-in {
        display: inline;
      }
      .reposted-by-via {
        display: inline;
      }
      .reposted-by-via + .reposted-by-in {
        &:before {
          content: " ";
        }
      }
      .name {
        display: inline;
        @include fontSansBoldItalic;
      }

      .repost-comment {
        font-style: italic;
        display: block;
        &:before {
          content: "‘";
        }
        &:after {
          content: "’";
        }
      }

      @include linkpseudos {
        color: var(--highlight-color);
      }
    }

    .parent-author {
      display: none; // shown when in responses
      color: var(--highlight-color);
      text-align: left;
      align-items: center;
      justify-content: flex-start;
      @include fontSansItalic;
      line-height: $info-line-height-medium;
      font-size: $info-font-size-medium;
    }

    .reputation-post-overview {
      height: 12px;
      line-height: 12px;
      margin: 3px auto 5px 0;
      width: fit-content;

      & > .reputation-stars {
        height: 12px;
        line-height: 12px;
        canvas {
          height: 12px;
          width: 45px;
        }
      }
    }
    .reputation-generic-overview {
      display: inline-block;
    }

    .tags {
      color: var(--highlight-color);
      @include fontSansItalic;
      line-height: $info-line-height-medium;
      font-size: $info-font-size-medium;

      a {
        @include fontSansItalic;
        @include linkpseudos {
          color: var(--highlight-color);
          text-decoration: none;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
    .ancestor-title {
      color: var(--highlight-color);
      @include fontSansItalic;
      margin: 0;
      line-height: $info-line-height-medium;
      font-size: $info-font-size-medium;
    }

    h1,
    .comment {
      &.h1 {
        @include fontSansBold;
      }

      margin: 0 0 2px 0;
      font-size: 18px;
      line-height: 26px;

      a {
        @include linkpseudos {
          color: $text-black;
          // font-size: 16px;
        }
      }
    }

    .ancestor-title {
      @include fontSansItalic;
      margin: 0;
      line-height: 22px;
      font-size: 14px;
      color: var(--highlight-color);
    }

    p {
      margin: 0;
      @include fontSansRegular;
    }
    .byline {
      margin: 0;
    }
    .author {
      @include fontSansBold;
      line-height: $info-line-height-medium;
      font-size: $info-font-size-medium;
      // display: flex;
      // justify-content: flex-start;
      // align-items: flex-start;
      cursor: pointer;
      color: var(--highlight-color);
      &:hover {
        text-decoration: none;
      }
      time {
        // display: flex;
        // flex-direction: row;
        // align-items: center;
        display: inline;
        color: var(--highlight-color);
        @include fontSansRegular;
        line-height: $info-line-height-medium;
        font-size: $info-font-size-medium;
        // flex: 0 0 auto;
        white-space: nowrap;
        &.show:before {
          content: "•";
          font-size: 6px;
          line-height: 6px;
          padding: 0 3px;
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
    .post-stats {
      margin: 0 0 0 0;
      font-size: 12px;
    }
    .door-policy-summary {
      position: absolute;
      bottom: 10px;
      right: 6px;
      margin: 0;
      padding: 0;
      height: 16px;
      width: 16px;      
      color: var(--highlight-color);
      .icon {
        height: 16px;
        width: 16px;      
      }
    }

    .responses-count {
      position: absolute;
      bottom: 0;
      right: 0;
      margin: 0;
      padding: 0;
      height: 36px;
      width: 36px;
      border-radius: 8px 0 8px 0;
      background-color: var(--highlight-color);
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top: 3px solid #f5f4f4;
      border-left: 3px solid #f5f4f4;

      span {
        padding: 0 3px;
        flex: 0 0 30px;
        text-align: center;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @include fontSansSemiBold;
      }
    }
  }

  &.has-responses {
    .content {
      .door-policy-summary {
        bottom: 10px;
        right: 42px;
      }
    }
  }
  
  .generic-mouseover:empty + .content {
    padding-top: 14px;
  }

  &.image,
  &.embed {
    .content {
      padding-top: 8px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
  &.image.repost,
  &.embed.repost {
    .content {
      // padding-top: 82px;
    }
  }
  &.has-breadcrumbs {
    h1,
    .comment {
      margin: 0 0 2px 0;
    }
  }
}

@media only screen and (max-width: $mobile-width) {
  article.post-card {
    border-radius: 0;

    .content {
      line-height: $info-line-height;
      font-size: $info-font-size;
      padding: 4px 20px 40px 12px;
      border-radius: 0;

      .author {
        line-height: $info-line-height;
        font-size: $info-font-size;
        time {
          line-height: $info-line-height;
          font-size: $info-font-size;
        }
      }

      .post-stats {
        font-size: 11px;
      }
      .responses-count {
        height: 30px;
        width: 30px;
        border-radius: 8px 0 0 0;

        span {
          padding: 0 3px;
          flex: 0 0 24px;
          font-size: 11px;
          @include fontSansSemiBold;
        }
      }
      .reposted-by {
        line-height: $info-line-height;
        font-size: $info-font-size;
      }

      .tags {
        line-height: $info-line-height;
        font-size: $info-font-size;
      }

      h1,
      .comment {
        font-size: 15px;
        line-height: 21px;
        // a {
        // 	@include linkpseudos {
        // 		font-size: 14px;
        // 	}
        // }
      }
      .ancestor-title {
        line-height: $info-line-height;
        font-size: $info-font-size;
      }
    }

    &.article {
      .header-image {
        .embed,
        .embed-thumb {
          .info {
            h4 {
              font-size: 12px;
              line-height: 18px;
              @include fontSansRegular;
            }
          }
        }
      }
    }

    .header-image {
      border-radius: 0;
      &:after {
        border-radius: 0;
      }
      .embed,
      .embed-thumb {
        iframe {
          max-height: 140px;
        }

        .info {
          font-size: 11px;
          line-height: 17px;

          h4 {
            font-size: 15px;
            line-height: 21px;
            margin-left: 12px;
            margin-right: 20px;
          }
          p {
            font-size: 12px;
            line-height: 18px;
            margin-left: 12px;
            margin-right: 20px;
            @include fontSansRegular;
          }
        }
      }
    }

    &.columns-2 {
      .header-image {
        .embed,
        .embed-thumb {
          iframe {
            max-height: 331px;
          }
        }
      }
    }
  }
}
