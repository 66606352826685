@import "./../../../../scss/colors.scss";
@import "./../../../../scss/responsive.scss";

.dummycontent {
  .block {
    display: flex;
    flex: 1 1 auto;

    .veritcal {
      flex-direction: column;
    }
  }

  .avatar {
    background-color: $canvas-grey;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    margin-right: 16px;
  }
  .content {
    background-color: $canvas-grey;
    border-radius: 12px;
    height: 18px;
    width: 100%;
    margin-bottom: 8px;

    &.fat {
      height: 30px;
      border-radius: 15px;
      margin-bottom: 26px;
    }

    &.w-10 {
      width: 10%;
    }
    &.w-10 {
      width: 10%;
    }
    &.w-20 {
      width: 20%;
    }
    &.w-30 {
      width: 30%;
    }
    &.w-40 {
      width: 40%;
    }
    &.w-50 {
      width: 50%;
    }
    &.w-60 {
      width: 60%;
    }
    &.w-70 {
      width: 70%;
    }
    &.w-80 {
      width: 80%;
    }
    &.w-90 {
      width: 90%;
    }
    &.w-100 {
      width: 100%;
    }
  }

  .extra-margin {
    margin-bottom: 10px;
  }
}
