@import './../../../../scss/colors.scss';
@import './../../../../scss/typography.scss';
@import './../../../../scss/responsive.scss';


.post-newposttextbutton {
  background-color: var(--highlight-color);
  color: white;
	padding: 0px 16px 0px 6px;
  margin-left: 0 !important;
	text-align: center;
	@include fontSansSemiBold;
	box-sizing: border-box;
	width: auto;
	text-align: center;
	position: relative;
	border: 1px solid var(--highlight-color);
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 30px;
  border-radius: 15px;
  line-height: 22px;
  font-size: 14px;

  a, a:visited, a:active, a:hover {
    flex: 1 1 auto;
    height: 40px;
    line-height: 40px;
    color: white;
    text-decoration: none;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .icon {
      height: 24px;
      width: 24px;
    }
  }
} // .follow-button


@media only screen and (min-width: 600px) {
    .post-newposttextbutton {
      height: 40px;
      border-radius: 20px;
      line-height: 32px;
      font-size: 16px;
      a, a:visited, a:active, a:hover {
        .icon {
          height: 24px;
          width: 24px;
        }
      }
    }
}
