@import './colors.scss';
@import './typography.scss';

// hopefully generic and reusable styles for some forms
// second version already.
.tx2 {

    display: flex;
    flex-direction: column;

    .row {
      display: flex;
      flex-direction: row;

      & > * {
        flex: 1 1 auto;
      }

      .fixed {
        flex: 0 0 auto;
      }

      .end {
        margin-left: auto;
      }

    }

    .field {

        font-size: 14px;
        border-bottom: 1px solid $lighter-grey;
        text-align: left;

        &.error {

            input, textarea {
                border-bottom: 2px solid red;
            }
            .info {
                .error-message {
                    color: red;
                    justify-content: flex-start;
                    margin-right: auto;
                    margin-bottom: 15px;
                }
            }

            label {
                color: red;
            }
        }

        label {
            flex: 0 0 auto;
            color: $tremr-highlight;
            color: var(--highlight-color);
            text-transform: capitalize;
            font-size: 14px;
            line-height: 22px;
            text-transform: uppercase;
            @include fontSansRegular;
            user-select: none;
            text-align: left;
            margin-top: 10px;
        }

        p {
            font-size: 14px;
            @include fontSansRegular;
            font-style: italic;
        }

        p.help {
          color: $placeholder-grey;
        }

        input, textarea, .input {

            flex: 0 0 auto;
            border: none;
            height: auto;
            line-height: 22px;
            font-size: 18px;
            border-bottom: 2px solid $lighter-grey;
            box-shadow: none;
            color: $text-black;
            @include fontSansSemiBold;
            text-align: left;
            background-color: white;
            padding: 4px 0;
            border-radius: 0;
            border: none;
            margin: 0 0 15px 0;

            &:focus {
                background-color: white;
                color: $text-black;
            }

        }

        &.readonly {
      		input, textarea, .input {
      			cursor: not-allowed;
            color: var(--highlight-color);
      			// background-color: color($canvas-grey tint(40%));
      		}
      	}

        .info {
            flex: 0 0 auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            text-align: left;
        }

        &.image-upload-field {
            padding-bottom: 30px;
        }

        .image-upload {
            display: block;
            width: auto;
            position: relative;

            .image {
                display: block;
                height: 120px;
                width: auto;
                background-color: $lighter-grey;
                position: relative;
                border-radius: 10px;


                .icon {
                    position: absolute;
                    top: 50%;
                    height: 80px;
                    width: 80px;
                    color: $light-grey;
                    left: 50%;
                    transform: translateX(-40px) translateY(-40px);
                }
            }

            .upload-container {

            }

            input.cloudinary-fileupload {
                display: none;
            }

            a.button.upload {
                position: absolute;
                bottom: -17px;
                height: 34px;
                width: 34px;
                background-color: #AFADB4;
                color: white;
                border-radius: 50%;
                left: 50%;
                transform: translateX(-17px);
                margin: 0;
                padding: 0;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;

                .icon {
                    background-color: transparent;
                    color: white;
                    flex: 0 0 auto;
                    height: 24px;
                    width: 24px;
                }
            }

            .remove-image {
                position: absolute;
                top: 15px;
                right: 15px;
                height: 20px;
                width: 20px;
                background-color: white;
                border-radius: 50%;
                border: 2px solid $text-black;
                color: $text-black;
                cursor: pointer;
                z-index: 2;
                .icon {
                    height: 20px;
                    width: 20px;
                }
            }
        }

    }
}
