@import './../../../../scss/colors.scss';
@import './../../../../scss/typography.scss';
@import './../../../../scss/mixins/linkpseudos.scss';
@import './../../../../scss/responsive.scss';

.layout-page.post {

    .layout-titlebar {


        &.with-channel {
          height: 84px;
          transition: height 200ms ease;
          align-items: flex-start;
          position: relative;

          & > .layout-titlebar-content {
            height: 50px;
          }
        }

        & > .layout-titlebar-content {

            max-width: 100%;

            .logo {
                margin-right: 0;
                padding-right: 10px;
            }

            & > .subheader {
              display: none;
            }
            & > .newlinesubheader {
              display: block;
              position: absolute;
              top: 50px;
              left: 0;
              right: 0;
              height: 50px;
              overflow: hidden;
              text-overflow: ellipsis;
              padding: 0 12px;
              white-space: nowrap;
                @include fontSansRegular;
                font-size: 18px;
                height: 34px;
                line-height: 18px;
                display: block;
                color: white;
                text-decoration: none;
                padding-top: 0;
                padding-bottom: 0;
                background: transparent;
                &:hover, &:visited, &:active {
                  color: white;
                  text-decoration: none;
                }
            }
        }

        // .layout-titlebar-content {
        //     border-bottom: 3px solid $lighter-grey;
        // }

        // .logotr {
        //     padding-right: 0;
        //     margin-right: 8px;
        // }

        // .children.channel {
        //
        //     flex: 1 1 auto;
        //     display: flex;
        //     align-items: center;
        //     justify-content: flex-start;
        //     color: $text-black;
        //     text-decoration: none;
        //     height: 32px;
        //     margin: 0;
        //     // border-left: 3px solid $lighter-grey;
        //     padding: 0;
        //
        //     @include linkpseudos {
        //         color: $text-black;
        //         text-decoration: none;
        //     }
        //
        //     & > .image, & > .icon {
        //         flex: 0 0 auto;
        //         height: 32px;
        //         width: 32px;
        //         max-height: 32px;
        //         max-width: 32px;
        //         border-radius: 4px;
        //     }
        //
        //     .channel-avatar {
        //         height: 32px;
        //         width: 32px;
        //         line-height: 32px;
        //         font-size: 16px;
        //         position: relative;
        //         text-align: center;
        //         text-transform: capitalize;
        //         border-radius: 4px;
        //     }
        //
        //     .channel-title {
        //         flex: 1 1 auto;
        //         height: 17px;
        //         line-height: 17px;
        //         color: white;
        //         @include fontSansSemiBold;
        //         font-size: 17px;
        //         margin: 0 8px;
        //         max-height: 32px;
        //         overflow: hidden;
        //         text-overflow: ellipsis;
        //         display: -webkit-box;
        //         -webkit-box-orient: vertical;
        //         -webkit-line-clamp: 1;
        //         line-clamp: 1;
        //     }
        // }
    }
}

@media only screen and (min-width: $mobile-width) {

    .layout-page.post {

        .layout-titlebar {

          &.with-channel {
            height: 50px;
            align-items: center;
          }

          & > .layout-titlebar-content {

              .logo {
                  margin-right: 0;
              }

              & > .subheader {
                flex: 1 1 auto;
                overflow: hidden;
                text-overflow: ellipsis;
                padding-right: 0;
                white-space: nowrap;
                  @include fontSansRegular;
                  padding-left: 10px;
                  border-left: 1px solid white;
                  font-size: 20px;
                  height: 28px;
                  line-height: 28px;;
                  display: block;
                  color: white;
                  text-decoration: none;
                  padding-top: 0;
                  padding-bottom: 0;
                  background: transparent;
                  position: static;
                  &:hover, &:visited, &:active {
                    color: white;
                    text-decoration: none;
                  }
              }

              & > .newlinesubheader {
                display: none;
              }
            }

            // .children.channel {
            //
            //     & > .image, & > .icon {
            //         height: 36px;
            //         width: 36px;
            //     }
            //
            //     .channel-title {
            //         margin: 0 15px;
            //     }
            // }
        }
    }
}
