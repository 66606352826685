@import './../../../../scss/colors.scss';
@import './../../../../scss/typography.scss';
@import './../../../../scss/mixins/linkpseudos.scss';
@import './../../../../scss/mixins/progress.scss';
@import './../../layout/scss/sidebar.scss';

$control-grey: color($canvas-grey shade(20%));

.layout-overlays {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	z-index: 91250;

	&.empty {
		display: none;
	}

}

.layout-overlay.overlay-sidebar {
    @include sidebar;
}

.layout-overlay:not(.overlay-sidebar) {

	position: fixed;
	z-index: 255;
	height: 100%;
	width: 100%;
	// background-color: color($canvas-grey a(95%));
    background-color: $canvas-grey;
	overflow: auto;
    -webkit-overflow-scrolling: touch;

	&:overlay-1 { z-index: 91255; }
	&:overlay-2 { z-index: 91260; }
	&:overlay-3 { z-index: 91265; }
	&:overlay-4 { z-index: 91270; }
	&:overlay-5 { z-index: 91275; border: 4px dashed red !important; }

	.close {
		position: fixed;
		left: 15px;
		top: 15px;
		height: 30px;
		width: 30px;
		box-sizing: content-box;
		color: var(--highlight-color);
		z-index: 888;
		text-align: center;
		cursor: pointer;
		background-color: white;
		border: 2px solid $lighter-grey;
		border-radius: 50%;
		.icon {
			height: 30px;
			width: 30px;
			.svgWrapper {
				top: 20%;
				left: 20%;
				height: 60%;
				width: 60%;
			}
		}
	}

    .toolbar {
        position: fixed;
        top: 0;
        right: 0;
        width: 100%;
        height: 60px;
        background-color: white;
        border-bottom: 3px solid $lighter-grey;
        z-index: 30;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding-right: 15px;

    }

    .content {
        padding-top: 63px;
    	text-align: center;

        & > * {
    		max-width: calc(100% - 40px);
            width: calc(100% - 40px);
    		margin-left: 20px;
    		margin-right: 20px;
    	}
        & > .field,
				& > p,
				& > blockquote,
        & > h2,
        & > ul,
        & > ol,
				& > h3,
				& > h4,
        & > .option-list {
            max-width: calc(100% - 40px);
            width: 720px;
        }
    }

	// input, textarea {
	// 	// border: 2px solid $control-grey;
    //
	// 	// color: $tr-standard-grey;
	// 	color: $text-black;
	// 	// text-align: center;
	// 	outline: none;
	// 	&::placeholder {
	// 		color: $control-grey;
	// 	}
	// 	&:focus {
	// 		@include default-outline;
	// 		box-shadow: none;
	// 		color: $text-grey;
	// 		&::placeholder {
	// 			color: $control-grey;
	// 		}
	// 	}
	// }

	.readonly {
		input, textarea {
			cursor: not-allowed;
            color: var(--highlight-color);
			// background-color: color($canvas-grey tint(40%));
		}
	}

	button {
		background-color: $control-grey;
		margin: 0;
		width: auto;
		height: 34px;
		border-radius: 17px;
		overflow: hidden;
        padding: 0 15px;
        @include fontSansSemiBold;
        font-size: 14px;
        line-height: 34px;


		&:after {
			border-radius: 17px;
		}

		&:focus {
			// @include default-outline;
		}
		&:hover {
			background-color: color($canvas-grey shade(10%));
		}

		&.loading {			
			@include progressGrey;
		}
	}
} // .overlay

// @media only screen and (min-width: $mobile-width) {
//
// }
