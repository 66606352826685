@import './../../../../scss/colors.scss';
@import './../../../../scss/responsive.scss';
// @import './../../../../scss/typography.scss';
// @import './../../../../scss/mixins/linkpseudos.scss';

$favbar-background: white;

.layout-favbar {

	background-color: $favbar-background;
	color: var(--highlight-tint-color);
	position: relative;
	height: 90px;
	width: 100%;
	z-index: 1;

    hr {
        display: block;
        border: none;
        position: absolute;
        bottom: 0;
        left: 5px;
        right: 5px;
        height: 3px;
        margin: 0;
        background: $lighter-grey;
    }

	.container {
		display: inline-flex;
		margin-top: 20px;
		height: 70px;
        max-width: unset;
		overflow: hidden;
        padding-left: 0;
	}

	.user-menu,
	.layout-followingmenu {
		display: flex;
		flex: 1 0 auto;

		.wall .list {
			display: flex;
            padding-right: 78px;

			div {
				flex: 0 0 auto;
			}

			div.feed-listitem {
				flex: 0 0 58px;
				min-width: 58px;
				a {
					min-width: 46px;
				}
			}
		}
	}

    .layout-followingmenu {
        background-image: url('/images/dummy-fav.png');
        background-position: 12px 3px;
        background-repeat: repeat-x;
        min-width: calc(100vw - 200px);
    }

    .user-menu {
        a .icon {
            background-color: var(--highlight-color);
        }
        a + a .icon {
            background: linear-gradient(to left, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.3) 100%), var(--highlight-color);
        }
        a + a + a .icon {
            background: linear-gradient(to left, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.6) 100%), var(--highlight-color);
        }
    }

    a {
		display: inline-block;
		flex: 0 0 auto;
		position: relative;
		height: 40px;
		line-height: 40px;
		color: white;
        background-color: white;
        background-clip: padding-box;
		text-decoration: none;
		cursor: pointer;
		padding: 0 5px 10px 5px;
        margin: 0 1px 10px 1px;

		&.active {
			color: $favbar-background;
			&:hover, &:active, &:visited {
				color: $favbar-background;
			}

			.image, .icon, .tag-avatar, .channel-avatar, .feed-avatar {
                // border: 3px solid $favbar-background;
                border: 3px solid $lighter-grey;
                box-shadow: 0 0 0 2px $lighter-grey;
			}

            &:after {
                content: " ";
                position: absolute;
                bottom: -21px;
                left: 50%;
                transform: translateX(-28px);
                height: 10px;
                width: 55px;
                background: url('/images/arrow.png');
                background-size: 55px 10px;
                background-position: 0px 0px;
                background-origin: border-box;
                background-repeat: no-repeat;
            }
		}

        &:hover, &:active, &:visited {
			color: white;
			text-decoration: none;
		}

		.icon, .image, .tag-avatar, .channel-avatar {
			position: relative;
			border: 3px solid $favbar-background;
			background-color: var(--highlight-color);
			box-shadow: 0 0 0 2px white;
			box-sizing: border-box;
			border-radius: 8px;
			height: 46px;
			width: 46px;
			text-align: center;
            top: 2px;
            &.tag-avatar {
                border-radius: 50%;
            }
		}
		.icon {
			.svgWrapper {
				top: 13%;
				left: 13%;
				height: 74%;
				width: 74%;
			}
		}

		.tag-avatar, .channel-avatar {
			color: color($dark-grey tint(95%));
			line-height: 42px;
			font-size: 18px;
			position: relative;
			text-align: center;
			text-transform: capitalize;

			&:hover, &:active, &:visited {
				color: color($dark-grey tint(95%));
			}

			.unseencount {
				bottom: -9px;
				right: -8px;
				top: auto;
			}
		}
	}

    .feed-listitem {
        a .image {
            border-radius: 50%;
            .fader {
                border-radius: 50%;
            }
        }
        a:empty {
            &:before {
                content: " ";
                display: block;
                height: 40px;
                width: 40px;
                background: $lighter-grey;
                margin: 4px 3px;
                border-radius: 50%;
            }
        }
        a.channel .image {
            border-radius: 8px;
            .fader {
                border-radius: 8px;
            }
        }
        a.channel:empty {
            &:before {
                border-radius: 6px;
            }
        }
    }

	.tag-listitem {
		a {
			position: relative;
		}
	}

	.unseencount {
		position: absolute;
		top: 30px;
		right: 0px;
		bottom: auto;
		border: 2px solid $favbar-background;
	}

}


@media only screen and (min-width: $mobile-width) {

    .layout-favbar {

        display: flex;
        flex-direction: row;
        justify-content: center;
        overflow: hidden;

        hr {
            left: calc(calc(100% - 1220px) / 2);
            right: calc(calc(100% - 1220px) / 2);
        }

        .container {
            padding-left: 0;
        }

        .user-menu {
            a:first-child {
                padding-left: 3px;
                &.active {
                    &:after {
                        transform: translateX(-29px);
                    }
                }
            }
        }

    }
}
