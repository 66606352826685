@import './../../../../scss/colors.scss';
@import './../../../../scss/typography.scss';

.generic-colorpicker {

    .colors {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        .color, .add {
            flex: 0 0 34px;
            height: 34px;
            width: 34px;
            border: 3px solid white;
            border-radius: 50%;
            cursor: pointer;
            margin: 3px;

            &.selected {
                box-shadow: 0px 0px 0px 3px $lighter-grey;
            }
        }

        .add {
            background-color: $lighter-grey;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            .icon {
                flex: 0 0 18px;
                height: 18px;
                width: 18px;
            }
        }

    }

    .adding {
        display: block;
        height: 22px;
        width: auto;
        padding: 7px 0;
        margin: 10px 30% 0 30%;
        line-height: 22px;
        color: $light-grey;
        text-align: center;

        .contenteditable:after {
            display: none;
        }
    }
}
