@import './../../../../scss/colors.scss';
@import './../../../../scss/typography.scss';
@import './../../../../scss/mixins/linkpseudos.scss';
@import './../../../../scss/mixins/progress.scss';

$multibutton_height: 30px;
$base-border-radius: 4px;

.multi-button {
	user-select: none;

	position: relative;
	display: inline-block;
	text-transform: uppercase;

	.action {
		display: inline-block;
		height: $multibutton_height;
		border-radius: 4px;
		color: white;
		line-height: $multibutton_height;
		cursor: pointer;
	}

	.label {
		transition: transform 100ms ease 0s;

		display: inline-block;
		float: left;
		padding: 0 30px;
		height: $multibutton_height;
		border-radius: $base-border-radius 0 0 4px;
		background-color: $text-grey;
		white-space: nowrap;
		line-height: $multibutton_height;

		&:hover {
			background-color: color($canvas-grey shade(20%));
		}
	}
	&.working {
		.action {
			@include progressGrey;
		}
	}
	.handle {
		transition: transform 100ms ease 0s;

		position: relative;
		display: inline-block;
		float: right;
		width: $multibutton_height;
		height: $multibutton_height;
		border-radius: 0 $base-border-radius $base-border-radius 0;
		background-color: $text-grey;

		&:hover {
			background-color: color($canvas-grey shade(20%));
		}
		.icon {
			width: $multibutton_height;
			height: $multibutton_height;
		}
		&:before {
			position: absolute;
			top: 8px;
			bottom: 8px;
			left: 0;
			width: 0;
			border-left: 1px solid #ffffff33;
			content: " ";
		}
	}
	.dropdown {
		position: absolute;
		display: block;
		overflow: hidden;
		border: 1px solid $text-grey;
		border-radius: $base-border-radius;
		background-color: white;
		color: $text-grey;
		line-height: $multibutton_height;
		cursor: pointer;

		& > div {
			display: block;
			padding: 0 15px;
			height: $multibutton_height;
			text-align: center;
			white-space: nowrap;
			line-height: $multibutton_height;

			&:hover {
				background-color: color($canvas-grey shade(20%));
			}
		}
	}
}
