@import './../../../../scss/colors.scss';

.post-subfeeds {

	position: relative;

	.option-list {
		padding: 0;
		margin: 0;
	}

	.option-item {
		display: flex;
		align-items: center;
		height: 30px;
		line-height: 30px;
		list-style: none;
		margin-left: 0;

		.icon {
			flex: 0 0 auto;
			cursor: pointer;
		}

		.feedName {
			text-transform: capitalize;
		}

		.feedName, .newfeed {
			flex: 1 0 auto;
			text-transform: capitalize;
		}

		a {
			flex: 0 0 auto;
			cursor: pointer;
		}
	}

	label {
		display: none;
	}

	input {
		max-width: 300px;
		margin: 0;
		height: 50px;
		line-height: 50px;
		border-radius: 0;
		border: none;
		font-size: 1.4rem;
		box-shadow: none;
		&:focus {
			box-shadow: none;
		}
		&::placeholder {
			color: $form-grey;
		}
	}

}
