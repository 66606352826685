// styles for alertify notifications and dialogs, based on the original default styles
@import "./alertify.core.scss";
@import "./alertify.default.scss";
@import "./colors.scss";
@import "./typography.scss";

.alertify-cover {
  background-color: color($canvas-grey a(95%));

  &.alertify-cover-hidden {
    display: none;
  }
}

.alertify,
.alertify-log {
}
.alertify {
  border: none;
  border-radius: 0;
  // background: $tr-canvas-grey;
  // border: 10px solid #333; /* browsers that don't support rgba */
  // border: 10px solid rgba(0,0,0,.7);
  // border-radius: $base-border-radius;
  // box-shadow: 0 3px 3px rgba(0,0,0,0.2);
  // -webkit-background-clip: padding;     /* Safari 4? Chrome 6? */
  //    -moz-background-clip: padding;     /* Firefox 3.6 */
  //         background-clip: padding-box; /* Firefox 4, Safari 5, Opera 10, IE 9 */
  background-color: transparent;
  box-shadow: none;
  top: 50%;
  transform: translateY(-100px);
}
.alertify-text,
.alertify-message {
  padding: 10px;
  // border: 1px solid darken($tr-canvas-grey, 10%);
  border: none;
  border-radius: 4px;
  color: $text-black;
  @include fontSansSemiBold;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 10px;
}
.alertify-dialog {
  p {
    margin: 0 0 0.5em 0;
  }
}
.alertify-button {
  padding: 11px 25px;
  border: 1px solid var(--highlight-color);
  border-radius: 20px;
  background-color: $canvas-grey;
  color: var(--highlight-color);
  text-decoration: none;
  @include fontSansSemiBold;
  font-size: 16px;
  line-height: 19px;
  text-shadow: none;
  box-sizing: border-box;
  min-width: 122px;
  box-shadow: none;
  margin-left: 7px;
  margin-right: 7px;
  background-image: none;

  // text-shadow: 1px 1px 0 rgba(0,0,0,.5);
  // box-shadow: inset 0 1px 0 0 rgba(255,255,255,.5);
  // background-image: -webkit-linear-gradient(top, rgba(255,255,255,.3), rgba(255,255,255,0));
  // background-image:    -moz-linear-gradient(top, rgba(255,255,255,.3), rgba(255,255,255,0));
  // background-image:     -ms-linear-gradient(top, rgba(255,255,255,.3), rgba(255,255,255,0));
  // background-image:      -o-linear-gradient(top, rgba(255,255,255,.3), rgba(255,255,255,0));
  // background-image:         linear-gradient(top, rgba(255,255,255,.3), rgba(255,255,255,0));
}
.alertify-button:focus {
  // background-color: shade($tr-canvas-grey, 1%);
  box-shadow: none;
  background-image: none;
}
.alertify-button:hover {
  box-shadow: none;
  // background-color: shade($tr-canvas-grey, 3%);
  background-image: none;

  // color: white;
  // background-image: -webkit-linear-gradient(top, rgba(0,0,0,.1), rgba(0,0,0,0));
  // background-image:    -moz-linear-gradient(top, rgba(0,0,0,.1), rgba(0,0,0,0));
  // background-image:     -ms-linear-gradient(top, rgba(0,0,0,.1), rgba(0,0,0,0));
  // background-image:      -o-linear-gradient(top, rgba(0,0,0,.1), rgba(0,0,0,0));
  // background-image:         linear-gradient(top, rgba(0,0,0,.1), rgba(0,0,0,0));
}
.alertify-button:focus {
  // box-shadow: 0 0 5px #000;
  box-shadow: none;
}
.alertify-button:active {
  position: relative;
  box-shadow: none;

  // box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
}
.alertify-button-cancel,
.alertify-button-cancel:hover,
.alertify-button-cancel:focus {
  // background-color: $error-color;
  // border: 1px solid darken($error-color, 3%);
}
.alertify-button-ok,
.alertify-button-ok:hover,
.alertify-button-ok:focus {
  // background-color: $success-color;
  // border: 1px solid darken($success-color, 3%);
  background-color: var(--highlight-color);
  color: white;
}

.alertify-logs {
  z-index: 100000;
}
.alertify-log {
  padding: 15px;
  border-radius: 4px;
  background: $text-grey;
  background: rgba(0, 0, 0, 0.9);
  color: white;
  text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.5);
}
.alertify-log-error {
  background: $error-color;
  background: rgba($error-color, 0.8);
}
.alertify-log-success {
  background: $success-color;
  background: rgba($success-color, 0.8);
}
