@import "./../../../../scss/colors.scss";
@import "./../../../../scss/typography.scss";

$optionlist_height: 19px;
$optionlist_border: 3px;
$optionlist_marker_border: 4px;
$base-border-radius: 4px;

.option-list {
  display: block;

  .option-item {
    user-select: none;
    display: inline-block;
    height: $optionlist_height;
    line-height: $optionlist_height;
    border-radius: $base-border-radius;
    cursor: pointer;
    margin-left: 25px;
    // text-transform: uppercase;

    &:first-child {
      margin-left: 0;
    }

    .marker {
      display: inline-block;
      float: left;
      border: $optionlist_border solid $lighter-grey;
      height: $optionlist_height;
      width: $optionlist_height;
      position: relative;
      border-radius: 50%;

      &.tick {
        border: none;
        color: $lighter-grey;
      }
    }

    &.set {
      .marker:before {
        content: " ";
        position: absolute;
        top: $optionlist_marker_border;
        left: $optionlist_marker_border;
        right: $optionlist_marker_border;
        bottom: $optionlist_marker_border;
        background-color: var(--highlight-color);
        border-radius: 50%;
      }
      .marker.tick {
        color: var(--highlight-color);
      }
      .marker.tick:before {
        display: none;
      }
    }
    &.set.readonly {
      .marker:before {
        background-color: $light-grey;
      }
    }

    .label {
      margin-left: 15px;
      display: inline-block;
      float: right;
      line-height: $optionlist_height;
      height: $optionlist_height;
    }
  }
}
