@import './../../../../scss/colors.scss';
@import './../../../../scss/typography.scss';
@import './../../../../scss/mixins/linkpseudos.scss';
@import './../../../../scss/responsive.scss';

.post-related {

   @include fontSansRegular;
   font-size: 14px;
   line-height: 20px;
   background-color: white;
   padding: 0 0 20px 0;
   overflow: hidden;
   display: none;

   &.loaded {
       display: block;
   }
   &.loaded.empty {
       display: none;
   }

   .generic-horizontalscroller {
       width: 100%;
       max-width: 100%;
       &.custom-scrolling .scroll-content {
           width: calc(100% - 40px);
           max-width: calc(100% - 40px);
       }
   }

   .title {
       @include fontSansBold;
       font-size: 12px;
       line-height: 20px;
       margin: 12px 0 4px 0;
       color: var(--highlight-color);
       text-align: center;
       text-transform: uppercase;
   }

   .wall .post-list {
      display: flex;
      margin: 0;
      min-height: 90px;
      max-height: 90px;
      box-sizing: border-box;
      justify-content: flex-start; // can't centre or you get pre-scrolled effect

      & > a.post-simplecard {
         flex: 0 0 220px;
         margin: 0 10px;
         border-top: 3px solid $lighter-grey;
         border-bottom: 3px solid $lighter-grey;
      }
   } // .wall .post-list

}

@media only screen and (min-width: $mobile-width) {
    .post-related {
        padding: 0 0 20px 0;
        max-width: 100% !important;
        width: 100% !important;
        margin: 0 !important;
        box-sizing: border-box;


    }
}
