@import "./../../../../scss/colors.scss";
@import "./../../../../scss/typography.scss";

.reputation-user-overview {
  position: relative;
  cursor: pointer;

  &:empty {
    display: none;
  }

  & > .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1234;
  }

  &.left.open {
    .reputation-user-overview-popup {
      // transform: translateX(0) translateY(30px);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 20px;
      & > .icon {
        position: absolute;
        left: 16px;
      }
    }
  }

  &.open {
    &:after {
      content: " ";
      position: absolute;
      top: -15px;
      right: 0;
      height: 50px;
      width: 70px;
      background: transparent;
    }

    .reputation-user-overview-popup {
      background: white;
      border: 3px solid var(--highlight-color);
      border-bottom-left-radius: 20px;
      // position: absolute;
      position: fixed;
      transform: translateY(30px);
      // right: -19px;
      // top: 30px;
      height: auto;
      width: 250px;
      max-width: min(250px, 100%);
      margin: 0;
      padding: 0;
      @include fontSansRegular;
      padding: 0 0 10px 0;
      user-select: none;
      z-index: 1235;
      cursor: default;

      & > .icon {
        transform: rotate(180deg);
        fill: var(--highlight-color);
        position: absolute;
        top: -13px;
        height: 12px;
        width: 18px;
        right: 16px;
        cursor: pointer;
        .triangle {
          fill: var(--highlight-color);
          stroke: var(--highlight-color);
          stroke-width: 1;
        }
        .edge {
          fill: var(--highlight-color);
          stroke: var(--highlight-color);
          stroke-width: 1;
        }
      }

      h4 {
        background: var(--highlight-color);
        color: white;
        text-transform: uppercase;
        line-height: 24px;
        font-size: 11px;
        @include fontSansBold;
        margin: 0 0 10px 0;
        padding: 5px 0 6px 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        .icon {
          margin-left: auto;
          margin-right: 13px;
          color: white;
          fill: white;
          height: 24px;
          width: 24px;
        }
      }

      h5 {
        font-size: 13px;
        @include fontSansBlack;
        color: black;
        min-height: 24px;
        height: auto;
        line-height: 16px;
        margin: 0;
        padding: 4px 15px;
      }

      & > .line {
        margin: 0;
        padding: 0 15px;
        color: $text-grey;
        height: 24px;
        line-height: 24px;
        font-size: 11px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        text-transform: uppercase;
        @include fontSansRegular;

        label {
          font-size: 11px;
        }

        .reputation-stars {
          margin-left: auto;
          height: 18px;
          width: 63px;
          canvas {
            height: 18px;
            width: 63px;
          }
        }

        &.tag {
          color: var(--highlight-color);
          &:before {
            content: "";
          }
          label {
            color: var(--highlight-color);
          }
        }
      }
    }
  }
}
