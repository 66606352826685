/**
 * Default Look and Feel
 */
.alertify,
.alertify-log {
  font-family: sans-serif;
}
.alertify {
  background: #fff;
  border: 10px solid #333; /* browsers that don't support rgba */
  border: 10px solid rgba(0, 0, 0, 0.7);
  border-radius: 8px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
  -webkit-background-clip: padding; /* Safari 4? Chrome 6? */
  -moz-background-clip: padding; /* Firefox 3.6 */
  background-clip: padding-box; /* Firefox 4, Safari 5, Opera 10, IE 9 */
}
.alertify-text {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
}
.alertify-button {
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  padding: 6px 15px;
  text-decoration: none;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.5);
  background-image: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0)
  );
  background-image: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0)
  );
  background-image: -ms-linear-gradient(
    top,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0)
  );
  background-image: -o-linear-gradient(
    top,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0)
  );
  background-image: linear-gradient(
    top,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0)
  );
}
.alertify-button:hover,
.alertify-button:focus {
  outline: none;
  box-shadow: 0 0 15px #2b72d5;
  background-image: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0)
  );
  background-image: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0)
  );
  background-image: -ms-linear-gradient(
    top,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0)
  );
  background-image: -o-linear-gradient(
    top,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0)
  );
  background-image: linear-gradient(top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
}
.alertify-button:active {
  position: relative;
  top: 1px;
}
.alertify-button-cancel {
  background-color: #fe1a00;
  border: 1px solid #d83526;
}
.alertify-button-ok {
  background-color: #5cb811;
  border: 1px solid #3b7808;
}

.alertify-log {
  background: #1f1f1f;
  background: rgba(0, 0, 0, 0.9);
  padding: 15px;
  border-radius: 4px;
  color: #fff;
  text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.5);
}
.alertify-log-error {
  background: #fe1a00;
  background: rgba(254, 26, 0, 0.9);
}
.alertify-log-success {
  background: #5cb811;
  background: rgba(92, 184, 17, 0.9);
}
