@import "./../../../../scss/colors.scss";
@import "./../../../../scss/typography.scss";

.reputation-badge {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  @include fontSansBlack;
  line-height: 20px;
  font-size: 10px;
  color: var(--highlight-color);
  transform: translateX(-5px);

  .icon {
    flex: 0 0 auto;
    height: 20px;
    width: 20px;
  }

  .reputation-badge-description {
    flex: 1 1 auto;
    text-transform: uppercase;
    text-align: left;
  }
}
