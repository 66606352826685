@import './../../../../scss/colors.scss';
@import './../../../../scss/typography.scss';
@import './../../../../scss/responsive.scss';

//generic layout
.layout-page {
    .primary {
        .wall {
            .grid, .list {
                &:not(.init) {
                    &:empty {
                        display: flex;
                        justify-content: center;
                        align-items: flex-start;
                        &:before {
                            content: "Wall empty";
                            flex: 0 0 auto;
                            color: $light-grey;
                            @include fontSansRegular;
                            line-height: 32px;
                            font-size: 18px;
                            margin-top: 90px;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}

// feed page
.layout-page.feed {
    .primary {
        .wall {
            .grid, .list {
                &:not(.init) {
                    &:empty {
                        &:before {
                            content: "Feed empty";
                        }
                    }
                }
            }
        }
    }
}

.layout-page.inbox {
    .primary {
        .wall {
            .grid, .list {
                &:not(.init) {
                        &:empty {
                        &:before {
                            content: "Inbox empty";
                        }
                    }
                }
            }
        }
    }
}

.layout-page.bookmarks {
    .primary {
        .wall {
            .grid, .list {
                &:not(.init) {
                        &:empty {
                        &:before {
                            content: "Bookmarks empty";
                        }
                    }
                }
            }
        }
    }
}

.layout-page.tag {
    .primary {
        .wall {
            .grid, .list {
                &:not(.init) {
                        &:empty {
                        &:before {
                            content: "Tag empty";
                        }
                    }
                }
            }
        }
    }
}

.layout-page.search {
    .primary {
        .wall {
            .grid, .list {
                &:not(.init) {
                        &:empty {
                        &:before {
                            content: "Search empty";
                        }
                    }
                }
            }
        }
    }
}
