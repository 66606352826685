@import "./../../../../scss/colors.scss";
@import "./../../../../scss/typography.scss";
@import "./../../../../scss/mixins/linkpseudos.scss";
@import "./../../../../scss/responsive.scss";

.header.feed {
  display: block;
  background: white;
  position: relative;
  overflow: hidden;

  .generic-horizontalscroller .scroll-content {
    padding: 0 12px;
  }
  .generic-horizontalscroller.content-fits .scroll-content {
    padding: 0 12px;
  }

  &.homeui {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    a.feedName {
      flex: 0 0 auto;
      line-height: 38px;
      margin: 5px 10px;
      font-size: 16px;
      padding: 4px 12px 6px 0;
      @include fontSansBold;
      white-space: nowrap;
      text-decoration: none;
      // color: $text-grey;
      color: var(--highlight-color);
      cursor: pointer;
      @include linkpseudos {
        text-decoration: none;
        // color: $text-grey;
        color: var(--highlight-color);
      }
      &:after {
        content: ":";
      }
    }

    .feed-posts-subfeeds-summary {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    .menu {
      flex: 1 1 auto;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin: 0;
      line-height: 22px;
      font-size: 14px;

      a {
        flex: 0 0 auto;
        display: block;
        @include fontSansBold;
        line-height: 20px;
        margin: 5px 10px;
        font-size: 16px;
        padding: 4px 12px 6px 12px;
        @include fontSansBold;
        white-space: nowrap;
        text-decoration: none;
        color: $text-grey;
        cursor: pointer;

        @include linkpseudos {
          text-decoration: none;
          color: $text-grey;
        }

        &.manage-subfeeds-button {
          display: none;
        }

        &.active {
          color: var(--highlight-color);
          border-radius: 4px;
          background-color: $lighter-grey;
          @include linkpseudos {
            color: var(--highlight-color);
          }
          & > output,
          & > label {
            color: var(--highlight-color);
          }
        }

        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }

        & > * {
          @include fontSansBold;
          line-height: 20px;
          font-size: 16px;
          display: inline;
          color: $text-grey;
          cursor: pointer;
          text-transform: capitalize;
        }

        & > output {
          @include fontSansBold;
          line-height: 20px;
          font-size: 16px;
          color: $text-grey;

          &:before {
            content: " (";
          }
          &:after {
            content: ")";
          }
        }
      } // a
    } // .menu
  }

  &:not(.homeui) {
    &.has-image {
      background: white;

      .header-image {
        &:before {
          content: " ";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 2;
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.5) 0%,
            rgba(0, 0, 0, 0) 30%
          );
        }
      }
    }

    .header-image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 200px;
      z-index: 1;
      background-color: var(--highlight-color);
      .image {
        max-height: 200px;
      }
    }

    .layout-titlebar {
      background-color: transparent;
      color: white;
      position: relative;
      z-index: 6;

      & > .layout-titlebar-content {
        background-color: transparent;
        border: none;

        & > a {
          color: white;
          @include linkpseudos {
            color: white;
          }
        }

        .logo {
          margin-right: 0;
        }

        & > .subheader {
          padding-left: 10px;
          border-left: 1px solid white;
          font-size: 28px;
          line-height: 1em;
          display: none;
        }

        & > .icon {
          color: white;
        }

        .button + .user-profilemenu,
        .button + .signinup-link {
          margin-left: 0;
        }
      }
    } // .layout-titlebar

    &.channelheader {
      .content {
        margin: 0;

        .avatar {
          max-height: 100px;
          max-width: 100px;

          &.dummy {
            height: 100px;
            width: 100px;
            border: none;
            border-radius: 8px;
            background-color: white;

            .channel-avatar {
              @include fontSansBold;
              height: 100px;
              width: 100px;
              background: $lighter-grey;
              border-radius: 8px;
              line-height: 100px;
              font-size: 48px;
              position: relative;
              text-align: center;
              text-transform: capitalize;
              text-decoration: none;
            }
          }

          .image {
            border: none;
            border-radius: 8px;
            max-height: 100px;
            max-width: 100px;
            min-height: 100px;
            min-width: 100px;
            .fader {
              border-radius: 8px;
            }
          }
        }
      }

      .contributors {
        z-index: 3;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 32px 0 0 0;
        position: relative;
        left: -8px;

        &:empty {
          height: 30px;
        }

        .contributor {
          flex: 0 0 24px;
          max-width: 20px;
          overflow: visible;

          .image {
            border-radius: 50%;
            border: 3px solid white;
            max-height: 30px;
            max-width: 30px;
            .fader {
              border-radius: 50%;
            }
          }
        }
      } // .contributors

      .content {
        .info {
          margin-top: 15px;
        }

        .actions-tabs-container {
          .actions {
            .follow-button-container, .join-button-container {
              margin: 0 6px;
            }
          }
        }
      }
    } // &.channelheader

    .content {
      // flex: 0 0 100%;
      z-index: 5;
      position: relative;
      margin: 71px 0 0 0;
      padding: 0;
      display: flex;
      @include fontSansRegular;
      line-height: 1.6em;
      align-items: flex-start;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;

      .avatar {
        flex: 0 0 auto;
        max-width: 162px;
        max-height: 162px;
        .image {
          border: 6px solid white;
          max-width: 150px;
          max-height: 150px;
          min-width: 150px;
          min-height: 150px;
        }
      }

      .info {
        flex: 1 0 30%;
        margin: 0 20px 20px 20px;
        font-size: 1.6rem;
        line-height: 1.6em;
        text-align: center;
        max-width: calc(100% - 40px);

        h1 {
          margin: 0;
          @include fontSansSemiBold;
          font-size: 36px;
          line-height: 42px;
          text-align: center;
        }

        .username {
          margin: 0;
          @include fontSansSemiBold;
          font-size: 20px;
          line-height: 28px;
          text-align: center;
          color: var(--highlight-color);
          text-transform: lowercase;
        }

        .bio {
          font-size: 14px;
          line-height: 22px;
          text-align: center;
          color: $text-grey;
          hr {
            border: none;
            outline: none;
            display: block;
            margin: 10px auto;
            height: 3px;
            width: 140px;
            background-color: $canvas-grey;
          }
          .reputation-stars {
            margin: 3px 0;
            line-height: 19px;
            height: 19px;
            canvas {
              height: 19px;
              width: 57px;
            }
          }
          .reputation-badge {
            justify-content: center;
            flex-direction: column;
            transform: none;
            .icon {
              height: 30px;
              width: 30px;
            }
            .reputation-badge-description {
              flex: 0 1 auto;
              font-size: 12px;
              line-height: 16px;
              margin-bottom: 5px;
            }
          }
          .author-tags {
            margin: 0;
            @include fontSansRegular;
            font-size: 12px;
            line-height: 28px;
            text-align: center;
            color: var(--highlight-color);
            text-transform: uppercase;
          }
        }

        .links {
          display: flex;
          align-items: center;
          font-size: 14px;
          line-height: 22px;
          flex-wrap: wrap;
          justify-content: center;
          color: $text-grey;

          a {
            color: $text-grey;
            @include linkpseudos {
              color: $text-grey;
              text-decoration: none;
              cursor: pointer;
            }
          }

          & > * {
            display: inline-block;
            flex: 0 0 auto;
            margin-left: 10px;

            &:before {
              content: "•";
              // font-size: 0.8em;
              display: inline;
              padding-right: 10px;
              color: $text-grey;
              cursor: default;
              font-family: serif;
            }

            &:first-child {
              margin-left: 0;
              &:before {
                content: "";
                padding-right: 0;
              }
            }
          }
        }
      }

      .actions-tabs-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 100%;
        flex: 1 0 100%;
        width: 100%;
        border-bottom: 3px solid $lighter-grey;
        margin: 0 auto 10px auto;
      }

      .actions {
        flex: 0 0 auto;
        margin: 0 0 20px 0;
        display: flex;
        align-items: center;
        justify-content: center;

        & > * {
          flex: 0 0 auto;
          margin: 0 6px;
        }

        & > .follow-button-container > .follow-button,
        & > .join-button-container > .join-button,
        & > .endorse-button-container > .endorse-button {
          height: 30px;
          border-radius: 15px;
          line-height: 22px;
          font-size: 14px;
        }
        & > .follow-button-container,
        & > .join-button-container {
          margin: 0;
        }

        .addthis_toolbox {
          margin: 0;
          position: relative;
          flex: 0 1 auto;
          display: flex;

          .custom_images {
            max-height: 30px;
            overflow: hidden;
          }

          .addthis_button_more,
          .native-share {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 28px;
            width: 28px;
            border: 1px solid var(--highlight-color);
            border-radius: 50%;
            margin: 0;
            padding: 0;
          }

          .icon {
            max-height: 28px;
            max-width: 28px;
          }
          .native-share {
            color: var(--highlight-color);
          }
          .addthis_button_more {
            color: var(--highlight-color);
          }
        } // .addthis_toolbox
      } // .actions

      .custom-scrolling .scroll-button {
        height: 37px;
      }
    } // .content

    &.best-of-tremr .content,
    &.tremr .content,
    &.top-picks .content,
    &.covid-action .content {
      .actions-tabs-container {
        justify-content: center;
        margin-bottom: 20px;
      }
    }

    .tabs {
      flex: 1 1 auto;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin: 0;
      overflow: auto visible;
      word-wrap: normal;
      white-space: nowrap;
      transform: translateY(3px);

      a {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 4px 6px 23px 6px;
        text-decoration: none;
        @include fontSansBold;
        color: $text-grey;
        position: relative;
        cursor: pointer;

        @include linkpseudos {
          text-decoration: none;
          color: $text-grey;
        }

        &.active {
          color: var(--highlight-color);

          &:before {
            content: " ";
            position: absolute;
            // bottom: -3px;
            bottom: 0;
            left: 50%;
            transform: translateX(-27.5px);
            height: 10px;
            width: 55px;
            background: url("/images/arrow.png");
            background-size: 55px 10px;
            background-position: 0px 0px;
            background-origin: border-box;
            background-repeat: no-repeat;
          }

          @include linkpseudos {
            color: var(--highlight-color);
          }
          label {
            color: var(--highlight-color);
          }
        } // &.active

        label {
          flex: 1 1 auto;
          display: block;
          order: 2;
          font-size: 12px;
          line-height: 16px;
          text-transform: uppercase;
          text-align: center;
          letter-spacing: -0.2px;
          color: $text-grey;
          @include fontSansBold;
          cursor: pointer;
        }
        output {
          flex: 1 1 auto;
          display: block;
          order: 1;
          font-size: 30px;
          line-height: 36px;
          text-align: center;
          cursor: pointer;
        }
      } // a
    } // .tabs

    .no-submenu {
      display: block;
      height: 56px;
    }

    .menu {
      flex: 1 1 auto;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin: 0;
      line-height: 22px;
      font-size: 14px;

      a {
        flex: 0 0 auto;
        display: block;
        @include fontSansBold;
        color: $text-grey;
        line-height: 22px;
        font-size: 18px;
        padding: 10px 5px 24px 5px;
        margin: 0 15px;
        cursor: pointer;
        text-transform: capitalize;

        @include linkpseudos {
          text-decoration: none;
          color: $text-grey;
        }

        &.active {
          color: var(--highlight-color);
          @include linkpseudos {
            color: var(--highlight-color);
          }
          & > output,
          & > label {
            color: var(--highlight-color);
          }
        }

        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
          padding-right: 20px;
        }

        & > * {
          @include fontSansBold;
          line-height: 22px;
          font-size: 18px;
          display: inline;
          color: $text-grey;
          cursor: pointer;
        }

        & > output {
          @include fontSansBold;
          line-height: 22px;
          font-size: 18px;
          color: $text-grey;

          &:before {
            content: " (";
          }
          &:after {
            content: ")";
          }
        }
      } // a
    } // .menu
  } // &:not(.homeui)
}

@media only screen and (min-width: $mobile-width) {
  .header.feed {
    .generic-horizontalscroller {
      .scroll-content:first-child {
        padding-left: 20px;
      }
      .scroll-content:last-child {
        padding-right: 20px;
      }
    }

    &:not(.homeui) {
      .generic-horizontalscroller {
        .scroll-content:first-child {
          padding-left: 30px;
        }
        .scroll-content:last-child {
          padding-right: 30px;
        }
      }

      &.channelheader {
        .header-image {
          max-height: 300px;
          .image {
            max-height: 300px;
          }
        }
        .content {
          margin: 15px 0 0 0;

          .avatar {
            max-height: 140px;
            max-width: 140px;

            &.dummy {
              height: 140px;
              width: 140px;
              background-color: white;
              .channel-avatar {
                height: 140px;
                width: 140px;
                line-height: 140px;
              }
            }

            .image {
              max-height: 140px;
              max-width: 140px;
              min-height: 140px;
              min-width: 140px;
            }
          } // .avatar
        } // .content

        .contributors {
          margin-top: 74px;
          &:empty {
            height: 36px;
          }
          .contributor {
            max-width: 28px;
            .image {
              max-height: 36px;
              max-width: 36px;
            }
          }
        }

        .info {
          margin-top: 25px;
        }
      } // &.channelheader

      .layout-titlebar {
        & > .layout-titlebar-content {
          .subheader {
            @include fontSansLight;
            display: block;
          }
        }
      } // .layout-titlebar

      .header-image {
        height: 320px;
        .image {
          max-height: 320px;
        }
      }

      .content {
        margin-top: 166px;

        .avatar {
          max-width: 212px;
          max-height: 212px;
          .image {
            max-width: 200px;
            max-height: 200px;
            min-width: 200px;
            min-height: 200px;
          }
        }

        .info {
          max-width: 1180px;
          margin-bottom: 15px;

          h1 {
            font-size: 46px;
            line-height: 60px;
          }

          .username {
            font-size: 20px;
            line-height: 30px;
          }

          .bio,
          .links {
            font-size: 18px;
            line-height: 28px;
            .author-tags {
              font-size: 16px;
              line-height: 30px;
            }
          }
        }

        .actions {
          & > .join-button-container > .join-button {
            height: 40px;
            border-radius: 20px;
            line-height: 32px;
            font-size: 16px;
          }

          & > .follow-button-container > .follow-button {
            height: 40px;
            border-radius: 20px;
            line-height: 32px;
            font-size: 16px;
          }

          & > .endorse-button-container > .endorse-button {
            height: 40px;
            border-radius: 20px;
            line-height: 32px;
            font-size: 16px;
          }

          .addthis_toolbox {
            .custom_images {
              max-height: 40px;
            }

            .addthis_button_more {
              height: 38px;
              width: 38px;
            }

            .icon {
              max-height: 38px;
              max-width: 38px;
            }
          } // .addthis_toolbox
        } // .actions

        .actions-tabs-container {
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          max-width: 1220px;
          flex: 1 0 calc(100% - 40px);
          width: calc(100% - 40px);
          border-bottom: 3px solid $lighter-grey;
          .tabs {
            flex: 1 1 auto;
            order: 1;
            overflow: visible;
            a output {
              font-size: 36px;
              line-height: 42px;
              padding-left: 16px;
              padding-right: 16px;
            }
          }
          .actions {
            flex: 0 1 auto;
            order: 2;
            margin-left: auto;
            margin: 0;
          }
        }
      } // .content

      &.best-of-tremr .content,
      &.tremr .content,
      &.top-picks .content,
      &.covid-action .content {
        .actions-tabs-container {
          padding: 10px 0 30px 0;
        }
      }

      // .sideways-scroller {
      // 	max-width: 1220px;
      //     flex: 1 0 calc(100% - 40px);
      //     width: calc(100% - 40px);
      //     min-width: unset;
      // }
    } // &:not(.homeui)
  }
}

@media only screen and (min-width: $mobile-width) {
  .header.feed {
    &.homeui {
      height: 65px;
      line-height: 63px;

      a.feedName {
        line-height: 22px;
        margin: 5px 10px;
        font-size: 18px;
        padding: 5px 12px 7px 0;
      }

      .menu {
        font-size: 18px;
        line-height: 63px;

        a {
          line-height: 22px;
          font-size: 18px;
          margin: 5px 10px;
          padding: 5px 12px 7px 12px;

          & > * {
            line-height: 22px;
            font-size: 18px;
          }

          & > output {
            line-height: 22px;
            font-size: 18px;
          }
        } // a
      } // .menu
    }
  }
}

@media only screen and (min-width: 1280px) {
  .header.feed:not(.homeui) {
    .generic-horizontalscroller {
      .scroll-content:first-child {
        padding-left: 30px;
      }
      .scroll-content:last-child {
        padding-right: 30px;
      }
    }
  }
}
