@mixin fontSansRegular {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  text-rendering: optimizeLegibility;
}

@mixin fontSansItalic {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: italic;
  font-weight: 400;
  text-rendering: optimizeLegibility;
}

@mixin fontSansBoldItalic {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: italic;
  font-weight: 700;
  text-rendering: optimizeLegibility;
}

@mixin fontSansBlackItalic {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: italic;
  font-weight: 900;
  text-rendering: optimizeLegibility;
}

@mixin fontSansLight {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 300;
  text-rendering: optimizeLegibility;
}

@mixin fontSansSemiBold {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

@mixin fontSansBold {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

@mixin fontSansBlack {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

@mixin fontSerifLite {
  font-family: "Merriweather", serif;
  font-style: normal;
  font-weight: 300;
  text-rendering: optimizeLegibility;
}

@mixin fontSerifRegular {
  font-family: "Merriweather", serif;
  font-style: normal;
  font-weight: 400;
  text-rendering: optimizeLegibility;
}

@mixin fontSerifBold {
  font-family: "Merriweather", serif;
  font-style: normal;
  font-weight: 700;
  text-rendering: optimizeLegibility;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  @include fontSansRegular;
}
