@import './../../../../scss/colors.scss';
@import './../../../../scss/typography.scss';
@import './../../../../scss/mixins/linkpseudos.scss';
@import './../../../../scss/responsive.scss';

$avatarSize: 52px;

.feed-edit-contributors {

    margin: 0 10px;

    h2 {
      font-size: 20px;
      line-height: 26px;
      padding: 15px 0 0 0;
      margin: 0;
      border-top: 2px solid $lighter-grey;
    }

    p {
      margin: 0 0 15px 0;
      font-style: italic;
      color: $light-grey;
    }

    .contributor {
        display: flex;
        border-bottom: 2px solid $lighter-grey;
        padding: 17px 0 24px 0;
        margin: 0 0 12px 0;


        .avatar {
            flex: 0 0 $avatarSize;
            max-height: $avatarSize;
            max-width: $avatarSize;
            .image {
              max-height: $avatarSize;
              max-width: $avatarSize;
            }
        }

        .info {
            flex: 1 0 200px;
            display: flex;
            flex-direction: column;
            margin-left: 24px;
            justify-content: center;
            align-items: flex-start;

            & > * {
                margin: 0;
            }

            .name {
              @include fontSansSemiBold;
              font-size: 15px;
              line-height: 22px;

              .role {
                  display: inline;
                  @include fontSansRegular;
              }
            }

            .bio {
              @include fontSansRegular;
              font-size: 15px;
              line-height: 22px;
              margin: 0 0 12px 0;
            }

            .actions {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-content: center;

              .status {
                margin: 0;
                border: 1px solid $lighter-grey;
                border-radius: 24px;
                line-height: 14px;
                font-size: 12px;
                padding: 4px 15px;
                min-width: 86px;
                box-sizing: border-box;
                text-align: center;
                user-select: none;
                display: none;
                &.declined {
                  display: block;
                  margin-right: 6px;
                  border-color: $profile-colors-red;
                  color: $profile-colors-red;
                }
              }

              .delete {
                  display: inline-block;
                  border: 1px solid $lighter-grey;
                  border-radius: 24px;
                  line-height: 14px;
                  font-size: 12px;
                  padding: 4px 15px;
                  min-width: 86px;
                  box-sizing: border-box;
                  text-align: center;
                  user-select: none;
                  cursor: pointer;
              }

              .channel-admin {
                  display: inline-block;
                  border: 1px solid $lighter-grey;
                  border-radius: 24px;
                  line-height: 14px;
                  font-size: 12px;
                  padding: 4px 15px;
                  min-width: 86px;
                  margin-left: 6px;
                  box-sizing: border-box;
                  text-align: center;
                  user-select: none;
                  cursor: pointer;
                  &.set {
                    background-color: var(--highlight-color);
                    color: white;
                  }
              }
            }
        } // .info

        .reputation {
          flex: 0 0 54px;
          max-height: 18px;
          max-width: 54px;
          .reputation-stars {
            max-height: 18px;
            max-width: 54px;
            canvas {
              max-height: 18px;
              max-width: 54px;
            }
          }
        }
    }

    .invite {
        position: relative;
        color: #5F5C6A;

        .field.invite {
          label, .info {
            display: none;
          }

          input {
            @include fontSansRegular;
            font-size: 16px;
            line-height: 34px;
            color: #5F5C6A;
            margin: 10px 0 12px 0;
            border: none;
            outline: none;
            box-shadow: none;
            box-sizing: border-box;
            height: 34px;
            padding: 0;
          }
        }

        .suggestions {
            display: flex;
            width: 100%;
            flex-direction: column;
            max-height: none;
            .contributor.suggestion {
              user-select: none;
              cursor: pointer;
              &:last-child {
                border-bottom: none;
              }
            }
        }
    }

}

@media only screen and (min-width: 795px) {

  .feed-edit-contributors {

    margin: 0;

    h2 {
      font-size: 30px;
      line-height: 40px;
    }


  }
}
