@import "./../../../../scss/colors.scss";
@import "./../../../../scss/typography.scss";
@import "./../../../../scss/mixins/linkpseudos.scss";
@import "./../../../../scss/responsive.scss";

.editor .editor-toolbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: auto;
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 10101;
  padding: 0 15px;
  box-sizing: border-box;
  user-select: none;
  background-color: white;

  .summary-line {
    [contenteditable] {
      white-space: normal;
    }
  }

  &:after {
    content: " ";
    position: absolute;
    bottom: 0;
    height: 3px;
    // left: 7px;
    // right: 7px;
    left: 0;
    right: 0;
    background-color: $lighter-grey;
  }

  [contenteditable] {
    user-select: text;
  }

  & > .icon {
    height: 28px;
    width: 28px;
    cursor: pointer;
    border-radius: 50%;
    border: 3px solid $lighter-grey;
    color: var(--highlight-color);
    & > .svgWrapper {
      top: 10%;
      left: 10%;
      height: 80%;
      width: 80%;
    }
  }

  // .editor-typesmenu {
  // display: flex;
  // flex: 0 0 auto;
  // margin-left: auto;
  // margin-right: auto;
  // align-self: flex-end;
  //
  // & > * {
  //     flex: 0 0 auto;
  //     border-radius: 6px 6px 0 0;
  //     box-sizing: border-box;
  //     cursor: pointer;
  //     transform: translateY(2px);
  //     padding: 12px 12px 10px 12px;
  //     border-bottom: 2px solid $light-grey;
  //     &.active {
  //         padding: 10px;
  //         border: 2px solid $light-grey;
  //         border-bottom: 2px solid white;
  //     }
  // }
  // } // .editor-typesmenu

  .channel {
    margin-left: 15px;
    font-size: 18px;
  }

  .status {
    margin-left: auto;
    color: var(--highlight-color);
    font-size: 14px;
    .time {
      display: none;
    }
  }

  .post-menu {
    margin-left: 10px;
  }
}

@media only screen and (min-width: $mobile-width) {
  .editor .editor-toolbar {
    .status .time {
      display: inline;
    }
  }
}
