@import "./../../../../scss/colors.scss";
@import "./../../../../scss/typography.scss";
@import "./../../../../scss/responsive.scss";

.endorse-button-container {
  position: relative;
  display: flex;

  .popup {
    top: 20px;
    right: 0;
    z-index: 5000;
  }

  .endorse-button {
    background-color: white;
    padding: 3px 16px 5px 16px;
    line-height: 12px;
    font-size: 12px;
    text-align: center;
    display: inline-block;
    @include fontSansBold;
    height: 20px;
    box-sizing: border-box;
    width: auto;
    text-align: center;
    border-radius: 12.5px;
    position: relative;
    color: var(--highlight-color);
    border: 1px solid var(--highlight-color);
    flex: 1 1 auto;

    &.set {
      background-color: var(--highlight-color);
      color: white;
    }
  } // .follow-button
}
