@import './../../../../scss/colors.scss';
@import './../../../../scss/typography.scss';
@import './../../../../scss/mixins/linkpseudos.scss';


a.post-simplecard {

	display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 12px 0 20px 0;

    & > .embed-thumb, & > .image {
        max-height: 50px;
        max-width: 50px;
        height: 50px;
        width: 50px;
        flex: 0 0 50px;
        margin-right: 11px;
        border-radius: 4px;
        overflow: hidden;
        margin-top: 3px;

        .image {
            height: 50px;
            width: 50px;
            max-height: 50px;
            max-width: 50px;
            border-radius: 4px;
            overflow: hidden;
            flex: 0 0 50px;
        }
    }

    text-decoration: none;
	@include linkpseudos {
		text-decoration: none;
	}
	&:hover {
		text-decoration: none;
	}

    .info {
        flex: 1 1 auto;
        @include fontSansRegular;
        font-size: 14px;
        line-height: 18px;
        margin: 0;

    	h2 {
    		@include fontSansRegular;
    		font-size: 14px;
    		line-height: 18px;
            margin: 0;
            max-height: 36px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            line-clamp: 2;
    	}
        .tag {
            @include fontSansItalic;
            text-transform: capitalize;
    		font-size: 14px;
    		line-height: 18px;
            margin: 0;
            color: var(--highlight-color);
        }
    }

}
