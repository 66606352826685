@import './../../../../scss/colors.scss';
@import './../../../../scss/typography.scss';
@import './../../../../scss/responsive.scss';

.popup {

	position: absolute;
	border-radius: 6px;
	background-color: color($canvas-grey shade(10%));
	padding: 6px;

	.content {
		border: 1px solid color($canvas-grey shade(20%));
		border-radius: 6px;
		background-color: white;
		padding: 6px;

		min-width: 200px;
		min-height: 300px;
		overflow: auto;
	}

	.close {
		position: absolute;
		top: -5px;
		right: -5px;
		height: 40px;
		width: 40px;
		box-sizing: content-box;
		color: white;
		z-index: 888;
		text-align: center;
		cursor: pointer;
		background-color: var(--highlight-color);
		border: 2px solid white;
		color: white;
		border-radius: 50%;
		// box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
		.icon {
			height: 40px;
			width: 40px;
			.svgWrapper {
				top: 25%;
				left: 25%;
				height: 50%;
				width: 50%;
			}
		}
	}
}

@media only screen and (max-width: $mobile-width) {


	body.has-popup {
		overflow: hidden;
	}

	.popup {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 99999;

		.content {
			width: 100%;
			height: 100%;
			overflow: auto;
		}
	}
}
