@import './../../../../scss/colors.scss';
@import './../../../../scss/typography.scss';
@import './../../../../scss/mixins/linkpseudos.scss';
@import './../../../../scss/mixins/progress.scss';
@import './../../../../scss/responsive.scss';

$form-grey: color($text-black tint(20%));

.user-profile {
	min-height: 100%;
    background-color: white;

    .toolbar {

        button {
            user-select: none;
            background-color: var(--highlight-color);
            color: white;
            &:hover {
                background: var(--highlight-color) linear-gradient(90deg, rgba(0, 0, 0, 0.05) 0px, rgba(0, 0, 0, 0.05));
    		}

    		&.loading {
                @include progress;
                cursor: normal;
    		}
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 50px;

        & > * {
            z-index: 29px;
        }

        .avatar {
            margin-top: 121px;
            flex: 0 0 auto;
        //     max-width: 162px;
        //     max-height: 162px;
        //     .image {
        //         border: 6px solid white;
        //         max-width: 150px;
        //         max-height: 150px;
        //         min-width: 150px;
        //         min-height: 150px;
        //     }
        }

        .generic-colorpicker {
            padding-bottom: 20px;
            border-bottom: 1px solid $lighter-grey;
        }

				.field.twitter .social_link #social_link {
						max-width: 28px;
				}

        .field.name {
            input {
                font-size: 30px;
                line-height: 42px;
            }
        }

        .field.feedname, .field.newemail {
            position: relative;

            // &.readonly {
            // }

            .info {
                .unlock {
                    position: absolute;
                    height: 22px;
                    box-sizing: content-box;
                    width: 70px;
                    top: 10px;
                    right: 0;
                    text-align: center;
                    font-size: 14px;
                    line-height: 22px;
                    padding: 2px 0 1px 0;
                    color: var(--highlight-color);
                    border: 1px solid var(--highlight-color);
                    background: white;
                    cursor: pointer;
                    border-radius: 6px;
                    @include fontSansSemiBold;
                }
            }
        }

    	textarea {
    		height: 125px;
    	}

    	.cloudinary-fileupload {
    		display: none;
    	}

    	.avatar {
    		display: inline;
            .image-upload {
                .image {
                    border: 6px solid white;
                }
            }
    	}

    	.image-upload {
    		margin-left: auto;
    		margin-right: auto;
    		width: 162px;
    		height: 162px;
            margin-bottom: 40px;
    		position: relative;
    		z-index: 29;

    		.button {
    			margin: 0;
    			padding: 0;
    			background-color: transparent;
    			border: none;
    		}

    		.image {
                width: 150px;
                height: 150px;
    			border-radius: 50%;
    			.fader {
    				border-radius: 50%;
    			}
    		}

    		&.inprogress {
    			.button {
    				display: none;
    			}
    		}
    	} // .image-upload

        .header-image {
            max-width: 100%;
            width: 100%;
            margin-left: 0;
            margin-right: 0;
            position: absolute;
            left: 0;
            right: 0;
            height: 200px;
            background-color: var(--highlight-color);

            .image-upload {
        		margin-left: 0;
        		margin-right: 0;
        		width: 100%;
        		height: 200px;
        		margin-bottom: 40px;
        		position: relative;
        		z-index: 29;

        		.button {
        			margin: 0;
        			padding: 0;
        			background-color: transparent;
        			border: none;
                    z-index: 20;
        		}
                .button.upload {
                    .button {
                        bottom: auto;
                        right: 20px;
                        top: 20px;
                    }
                }

                .remove-image {
                    position: absolute;
                    cursor: pointer;
                    height: 40px;
                    width: 40px;
                    top: 24px;
                    right: 78px;
                    background-color: rgba(30, 30, 30, 0.4);
                    border-radius: 50%;
                    color: white;
                    transition: all 200ms ease;
                    z-index: 20;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &:hover {
        				box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.8);
        			}
                    &:active {
        				box-shadow: none;
                        transform: translateX(1px) translateY(2px);
                    }
                    .icon {
                        flex: 0 0 18px;
                        height: 18px;
                        width: 18px;
                    }
                }
        		.image {
                    z-index: 5;
        			border-radius: 0;
        			.fader {
        				border-radius: 0;
        			}
        		}

        		&.inprogress {
        			.button {
        				display: none;
        			}
        		}
        	}
        } // .header-image

    	.image.loading {
    		background-color: $lighter-grey;
            transition: background-color 100ms ease;
    	}

    	// .progress {
        //     z-index: 6;
    	// 	position: absolute;
    	// 	top: 50%;
    	// 	left: 50%;
    	// 	right: auto;
    	// 	height: 30px;
    	// 	background-color: white;
    	// 	border-color: $form-grey;
    	// 	border-radius: 15px;
    	// 	overflow: hidden;
        //     // transform: translateY(-15px);
        //
    	// 	.meter {
    	// 		position: absolute;
    	// 		top: 0;
    	// 		left: 0;
    	// 		bottom: 0;
    	// 		right: auto;
    	// 		width: 0;
    	// 		background-color: var(--highlight-color);
    	// 		border-radius: 15px;
    	// 	}
    	// }
        .progress {
    		position: absolute;
    		top: 50%;
    		right: 20%;
    		left: 20%;
    		height: 20px;
    		border-color: #dad8d6;
    		border-radius: 20px;
    		background-color: white;
    		transform: translateY(-15px);

    		.meter {
    			border-radius: 10px;
    			background-color: var(--highlight-color);
    		}
    	}

    	// .error-message {
    	// 	display: inline-block;
    	// 	@include tremr-font-card-author;
    	// 	font-size: $pt11;
    	// 	background-color: $error-color;
    	// 	color: white;
    	// 	border-radius: 4px;
    	// 	padding: 0 6px 2px 6px;
    	// 	line-height: 1.4em;
    	// }

    	strong {
    		color: $form-grey;
    	}

    	.tag-editor {
    		display: inline;
    		.tag {
    			border-color: $form-grey;
    		}
    	}

    	.button.upload {
    		.button {
    			z-index: 30;
    			box-sizing: content-box;
    			border-radius: 50%;
    			background-color: white;
    			height: 40px;
    			width: 40px;
    			position: absolute;
    			bottom: -2px;
    			right: -6px;
    			display: inline-block;
    			border: 4px solid rgba(0, 0, 0, 0.1);
                cursor: pointer;
    			color: black;
                transition: all 200ms ease;
                background-clip: content-box;
                &:hover {
    				// background-color: color(white shade(2%));
                    box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.8);
    			}
                &:active {
    				background-color: color(white shade(1%));
                    box-shadow: none;
                    transform: translateX(1px) translateY(2px);
    			}
    			.svgWrapper {
    				top: 10%;
    				left: 10%;
    				height: 80%;
    				width: 80%;
    			}
    		}
    	}
    } // .content
} // .user-profile



@media only screen and (min-width: $mobile-width) {

    .user-profile {
        .content {
            .avatar {
                margin-top: 241px;
                .image-upload {
            		width: 200px;
            		height: 200px;
                }
            }

            .header-image {
                height: 320px;
                .image-upload {
                    height: 320px;
                }
            }
        }
    }
}
