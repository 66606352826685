@import './../../../../scss/colors.scss';
@import './../../../../scss/typography.scss';
@import './../../../../scss/mixins/progress.scss';
@import './../../../../scss/responsive.scss';
@import './../../../../scss/mixins/linkpseudos.scss';

.welcometitlebar {

  background-image: url('/images/welcome.svg');
  background-position: center -41px;
  background-size: auto 391px;
  background-repeat: no-repeat;
  padding-top: 0;
  background-color: $dark-green;

  .content {
    color: $light-green;
    text-align: center;
    padding-top: 260px;

    h1 {
      @include fontSansBold;
      color: white;
      font-size: 48px;
      line-height: 1.2em;
      margin: 0 0 10px 0;
    }

    h2 {
      @include fontSansBold;
      font-size: 20px;
      line-height: 28px;
      margin-top: 0;
    }

    .open-welcomer {
      height: 36px;
      line-height: 36px;
      @include fontSansBold;
      font-size: 12px;
      text-transform: uppercase;
      border: 1px solid $light-green;
      border-radius: 6px;
      padding: 0 15px;
      background: transparent;
      color: $light-green;
      text-decoration: none;
      margin: 0;
      @include linkpseudos {
        background: transparent;
        text-decoration: none;
        color: $light-green;
      }
    }

    .hide-welcomer {
      font-size: 12px;
      height: 36px;
      line-height: 36px;
      @include fontSansBold;
    }
  }

  .layout-titlebar {
    background-color: transparent;
    .layout-titlebar-content {
      background-color: transparent;
      .signinup-link.get-started a {
        color: $light-blue;
        @include linkpseudos {
					color: $light-blue;
				}
      }
    }
  }
}


// above mobile we change the header
@media only screen and (min-width: $mobile-width) {
  .welcometitlebar {
    background-position: center -120px;
    background-size: auto 720px;
    .content {
      padding-top: 280px;

      h1 {
        font-size: 80px;
        line-height: 110px;
      }

      h2 {
        font-size: 24px;
        line-height: 28px;
      }
    }
  }
}

// nothing changing for medium width
// @media only screen and (min-width: $medium-width) {
// }

// change the header again
@media only screen and (min-width: $enhanced-width) {
  .welcometitlebar {
    background-position: center -120px;
    background-size: 100% auto;
    // background-size: auto 640px;
    .content {
      // padding-top: 400px;
    }
  }
}
