@import "./../../../../scss/colors.scss";
@import "./../../../../scss/typography.scss";
@import "./../../../../scss/mixins/linkpseudos.scss";
@import "./../../../../scss/responsive.scss";

$another-light-grey: #f3f3f4;

article.tag-card {
  @include fontSansRegular;
  background-color: white;
  display: flex;
  box-sizing: border-box;
  position: relative;
  padding: 21px 14px 27px 9px;
  border-radius: 6px;
  margin: 0 4px 3px 4px;

  article.post-list-card:first-child {
    border-top: none;
  }

  &.clickable {
    .avatar-link {
      cursor: pointer;
    }
  }

  .avatar-link {
    flex: 0 0 auto;
    line-height: 52px;
    height: 52px;
    width: 52px;
    color: white;
    font-size: 22px;
    margin-right: 13px;
    background-color: var(--highlight-color);
    border-radius: 50%;
    text-transform: capitalize;
    text-decoration: none;
    text-align: center;
    cursor: default;
    @include linkpseudos {
      text-decoration: none;
      color: white;
    }
  }

  & > .content {
    display: block;
    align-items: flex-start;
    width: 100%;
    min-height: 65px;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    position: relative;

    a {
      text-decoration: none;
    }

    p {
      display: block;
      line-height: 22px;
      @include fontSansRegular;
      font-size: 15px;
      margin: 0;
    } // p

    .row {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      h1 {
        width: auto;
        flex: 0 1 auto;
        line-height: 22px;
        box-sizing: border-box;
        height: auto;
        margin: 0;
        font-size: 15px;
        @include fontSansBold;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-transform: capitalize;
        color: $text-black;

        a {
          cursor: pointer;
          color: $text-black;
          &:before {
            content: "#";
          }
          @include linkpseudos {
            color: $text-black;
            text-decoration: none;
          }
        }
      } // h1

      .actions {
        flex: 0 0 auto;
        margin: 0 0 0 auto;
        height: auto;

        .follow-button-container {
          height: auto;
          .tag:before {
            content: "";
          }
        }
      } // .actions
    }

    .follow-button-container {
      display: inline-flex;
      margin-top: 5px;
      height: auto;
      .tag:before {
        content: "";
      }
    }
    .stats {
      font-size: 13px;
      line-height: 22px;
      @include fontSansRegular;
      display: block;
      margin-top: 2px;
      color: var(--highlight-color);

      em {
        @include fontSansRegular;
      }
      span {
        &:before {
          font-family: sans-serif;
          content: "·";
          padding: 0 3px;
          line-height: 22px;
        }
        &:first-child:before {
          content: "";
          display: none;
        }
      }
    } // .stats
  } // .content
}

// medium styles
@media only screen and (min-width: $mobile-width) {
  article.tag-card {
    padding: 17px 14px 23px 23px;
    margin: 0 0 3px 0;

    .avatar-link {
      margin-right: 24px;
    } // .avatar-link

    & > .content {
    } // .content
  } // .tag-card
} // end of medium styles
