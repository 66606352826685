@import './../../../../scss/colors.scss';
@import './../../../../scss/typography.scss';
@import './../../../../scss/responsive.scss';

.layout-toolbar-sticky {

	display: block;
	position: sticky;
	top: 0;
	width: 100%;
	z-index: 10098;
	height: 50px;
	line-height: 48px;
    padding: 0;
	background-color: white;

	&.sticky {
		box-shadow: 0 6px 0 rgba(0, 0, 0, 0.05);
	}
}

@media only screen and (min-width: $mobile-width) {

    .layout-toolbar-sticky {

        display: flex;
        flex-direction: row;
        justify-content: left;
        overflow: hidden;
        height: 65px;
        line-height: 63px;
    }
}
