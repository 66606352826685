@import "./../../../../scss/colors.scss";
@import "./../../../../scss/typography.scss";
@import "./../../../../scss/mixins/linkpseudos.scss";
@import "./../../../../scss/responsive.scss";
// @import './../../../../scss/mixins/progress.scss';
@import "./../../../../scss/mixins/spinner.scss";
@import "./editorembed.scss";

$control-grey: color($canvas-grey shade(20%));
$tr-editor-grey: #dad8d6;
$tr-general-grey: $light-grey;
$tr-editor-bg: white;
$tr-editor-actions-bg: white;
$tr-editor-margin: 0;
$tr-editor-margin-small-up: 0;
$tr-editor-max-width: 768px; //768 or 760?
$tr-standard-grey: $control-grey;
$init-loading-size: 50px;
$tr-breadcrumb-white: $lighter-grey;
$global-radius: 4px;

// from post show scss (for byline)
$fixedHeaderHeight: 50px;
$post-show-font-size: 15px;
$post-show-line-height: 22px;
$post-show-font-size-medium: 17px;
$post-show-line-height-medium: 27px;

.editor {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding-top: 80px;
  width: auto;
  height: calc(100vh - 80px);
  z-index: 20071;
  display: block;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background-color: white;

  &.saving {
  }

  .door-policy-summary-container {
    width: 100%;
    padding: 0;
    background-color: #f9f9f9;
    margin: 0;
    transform: translateY(-14px);
  }
  .door-policy-summary {
    width: calc($tr-editor-max-width - 22px);
    margin: 0 auto;
    max-width: calc(100% - 22px);
    padding: 10px 0;
    @include fontSansBold;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    .icon {
      flex: 0 0 auto;
      width: 32px;
      height: 32px;
      padding: 0 3px;
    }
    p {
      flex: 1 0 auto;
      margin-left: 11px;
    }
    canvas {
      width: 54px;
      height: 14px;
    }
  }

  .post-breadcrumbs {
    width: $tr-editor-max-width;
    margin: 0 auto;
    max-width: 100%;
    box-sizing: border-box;

    .post-content-card article .card-content {
      grid-template-columns: [card-start] 12px [avatar-start] 34px [avatar-end] 12px [content-start] auto [content-end] 12px [card-end];
      grid-template-areas:
        ". . . . ."
        ". avatar . byline-content ."
        ". .      . content ."
        ". next-button . . ."
        ". responses-button . reply-button .";
    }
  }

  // & > {
  //   .post-breadcrumbs + .byline {
  //     &:before {
  //       content: " ";
  //       position: absolute;
  //       top: -10px;
  //       left: 38px;
  //       height: 40px;
  //       width: 3px;
  //       background-color: #f5f4f4;
  //     }
  //   }
  // }

  & > .byline {
    display: flex;
    width: $tr-editor-max-width;
    margin: 0 auto;
    max-width: 100%;
    box-sizing: border-box;
    padding: 16px 11px 0 11px;
    line-height: $post-show-line-height;
    font-size: $post-show-font-size;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    .avatar {
      flex: 0 1 38px;
      height: 38px;
      width: 38px; // includes below border
      .image {
        max-height: 34px;
        max-width: 34px;
        border: 2px solid white;
      }
    } // .avatar-link

    .info {
      flex: 1 1 auto;
      margin-left: 12px;

      .name-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .name {
          flex: 0 1 auto;
          @include fontSansBlack;
          line-height: $post-show-line-height;
          cursor: pointer;
          a {
            color: var(--highlight-color);
            text-decoration: none;
            @include linkpseudos {
              text-decoration: none;
              color: var(--highlight-color);
            }
          }
        }

        .follow-button-container {
          margin-left: 5px;
        }
      } // .name-container

      .subheader {
        @include fontSansItalic;
        color: $text-grey;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-clamp: 2;
      }
    } // .info
  } // .byline

  .loading {
    .panel {
      position: relative;
      min-height: 100px;

      // animate stuff after load
      & > * {
        opacity: 1;
        transition: opacity 200ms ease 0s;
      }
      & > * {
        opacity: 0;
      }
      &:after {
        @include spinner;

        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: $init-loading-size;
        height: $init-loading-size;
        content: " ";
        transform: translateX(-25px) translateY(-25px);
      }
    }
  }

  .fieldset {
    width: calc($tr-editor-max-width - 22px);
    margin: 0 auto;
    max-width: calc(100% - 22px);
    padding: 0;
    box-sizing: border-box;

    .tagsField {
      padding: 15px 0 5px 0;
      border-bottom: 1px dashed $lighter-grey;
    }

    .field.header {
      margin: 0 -11px;
      width: calc(100% + 22px);
      max-width: calc(100% + 22px);
    }

    .embed iframe {
      display: block;
      box-sizing: border-box;
      max-width: 100% !important;
      max-height: 66vw !important;
      width: 100% !important;
    }
    .panel {
      margin: 0 auto;
      min-height: 100vh;
      max-width: $tr-editor-max-width;
      background-color: $tr-breadcrumb-white;
    }
    .fields {
      padding-bottom: 20px;
      background-color: $tr-editor-bg;
    }
    .field {
      position: relative;
      background-color: $tr-editor-bg;

      &.error {
        position: relative;

        &:after {
          position: absolute;
          top: 8px;
          right: $tr-editor-margin;
          padding: 0 6px 2px 6px;
          border-radius: $global-radius;
          background-color: $error-color;
          color: white;
          content: attr(data-message);
          font-size: 11px;
          line-height: 1.4em;
        }
        &.header {
          &:after {
            top: 430px;
          }
        }
      }
    }
    .comment {
      border: none;
      font-size: 28px;
      line-height: 40px;
      margin: 20px 0;
      @include fontSansRegular;
      &:after {
        bottom: auto;
        top: -25px;
        font-size: 10px;
        line-height: 24px;
      }

      &.h1 {
        @include fontSansBlack;
      }
    }
    .editor-richtext {
      margin-top: 15px;
      margin-bottom: 30px;
      padding-bottom: 130px;
      min-height: 180px;

      .content {
        font-size: 16px;
        line-height: 28px;

        .upload-block,
        .embed-block {
          width: calc(100% + 22px);
          max-width: calc(100% + 22px);
          margin: 0 -11px;
          .caption {
            @include tremr-editor-caption;
          }
        }
      }
      // .controls {
      // 	opacity: 0;
      // 	transition: opacity 100ms ease 0s;
      // }
      // hover shows controls
      // &:hover {
      // 	.controls {
      // 		opacity: 1;
      // 	}
      // }
    } // .editor-richtext

    // flag in JS when the cursor is in content, to show controls
    &.focusRichText {
      .editor-richtext .controls {
        opacity: 1;
      }
    }
    .field > aside {
      position: absolute;
      bottom: 0;
      left: $tr-editor-margin;
      color: $tr-general-grey;
    }
    .field .count {
      position: absolute;
      right: 0;
      bottom: -10px;
      left: auto;
      min-width: 80px;
      max-width: calc(100vw - 80px);
      text-align: right;
      color: $light-grey;
      line-height: 24px;
      font-size: 10px;
      border-top: 1px dashed $lighter-grey;

      &:first-child {
        font-weight: normal;
      }
    }
    .panel > .controls {
      position: relative;
      border-top: 3px solid $canvas-grey;

      // &.open {
      // 	&:before {
      // 		content: " ";
      // 		position: absolute;
      // 		top: 0;
      // 		left: $tr-editor-margin;
      // 		width: auto;
      // 		right: $tr-editor-margin;
      // 		border-top: 1px solid $canvas-grey;
      // 	}
      // }
      .post-type .option-list .option-item {
        box-sizing: content-box;
        margin-left: 0;
        padding-right: 25px;
        padding-bottom: 10px;
        min-width: 75px;

        .label {
          position: static;
          display: block;
          float: none;
          padding-left: 20px;
        }
      }
      .field {
        margin: 0 $tr-editor-margin;
        padding: 20px 0 0 0;
        min-height: 60px;
        border-bottom: 1px solid $canvas-grey;
        background-color: $tr-breadcrumb-white;

        &:last-child {
          border-bottom: none;
        }
        &.error {
          &:after {
            right: 0;
          }
        }
        &.categoryField {
          padding: 13px 0;
          font-weight: normal;

          & > * {
            margin-bottom: 5px;
            vertical-align: top;
          }
        }
        &.tagsField {
          position: static;
          display: inline;
          margin: 0;
          padding: 0;

          &:after {
            position: absolute;
            top: -15px;
            right: 0;
          }
          .tag-editor {
            display: inline;

            .tag {
              margin-bottom: 5px;
              vertical-align: top;

              &:first-child {
                margin-left: 5px;
              }
            }
            .newTag {
              margin-bottom: 5px;
              margin-left: 10px;
              vertical-align: top;
            }
          }
        }
      }
    }
    .dropdown {
      .handle {
        padding: 0 10px 0 10px;
        background-color: $tr-standard-grey;
        color: $tr-breadcrumb-white;
        line-height: 1.6;

        .label {
          padding-right: 30px;
          font-size: 1.65rem;
        }
        &:hover {
          background-color: color($tr-standard-grey shade(2%));
        }
      }
      .content {
        width: 320px;
        border: 2px solid $canvas-grey;

        .broadTags,
        .nicheTags {
          display: inline-block;
          width: 140px;
          vertical-align: top;

          .col-header {
            margin: 0 25px;
            padding: 5px 0;
            border-bottom: 1px solid $canvas-grey;
            text-transform: uppercase;
            font-weight: normal;
          }
          div {
            display: block;
            margin: 0;
            padding: 5px 25px;
            text-transform: capitalize;
          }
          div:hover {
            // background-color: $tr-editor-actions-bg;
            // color: #222;
            text-decoration: underline;
          }
          &.broadTags {
            width: 170px;

            div {
              &.col-header {
                margin-right: 30px;
              }
            }
          }
        }
      }
    }
    .caption {
      @include tremr-editor-caption;
    }
    .richtext {
      .block.embed-block {
        background-color: $tr-breadcrumb-white;

        .caption {
          margin: 0;
          padding: 10px $tr-editor-margin;
          border-top: none;
          background-color: $tr-breadcrumb-white;
          background-color: $tr-editor-bg;
        }
      }
    }
    .header {
      background-color: $tr-editor-bg;

      &.unset {
        border-bottom: 1px dashed $lighter-grey;
        padding: 8px 0;
      }
      .caption {
        // padding: 10px 0 10px 0;

        &:after {
          font-size: 10px;
          line-height: 24px;
          bottom: -10px;
        }
      }
      // animate stuff after load
      & > * {
        opacity: 1;
        transition: opacity 200ms ease 0s;
      }
      &.loading {
        min-height: 430px;

        & > * {
          opacity: 0;
        }
        &:after {
          @include spinner;

          position: absolute;
          top: 50%;
          left: 50%;
          display: block;
          width: $init-loading-size;
          height: $init-loading-size;
          content: " ";
          transform: translateX(-25px) translateY(-25px);
        }
        .caption {
          display: none;
        }
      }
      .embed-thumb {
        @include tremr-editor-embed;
      }
      .embed-thumb {
        .info {
          padding: 1em 11px;
          @include fontSansRegular;
          line-height: 28px;
          font-size: 18px;

          a {
            color: white;
            text-decoration: none;
            @include linkpseudos {
              color: white;
              text-decoration: none;
            }
            &:hover {
              text-decoration: underline;
            }
          }

          h4 {
            @include fontSansBold;
            line-height: 28px;
            font-size: 18px;
            margin: 0;
            padding: 0;
          }
          p {
            @include fontSansRegular;
            line-height: 28px;
            font-size: 18px;
            margin: 0;
            padding: 0;
            &.link {
              margin: 0;
            }
          }
        }
      } // .embed-thumb
      .image-upload,
      .embed {
        min-height: 100px;
        background-color: $tr-editor-bg;

        .cloudinary-fileupload,
        a.upload,
        .icon-remove {
          display: none;
        }
        .image {
          box-sizing: border-box;
          max-width: 100% !important;
          max-height: 66vw !important;
          width: 100% !important;
        }
      }
      .options,
      .prompt {
        position: static;
        z-index: 49;
        box-sizing: content-box;
        padding: 0;
        display: block;
        height: 40px;
        background-color: white;
        list-style: none;

        .icon {
          height: 36px;
          width: 36px;
        }

        &.options {
          transform: translateX(-5px);
        }

        & > .embed-prompt {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          max-width: 100%;
          box-sizing: border-box;

          .contenteditable {
            text-align: left;
            max-width: 100%;
            box-sizing: border-box;
            [contenteditable] {
              padding-left: 2px;
              flex: 1 1 auto;
            }
          }

          .start,
          .cancel {
            flex: 0 0 auto;
          }
        }

        a {
          margin-right: 5px;
        }

        a,
        .contenteditable,
        .start,
        .cancel {
          display: inline-block;
          overflow: hidden;
          width: 54px;
          height: 36px;
          color: $light-grey;
          vertical-align: top;
          text-align: center;
          cursor: pointer;

          &:hover {
            color: color($light-grey shade(15%));
          }
          &:last-child {
            border-right: none;
          }
          .icon {
            vertical-align: top;
          }
        }
        &.prompt {
          margin: 0 11px;
          padding: 0;
          border-bottom: 1px dotted $lighter-grey;

          .start,
          .cancel {
            border-left: 1px solid $canvas-grey;

            .icon {
              height: 36px;

              .svgWrapper {
                top: 15%;
                left: 15%;
                width: 70%;
                height: 70%;
              }
            }
          }
        }
      }
      .reset {
        @include tremr-editor-remove-button;
      }
      .image-upload > .remove-image {
        display: none;
      }
    }
    // colour the post types
    // .option-list {
    // 	.option-item {
    // 		text-transform: capitalize;
    // 	}
    // 	.option-item {
    // 		.marker { border-color: $tr-general-grey; }
    // 		&.set .marker:before { background-color: $tr-general-grey; }
    // 		.label { color: $tr-general-grey; }
    // 	}
    // 	.option-item:nth-child(2) {
    // 		.marker { border-color: $tr-news-orange; }
    // 		&.set .marker:before { background-color: $tr-news-orange; }
    // 		.label { color: $tr-news-orange; }
    // 	}
    // 	.option-item:nth-child(3) {
    // 		.marker { border-color: $tr-opinion-blue; }
    // 		&.set .marker:before { background-color: $tr-opinion-blue; }
    // 		.label { color: $tr-opinion-blue; }
    // 	}
    // 	.option-item:nth-child(4) {
    // 		.marker { border-color: $tr-analysis-green; }
    // 		&.set .marker:before { background-color: $tr-analysis-green; }
    // 		.label { color: $tr-analysis-green; }
    // 	}
    // 	.option-item:nth-child(5) {
    // 		.marker { border-color: $tr-debate-pink; }
    // 		&.set .marker:before { background-color: $tr-debate-pink; }
    // 		.label { color: $tr-debate-pink; }
    // 	}
    // 	.option-item:nth-child(6) {
    // 		.marker { border-color: $tr-idea-peach; }
    // 		&.set .marker:before { background-color: $tr-idea-peach; }
    // 		.label { color: $tr-idea-peach; }
    // 	}
    //
    // }
    .image.loading {
      min-height: 100px;
      background-color: $tr-editor-bg;
      transition: background-color 100ms ease 0s;
    }
    .progress {
      position: absolute;
      top: 50%;
      right: 20%;
      left: 20%;
      height: 20px;
      border-color: $tr-editor-grey;
      border-radius: 20px;
      background-color: white;
      transform: translateY(-15px);

      .meter {
        border-radius: 10px;
        background-color: var(--highlight-color);
      }
    }
    .embed {
      @include tremr-editor-embed;
    }
  }
}

.responses {
  .responseEditor {
    display: block;
    width: 100%;

    .editor {
      position: relative;
      top: auto;
      right: auto;
      bottom: auto;
      left: auto;
      display: block;
      width: auto;
      height: auto;
      border-top: 3px solid $canvas-grey;
    }
    &.closed {
      .editor {
        .panel {
          padding: 20px 40px 20px 40px;
          min-height: 60px;
          background-color: $tr-breadcrumb-white;
          line-height: 20px;
          cursor: pointer;
          transition: background-color 100ms ease 0s;

          .avatar {
            display: inline-block;
            vertical-align: middle;
            cursor: pointer;

            .image {
              border-radius: 50%;
            }
          }
          p {
            display: inline-block;
            padding: 10px 0 0 20px;
            color: $tr-standard-grey;
            vertical-align: middle;
            font-size: 20px;
            line-height: 20px;
            cursor: text;
          }
          .icon {
            display: none;
          }
        } // .panel
      } // .editor
    } // &.closed
  } // .responseEditor
} // .responses

@media only screen and (min-width: $mobile-width) {
  .editor {
    & > {
      .post-breadcrumbs + .byline {
        &:before {
          left: 56px;
        }
      }
    }

    .door-policy-summary {
      padding-left: 18px;
      p {
        margin-left: 14px;
      }
    }

    .post-breadcrumbs {
      .breadcrumb,
      .toggle {
        box-sizing: content-box;
        padding-right: 15px;
      }
      .breadcrumb.breadcrumb .card-content:after {
        // right: 40px;
      }
      .post-content-card article .card-content {
        grid-template-columns: [card-start] 25px [avatar-start] 40px [avatar-end] 16px [content-start] auto [content-end] 25px [card-end];
      }
    }

    & > .byline {
      line-height: $post-show-line-height-medium;
      font-size: $post-show-font-size-medium;
      box-sizing: border-box;
      padding-left: 26px;
      padding-right: 26px;

      .avatar {
        flex: 0 1 44px;
        height: 44px;
        width: 44px; // includes below border
        .image {
          max-height: 40px;
          max-width: 40px;
          border: 2px solid white;
        }
      } // .avatar-link

      .info {
        margin-left: 14px;

        .name-container {
          .name {
            line-height: $post-show-line-height-medium;
            font-size: $post-show-font-size-medium;
            a {
              color: var(--highlight-color);
              @include linkpseudos {
                color: var(--highlight-color);
              }
            }
          }
        } // .name-container
      } // .info
    } // .byline

    .fieldset {
      padding: 0 26px;
      box-sizing: border-box;

      .comment {
        font-size: 38px;
        line-height: 52px;
      }

      .field.header {
        margin: 0;
        width: 100%;
        max-width: 100%;
      }

      .header {
        .options.options {
          transform: translateX(-5px);
        }

        .options,
        .prompt {
          &.prompt {
            margin: 0;
          }
        }

        &.unset .options.options {
          transform: translateX(-15px);
        }
      }

      .editor-richtext {
        padding-bottom: 30px;

        .content {
          @include fontSerifRegular;
          font-size: 18px;
          line-height: 33px;

          .upload-block,
          .embed-block {
            width: 768px;
            max-width: 100%;
            margin: 0;
          }
        }
      } // .editor-richtext
    } // .fieldset
  } //.editor
}
