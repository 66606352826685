.alertify-show,
.alertify-log {
  -webkit-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1); /* older webkit */
  -webkit-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -ms-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275); /* easeOutBack */
}
.alertify-hide {
  -webkit-transition: all 250ms cubic-bezier(0.6, 0, 0.735, 0.045); /* older webkit */
  -webkit-transition: all 250ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -moz-transition: all 250ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -ms-transition: all 250ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -o-transition: all 250ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  transition: all 250ms cubic-bezier(0.6, -0.28, 0.735, 0.045); /* easeInBack */
}
.alertify-log-hide {
  -webkit-transition: all 500ms cubic-bezier(0.6, 0, 0.735, 0.045); /* older webkit */
  -webkit-transition: all 500ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -moz-transition: all 500ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -ms-transition: all 500ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -o-transition: all 500ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  transition: all 500ms cubic-bezier(0.6, -0.28, 0.735, 0.045); /* easeInBack */
}
.alertify-cover {
  position: fixed;
  z-index: 99999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.alertify {
  position: fixed;
  z-index: 99999;
  top: 50px;
  left: 50%;
  width: 550px;
  margin-left: -275px;
}
.alertify-hidden {
  -webkit-transform: translate(0, -150px);
  -moz-transform: translate(0, -150px);
  -ms-transform: translate(0, -150px);
  -o-transform: translate(0, -150px);
  transform: translate(0, -150px);
  visibility: hidden;
}
.alertify-logs {
  position: fixed;
  z-index: 5000;
  bottom: 10px;
  right: 10px;
  width: 300px;
}
.alertify-log {
  display: block;
  margin-top: 10px;
  position: relative;
  right: -300px;
  opacity: 0;
}
.alertify-log-show {
  right: 0;
  opacity: 1;
}
.alertify-log-hide {
  -webkit-transform: translate(300px, 0);
  -moz-transform: translate(300px, 0);
  -ms-transform: translate(300px, 0);
  -o-transform: translate(300px, 0);
  transform: translate(300px, 0);
  opacity: 0;
}
.alertify-dialog {
  padding: 25px;
}
.alertify-resetFocus {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.alertify-inner {
  text-align: center;
}
.alertify-text {
  margin-bottom: 15px;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 100%;
}
.alertify-buttons {
}
.alertify-button {
  /* line-height and font-size for input button */
  line-height: 1.5;
  font-size: 100%;
  display: inline-block;
  cursor: pointer;
  margin-left: 5px;
}

@media only screen and (max-width: 680px) {
  .alertify,
  .alertify-logs {
    width: 90%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .alertify {
    left: 5%;
    margin: 0;
  }
}
