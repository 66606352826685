@import './../../../../scss/colors.scss';
@import './../../../../scss/typography.scss';
@import './../../../../scss/mixins/progress.scss';

.user-signin {

    .toolbar {

        .help {
            color: var(--highlight-color);
            font-size: 14px;
            text-align: right;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .tx2 {
        h2 {
            margin-bottom: 30px;
        }

        .field.email {
            border-top: 1px solid $lighter-grey;
        }
    }

    #social_signup {
        max-width: calc(100% - 40px);
        width: 720px;
        text-align: left;
        iframe {
            width: auto;
        }
    }
}

.layout-overlay:not(.overlay-sidebar) .user-signin .toolbar {
    border-bottom: 0;
}
