@import './colors.scss';
@import './typography.scss';

.contenteditable,
[contenteditable=true] {
	&:empty:before {
		content: attr(data-placeholder);
		color: $placeholder-grey;
		display: block;
		cursor: text;
		font-size: 1em;
	}

	&[data-remaining] {
		position: relative;

		&:after {
			content: attr(data-remaining);
			color: $placeholder-grey;
			position: absolute;
			bottom: 0;
			right: 0;
			text-transform: capitalize;
			font-size: 14px;
            line-height: 14px;
			font-weight: normal;
		}
	}

	&[data-too-long=true] {
		&:after {
			color: $error-color;
		}
	}

	[contenteditable] {
		&::placeholder {
			color: $placeholder-grey;
			display: block;
			cursor: text;
		};
		cursor: text;
	}
}
