@import './../../../../scss/colors.scss';
@import './../../../../scss/typography.scss';
@import './../../../../scss/mixins/linkpseudos.scss';
@import './../../../../scss/responsive.scss';

.headline {

	.post-card {

		.header-image {
			.embed, .embed-thumb {

				iframe {
					max-height: 430px;
					border-radius: 6px;
					overflow: hidden;
				}
			}

			.image, .embed {
				border-radius: 6px;
				overflow: hidden;
				background-clip: content-box;

				&:not(.video) {
					&:after {
						content: " ";
						border-radius: 6px;
						overflow: hidden;
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						margin: 0;
						padding: 0;
						// height: calc(100% - 3px);

						/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.4+0,0+50,0+90,1+100,0.4+100 */
						background: -moz-linear-gradient(top,  rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 90%, rgba(0,0,0,0.5) 100%); /* FF3.6-15 */
						background: -webkit-linear-gradient(top,  rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,0) 90%,rgba(0,0,0,0.5) 100%); /* Chrome10-25,Safari5.1-6 */
						background: linear-gradient(to bottom,  rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,0) 90%,rgba(0,0,0,0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
						filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#66000000', endColorstr='#66000000',GradientType=0 ); /* IE6-9 */
					}
				}
			}
		}

		.content {
			display: inline;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: 0;
			padding: 10px 20px 0 20px !important;
			background-color: transparent;

			h1 {
				@include fontSansSemiBold;
				position: absolute;
				font-size: 3.0rem;
				line-height: 4.0rem;
				width: 550px;
				a {
					@include linkpseudos {
						color: $canvas-grey;
						font-size: 3.0rem;
					}
				}
			}

			p, .card-controls, .card-stats {
				display: none;
			}

			.byline {
				position: absolute;
				top: auto !important;
				display: block;
				width: auto;
				left: 20px;
				bottom: 20px;
				height: 36px;
				.avatar {
					position: absolute;
					top: 0;
					left: 0;
					height: 36px;
					.image {
						border: none;
					}
				}
				.via-avatar {
					display: none;
				}
				.via-text {
					display: none;
				}
				.info {
					display: inline-block;
					position: absolute;
					top: 0;
					left: 48px;
				}
				.name {
					display: block;
					color: $canvas-grey;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					a {
						@include linkpseudos {
							color: $canvas-grey;
						}
					}
				}
				.subheader {
					display: block;
					color: $canvas-grey;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					a {
						@include linkpseudos {
							color: $canvas-grey;
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width: $mobile-width) {

	.headline {
		.post-card {
			.content {
				h1 {
					width: auto;
				}
			}
		}
	}
}
