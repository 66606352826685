@import "./../../../../scss/colors.scss";
@import "./../../../../scss/typography.scss";
@import "./../../../../scss/mixins/linkpseudos.scss";
@import "./../../../../scss/responsive.scss";
@import "./editorembed.scss";

$tr-editor-actions-bg: white;
$tr-editor-margin: 0;
$post-margin: 20px;
$tr-editor-bg: white;
$tr-general-grey: $light-grey;
$tr-editor-grey: #dad8d6;

.editor-richtext {
  position: relative;
  margin: 0;

  .content[contenteditable="false"] {
    .text-block {
      &:only-child:empty {
        &:before {
          display: false;
          content: "";
        }
      }
    }
  }
  .content {
    @include fontSerifRegular;

    position: relative;
    margin: 0;
    padding: 0 0 0 0;
    font-size: 18px;
    line-height: 33px;

    p {
      margin: 0 0 2rem 0;
    }
    .text-block {
      position: relative;
      margin: 0 $tr-editor-margin 0 $tr-editor-margin;
      padding: 15px 0;
      // min-height: 60px;

      &.placeholder {
        display: block;
        color: $text-grey;
        cursor: text;
        user-select: none;
      }
      &:first-child {
        padding-top: 0;
      }
      &:only-child:empty {
        &:before {
          display: block;
          color: $text-grey;
          content: "Say some more…";
          cursor: text;
        }
      }
      &:after {
        // position: absolute;
        // right: 40px;
        // bottom: -1px;
        // width: 20px;
        // height: 1px;
        // border-bottom: 1px solid $canvas-grey;
        // content: " ";
        content: "";
        display: none;
      }
      a,
      a:visited,
      a:active,
      a:hover {
        border-bottom: 1px solid $text-grey;
        color: $text-black;
        text-decoration: none;
        transition: border-color 100ms ease 0s;
      }
      a:hover,
      a:active {
        border-color: $text-black;
      }
    }
  }
  .block {
    position: relative;
    margin: 0 30px;

    &.upload-block,
    &.embed-block,
    &.twitter-block {
      margin: 0;
      // cursor: pointer;

      .caption {
        @include fontSansRegular;
        margin: 0 $tr-editor-margin;
        padding: 10px 0 14px 0;
        word-wrap: break-word;
        line-height: 1.6em;
        cursor: text;
        font-size: 14px;

        &:before {
          opacity: 0.8;
        }
      }
      .remove {
        @include tremr-editor-remove-button;
      }
    }
    &.twitter-block {
      margin: 0 $tr-editor-margin;
      padding: 15px 0;

      &.loading {
        display: none;
      }
      .tweet {
        iframe,
        twitterwidget,
        .twitter-tweet,
        twitter-widget {
          margin-right: auto;
          margin-left: auto;
        }
      }
      .remove {
        top: 30px;
        right: 15px;
      }
    }
  }
  blockquote.block.text-block {
    word-wrap: break-word;
    overflow-wrap: break-word;
    padding-left: $post-margin;
    padding-right: $post-margin;
    border: none;
    color: $text-black;
    font-style: italic;
  }
  h2.block.text-block {
    @include fontSerifBold;
    word-wrap: break-word;
    overflow-wrap: break-word;
    font-size: 22px;
    line-height: 30px;
  }
  .add-block {
    left: auto;
    z-index: 48;
    background-color: transparent;
    vertical-align: middle;
    user-select: none;

    & > a {
      display: inline-block;
      margin: 0;
      width: 18px;
      height: 18px;
      border: 2px solid #e0e0e0;
      border-radius: 50%;
      background-color: $tr-editor-bg;
      // color: $canvas-grey;
      color: #e0e0e0;
      vertical-align: top;
      text-align: center;
      cursor: pointer;

      .icon {
        width: 16px;
        height: 16px;
        vertical-align: top;
        margin: 1px;
      }
      // &:hover {
      // 	border-color: darken($canvas-grey, 5%);
      // 	color: darken($canvas-grey, 5%);
      // }
      &:before {
        position: absolute;
        top: -3px;
        right: -3px;
        bottom: -3px;
        left: -3px;
        z-index: -1;
        border-radius: 50%;
        // background-color: $tr-editor-bg	;
        color: var(--highlight-color);
        content: " ";
      }
    }
    .image-upload {
      input,
      .remove-image {
        display: none;
      }
    }
    .menu {
      position: absolute;
      top: -8px;
      right: 40px;
      left: auto;
      z-index: 49;
      display: none;
      margin: 0;
      padding: 0;
      width: auto;
      height: 44px;
      border: 1px solid $canvas-grey;
      border-radius: 4px;
      background-color: $tr-editor-actions-bg;
      list-style: none;
      user-select: none;

      a {
        margin: 0;
      }
      .button {
        display: block;
        padding: 0;
      }
      li {
        display: inline-block;
        width: 53px;
        height: 44px;
        border-right: 1px solid $canvas-grey;
        color: $text-grey;
        list-style: none;
        text-align: center;
        cursor: pointer;
        user-select: none;

        &:hover {
          background-color: $lighter-grey;
        }
        &:last-child {
          border-right: none;
        }
        a.button.upload {
          color: $text-grey;
          background-color: transparent;

          &:hover {
            background-color: $lighter-grey;
          }
        }
        &:hover {
          background-color: $lighter-grey;
        }
      }
      .icon {
        width: 42px;
        height: 42px;

        .svgWrapper {
          top: 20%;
          left: 20%;
          width: 60%;
          height: 60%;
        }
      }
      &:after,
      &:before {
        position: absolute;
        top: 14px;
        right: -10px;
        width: 0;
        height: 0;
        border-width: 8px 0 8px 10px;
        border-style: solid;
        border-color: transparent transparent transparent white;
        content: " ";
      }

      &:before {
        right: -11px;
        border-color: transparent transparent transparent $canvas-grey;
      }
    }
    &.open .menu {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      flex-direction: row;
      max-width: calc(100vw - 60px);
      & > * {
        flex: 1 1 auto;
      }
    }
    &.prompt-video,
    &.prompt-link,
    &.prompt-twitter {
      .menu {
        .contenteditable {
          // make wider where we can
          width: 250px;

          // hide the char remaining count
          &:after {
            display: none;
          }
        }
      }
    }
  }
  .controls {
    .editor-control {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  .format-popup {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    display: inline-block;
    height: 44px;
    border: 1px solid $canvas-grey;
    border-radius: 4px;
    background-color: $tr-editor-actions-bg;
    color: $tr-editor-grey;
    user-select: none;
    white-space: nowrap;

    &:after {
      position: absolute;
      top: auto;
      bottom: -8px;
      left: 50%;
      width: 0;
      height: 0;
      border-width: 8px 8px 0 8px;
      border-style: solid;
      border-color: $canvas-grey transparent transparent transparent;
      content: " ";
      transform: translateX(-50%);
    }
    a {
      display: inline-block;
      width: 53px;
      height: 42px;
      border-right: 1px solid $canvas-grey;
      color: $text-grey;
      list-style: none;
      vertical-align: top;
      text-align: center;
      font-size: 18px;
      line-height: 42px;
      cursor: pointer;
      user-select: none;
      text-decoration: none;

      &:hover {
        background-color: darken($tr-editor-actions-bg, 2%);
        color: darken($text-grey, 5%);
        text-decoration: none;
      }
      &:last-child {
        border-right: none;
      }
      .icon {
        width: 42px;
        height: 42px;

        .svgWrapper {
          top: 20%;
          left: 20%;
          width: 60%;
          height: 60%;
        }
      }
      &.active {
        color: $tr-news-orange;
      }
    }
    input {
      display: inline-block;
      margin: 0;
      padding: 10px;
      width: 200px;
      height: 42px;
      outline: none;
      border: none;
      border-right: 1px solid $canvas-grey;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      box-shadow: none;
      font-weight: lighter;
      font-size: 10px;
      line-height: 22px;

      &:focus {
        border: none;
        border-right: 1px solid $canvas-grey;
        box-shadow: none;
      }
    }
  }
}

@media only screen and (max-width: $mobile-width) {
  .editor-richtext {
    .content {
      font-size: 16px;
      line-height: 28px;
      margin-top: 14px;

      p {
        margin: 0 0 8px 0;
      }

      .text-block {
        margin: 0;
        padding: 8px 0;
      }

      h2.block.text-block {
        font-size: 20px;
        line-height: 26px;
      }
    }
  }
}
