.icon {
   position: relative;
   display: inline-block;
   height: 32px; // default size - prob should override in specific uses
   width: 32px;

   // fix for safari 8, make all svg relative to fix click handler issue
   &:after {
     content: "";
     position: absolute;
     top: 0;
     right: 0;
     bottom: 0;
     left: 0;
     background-color: transparent;
   }
}
[class^="icon-"], [class*=" icon-"] {
   display: block;
   // fill: currentColor;
   fill: none;
   stroke: currentColor;
   stroke-width: 20;
   position: absolute;
   // top: 10%;
   // left: 10%;
   // height: 80%;
   // width: 80%;
   top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  &.fill {
     fill: currentColor;
     stroke: none;
     stroke-width: 0;
 }
   svg {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
   }
}
