@import './../../../../scss/colors.scss';
@import './../../../../scss/typography.scss';
@import './../../../../scss/mixins/linkpseudos.scss';

.layout-overlay.overlay-sidebar .overlay .sidebar { // needed to make rules specific
    &.user-sidebar {

    	.user-sidebar-toolbar {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            flex: 0 0 auto;
            height: auto;

            &:before {
                content: "";
                display: none;
            }

            .row {
                flex: 1 0 66px;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;

                .avatar {
                    flex: 0 0 72px;
                    margin: 15px 0 0 15px;
                    cursor: pointer;
                    .image {
                        max-height: 72px;
                        max-width: 72px;
                    }
                }
                .close {
                    margin: 10px 0 0 auto;
                }
            }
            .row + .row {
                flex: 1 0 auto;
            }
            .row.title {
                @include fontSansBold;
                box-sizing: border-box;
                line-height: 29px;
                font-size: 20px;
                padding: 15px;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                h1 {
                    flex: 1 0 auto;
                    margin: 15px 0 10px 0;
                    @include fontSansBold;
                    font-size: 30px;
                    line-height: 30px;
                    cursor: pointer;
                }
                p {
                    flex: 1 0 auto;
                    margin: 0 0 10px 0;
                    @include fontSansRegular;
                    font-size: 17px;
                    line-height: 18px;
                    color: var(--highlight-color);
                }

                .stats {
                    .stat {
                        display: inline-block;
                        margin-left: 5px;
                        &:before {
                            content: "•";
                            // font-size: 0.8em;
                            display: inline;
                            padding-right: 5px;
                            color: var(--highlight-color);
                            cursor: default;
                            font-family: serif;
                        }

                        &:first-child {
                            margin-left: 0;
                            &:before {
                                content: "";
                                padding-right: 0;
                            }
                        }
                    }
                } // .stats
            }

        }

        .controls {

            .line.manage-subfeeds-button {
                a {
                    display: block;
                    cursor: pointer;
                }
            }

            .section {

                .line {
                    .button {
                        background-color: var(--highlight-color);
                        height: 36px;
                        display: inline-flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        @include fontSansSemiBold;
                        font-size: 16px;
                        line-height: 16px;
                        color: white;
                        border-radius: 18px;
                        padding: 0 25px 0 5px;
                        flex: 0 0 auto;

                        .icon {
                            height: 36px;
                            width: 36px;
                        }
                    }
                }

                .line.channel {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    cursor: pointer;

                    .name {
                        flex: 1 1 auto;
                        height: 60px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        padding-right: 10px;
                    }

                    .image, .channel-avatar {
                        flex: 0 0 32px;
                        border-radius: 4px;
                        margin-left: auto;
                    }

                    .channel-avatar {
                        height: 32px;
                        width: 32px;
                        line-height: 32px;
            			font-size: 16px;
            			position: relative;
            			text-align: center;
            			text-transform: capitalize;
                    }
                }
            } // .channels
        }


    }
}
