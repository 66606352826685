@import "./../../../../scss/colors.scss";
@import "./../../../../scss/typography.scss";
@import "./../../../../scss/responsive.scss";

.userendorse-buttons-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;

  .userendorse-buttons {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;
    margin: 10px -2px 0 -2px;
    width: calc(100% + 4px);
    max-width: calc(100% + 4px);

    & > * {
      flex: 1 0 auto;
    }

    & > .activity-button {
      min-width: 75px;
    }

    label {
      font-size: 10px;
      line-height: 33px;
    }
  }
}

@media only screen and (min-width: $mobile-width) {
  .userendorse {
    .userendorse-buttons {
      & > .activity-button {
        min-width: 100px;
      }
    }
  }
}
